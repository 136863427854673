﻿import $ from "jquery";

export default class ProductVolumeDiscount {
    static init(): void {
        ProductVolumeDiscount.initProductItem();
        ProductVolumeDiscount.initProductDetailOrderInfo();
    }

    static initProductItem(): void {
        const $productItem: JQuery<HTMLElement> = $(".js-product-item");

        if ($productItem.length === 0) {
            return;
        }

        $productItem.on("click", ".js-modal-product-volume-discount--submit", e => {
            const $volumeDiscountSubmit: JQuery<HTMLElement> = $(e.currentTarget);
            const $volumeDiscountModal: JQuery<HTMLElement> = $volumeDiscountSubmit.closest(".modal");
            const $volumeDiscountClose: JQuery<HTMLElement> = $volumeDiscountModal.find(`[data-bs-dismiss="modal"]`);
            const $volumeDiscountOption: JQuery<HTMLElement> = $volumeDiscountModal.find(".js-product-volume-discount--option:checked");
            const $productItem: JQuery<HTMLElement> = $volumeDiscountSubmit.closest(".js-product-item");

            ProductVolumeDiscount.updatePriceAndQuantity($volumeDiscountOption, $productItem);
            $volumeDiscountClose.trigger("click"); // closes modal
        });

        // select correct volume discount option on load
        $("body").find(".js-product-item").each(function () {
            const $productItem: JQuery<HTMLElement> = $(this);

            const $totalOrderQuantity: JQuery<HTMLElement> = $productItem.find(".js-product-order-box--total-order-quantity");
            if ($totalOrderQuantity.length === 0) {
                return;
            }

            const totalOrderQuantity: string = ($totalOrderQuantity.val() as string).replace(",", ".");
            ProductVolumeDiscount.selectOption(parseFloat(totalOrderQuantity), $productItem)
        });
    }

    static initProductDetailOrderInfo(): void {
        const $productDetailOrderInfo: JQuery<HTMLElement> = $(".js-product-detail-order-info");

        if ($productDetailOrderInfo.length === 0) {
            return;
        }

        $productDetailOrderInfo.on("change", ".js-product-volume-discount--option", e => {
            const $volumeDiscountOption: JQuery<HTMLElement> = $(e.currentTarget);
            const $orderInfo: JQuery<HTMLElement> = $volumeDiscountOption.closest(".js-product-detail-order-info");
            ProductVolumeDiscount.updatePriceAndQuantity($volumeDiscountOption, $orderInfo);
        });

        // select correct volume discount option on load
        const $totalOrderQuantity: JQuery<HTMLElement> = $productDetailOrderInfo.find(".js-product-order-box--total-order-quantity");
        if ($totalOrderQuantity.length === 0) {
            return;
        }

        const totalOrderQuantity: string = ($totalOrderQuantity.val() as string).replace(",", ".");
        ProductVolumeDiscount.selectOption(parseFloat(totalOrderQuantity), $productDetailOrderInfo)
    }

    static updatePriceAndQuantity($volumeDiscountOption: JQuery<HTMLElement>, $targetContainer: JQuery<HTMLElement>): void {
        const $netPriceFrom: JQuery<HTMLElement> = $targetContainer.find(".js-async-net-price-from--value");
        const $orderQuantity: JQuery<HTMLElement> = $targetContainer.find(".js-product-order-box--order-quantity");
        const $totalOrderQuantity: JQuery<HTMLElement> = $targetContainer.find(".js-product-order-box--total-order-quantity");
        const $overpack: JQuery<HTMLElement> = $targetContainer.find(".js-product-order-box--overpack");

        const volumeDiscountOptionPrice: string = $volumeDiscountOption.data("nt-price") as string;
        const volumeDiscountOptionValue: number = $volumeDiscountOption.val() as number;

        const selectedUnitsPerPack: number = ($overpack.length > 0 ? $overpack.find("option:selected").data("nt-upp") : 1) as number;
        const decimalSeparator = window["Global"].DecimalSeparator;
        const totalOrderQuantity = volumeDiscountOptionValue;
        const totalOrderQuantityString = totalOrderQuantity.toString().replace(".", decimalSeparator);

        $netPriceFrom.text(volumeDiscountOptionPrice);
        const quantity = volumeDiscountOptionValue / selectedUnitsPerPack;
        $orderQuantity.val(Math.round((quantity + Number.EPSILON) * 10000) / 10000);
        $totalOrderQuantity.val(totalOrderQuantityString);

        if ($overpack.length) {
            $overpack.trigger("staffelchange");
        }
    }

    /**
     * Selects the correct radiobutton in the volume discount overview.
     * @param {number} totalOrderQuantity The total order quantity.
     * @param {number} $targetContainer The target containing the component.
     */
    static selectOption(totalOrderQuantity: number, $targetContainer: JQuery<HTMLElement>): void {
        if ($targetContainer.length === 0) {
            return;
        }

        const $netPriceFrom: JQuery<HTMLElement> = $targetContainer.find(".js-async-net-price-from--value");

        if ($netPriceFrom.length === 0) {
            return;
        }

        let found = false;

        $targetContainer.find(".js-product-volume-discount--option").each(function () {
            const $volumeDiscountOption: JQuery<HTMLElement> = $(this);
            const volumeDiscountOptionValue: number = $volumeDiscountOption.val() as number;
            const volumeDiscountOptionMaxValue: number = $volumeDiscountOption.data("nt-max") as number;
            const volumeDiscountOptionPrice: string = $volumeDiscountOption.data("nt-price") as string;

            if (found === false && totalOrderQuantity >= volumeDiscountOptionValue && totalOrderQuantity <= volumeDiscountOptionMaxValue) {
                $volumeDiscountOption.prop("checked", true);
                $netPriceFrom.text(volumeDiscountOptionPrice);
                found = true;
            }
        });

        if (!found) {
            // quantity entered is lower than the smallest discount quantity
            $targetContainer.find(".js-product-volume-discount--option").prop("checked", false);
            const defaultPrice = $targetContainer
                .find(".js-product-volume-discount--table")
                .data("nt-defaultprice");
            $netPriceFrom.text(defaultPrice);
        }
    }
}
