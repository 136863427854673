class Message {
    level!: number;
    content!: string;
}

class IdAndQuantity {
    id!: number;
    quantity!: number;
}

class CurrentQuantities {
    success: boolean = false;
    messages!: Message[];
    quantities!: IdAndQuantity[];
    lineCount!: number;
}

class AddResult {
    success: boolean = false;
    lineCount!: number;
    messages!: Message[];
}

class UpdateResult {
    success: boolean = false;
    messages!: Message[];
    productId!: number;
    quantity!: number;
}

class RemoveResult {
    success: boolean = false;
    messages!: Message[];
    productId!: number;
    lineCount!: number;
}

class SendToResult {
    success: boolean = false;
    messages!: Message[];
}

class AnonymousBasketFormData {
    firstName!: string;
    lastName!: string;
    emailAddress!: string;
    phone!: string;
    organizationLevel!: string;
    comment!: string;
    companyName!: string;
    sector!: string;
    vatNumber!: string;
    address!: string;
    zip!: string;
    city!: string;
}

export { Message, IdAndQuantity, CurrentQuantities, AddResult, UpdateResult, RemoveResult, SendToResult, AnonymousBasketFormData }
