﻿import { createApp } from 'vue';

import CmsConfigurator from "../components/CmsConfigurator.vue";
import MiniBasket from "../components/MiniBasket.vue";
import LeadsForm from '../components/LeadsForm.vue';
import Lists from '../components/Lists.vue';
import ListSelector from '../components/ListSelector.vue';
import UserMgmt from "../components/UserMgmt.vue";

/*
 * Place this on a page to render one of these components:
 * <div id="vue-hello-world" data-foo="bar"></div>
 */

export default class VueComponentLoader {
    private static vueComponents = [
        { component: CmsConfigurator, elementId: "vue-cms-configurator", checkForVisibility: false },
        { component: MiniBasket, elementId: "vue-mini-basket--desktop", checkForVisibility: true },
        { component: MiniBasket, elementId: "vue-mini-basket--mobile", checkForVisibility: true },
        { component: LeadsForm, elementId: "vue-leads-form", checkForVisibility: false },
        { component: Lists, elementId: "vue-lists", checkForVisibility: false },
        { component: ListSelector, elementId: "vue-list-selector", checkForVisibility: false },
        { component: UserMgmt, elementId: "vue-user-mgmt", checkForVisibility: false },
    ];

    static load() {
        for (let vueComponent of VueComponentLoader.vueComponents) {
            const mountElement = document.getElementById(vueComponent.elementId);

            if (mountElement !== null) {
                const doMount = vueComponent.checkForVisibility ? mountElement.offsetWidth > 0 || mountElement.offsetHeight > 0 : true;
                
                if (doMount) {
                    ////console.log("vue mounting...", mountElement);
                    createApp(vueComponent.component, {...mountElement.dataset}).mount("#" + vueComponent.elementId);
                }
            }
        }
    }
}
