import $ from "jquery";
import { format, parse } from "date-fns";
import Datepicker from "js-datepicker";
import { DatePickerData, DatePickerRegistration } from "../models/date-picker";
import CustomHelper from "../utils/custom-helper";

export default class CustomDatePicker {
    private static datePickerRegistrationList: Array<DatePickerRegistration> = [];

    public static init() {
        $(`[data-nt-toggle="datepicker"]`).each(function (index: number) {
            const $input = $(this);

            // add item to keep registration per datepicker
            const datePickerIdentifier: string | undefined = $input.prop("id");

            if (typeof datePickerIdentifier === "undefined") {
                console.warn(`Missing identifier for this datepicker registration (${$input[0]})`)
                return;
            }

            CustomDatePicker.datePickerRegistrationList.push({
                identifier: datePickerIdentifier,
                inputObject: $input,
                instance: undefined,
                selectedDate: new Date(),
                allowedDateList: [],
                allowedTimeList: [],
                allowAllDates: false
            });

            const datePickerRegistration = CustomDatePicker.getDatePickerRegistration(datePickerIdentifier);

            if (typeof datePickerRegistration === "undefined") {
                console.warn(`Missing registration for this datepicker (${datePickerIdentifier})`)
                return;
            }

            CustomDatePicker.initRegistration(datePickerRegistration);
        })
    }

    public static log() {
        console.log("CustomDatePicker Registrations");
        for (let r of CustomDatePicker.datePickerRegistrationList) {
            console.log("R", r.identifier, r.selectedDate);
        }

        console.log("----------------------------------------------");
    }

    public static getSelectedDate(identifier: string): Date | null {
        for (let r of CustomDatePicker.datePickerRegistrationList) {
            if (r.identifier === identifier) {
                return r.selectedDate;
            }
        }

        return null;
    }

    public static updateDates(data: DatePickerData, identifier: string) {
        const datePickerRegistration = CustomDatePicker.getDatePickerRegistration(identifier);

        if (typeof datePickerRegistration === "undefined") {
            console.warn(`Missing registration for this datepicker (${identifier})`)
            return;
        }

        // clear lists
        datePickerRegistration.allowedDateList.length = 0;
        datePickerRegistration.allowedTimeList.length = 0;

        for (let allowed of data.allowedDateList) {
            const allowedDate = CustomHelper.getDateFromString(allowed);
            datePickerRegistration.allowedDateList.push(allowedDate);
            datePickerRegistration.allowedTimeList.push(allowedDate.getTime());
        }

        if (data.allowedDateList.length == 0) {
            return;
        }

        // reset instance
        if (typeof datePickerRegistration.instance !== "undefined") {
            datePickerRegistration.instance.remove();
        }

        CustomDatePicker.initRegistration(datePickerRegistration);

        CustomDatePicker.setDatePickerDate(CustomHelper.getDateFromString(data.minDate), datePickerRegistration);

        datePickerRegistration.instance.setMin(CustomHelper.getDateFromString(data.minDate));
        datePickerRegistration.instance.setMax(CustomHelper.getDateFromString(data.maxDate));

        const initialDate = new Date(data.selectedDate);
        CustomDatePicker.setDatePickerDate(initialDate, datePickerRegistration);
    }

    public static clearDatePicker() {
        $("#transfer-date").val("");
    }

    private static initRegistration(datePickerRegistration: DatePickerRegistration) {
        // https://www.npmjs.com/package/js-datepicker
        const options = {
            startDay: 1,
            disableYearOverlay: true,
            disabler: (date: Date) => {
                if (datePickerRegistration.allowAllDates) {
                    return false;
                }

                return $.inArray(date.getTime(), datePickerRegistration.allowedTimeList) === -1;
            },
            formatter: (input: any, date: Date, instance: any) => {
                input.value = format(date, "dd/MM/yyyy");
            },
            onSelect: (instance: any, date: Date) => {
                datePickerRegistration.selectedDate = instance.dateSelected;
                datePickerRegistration.inputObject.trigger("change", instance.dateSelected);
            },
        };

        const firstDate = datePickerRegistration.inputObject.data("nt-datepicker-first") as string;
        if (typeof firstDate !== "undefined" && firstDate.length > 0 && firstDate !== "01/01/0001" && firstDate !== "31/12/9999") {
            options["minDate"] = parse(firstDate, "dd/MM/yyyy", new Date());
        }

        const lastDate = datePickerRegistration.inputObject.data("nt-datepicker-last") as string;
        if (typeof lastDate !== "undefined" && lastDate.length > 0 && lastDate !== "01/01/0001" && lastDate !== "31/12/9999") {
            options["maxDate"] = parse(lastDate, "dd/MM/yyyy", new Date());
        }

        const allowedAsJson = datePickerRegistration.inputObject.data("nt-datepicker-allowed") as string;
        if (typeof allowedAsJson !== "undefined") {
            if (allowedAsJson === "*") {
                datePickerRegistration.allowAllDates = true;
            }
            else {
                for (let allowed of allowedAsJson) {
                    const allowedDate = CustomHelper.getDateFromString(allowed);
                    datePickerRegistration.allowedDateList.push(allowedDate);
                    datePickerRegistration.allowedTimeList.push(allowedDate.getTime());
                }
            }
        }

        const datepickerSelector = `#${datePickerRegistration.inputObject.prop("id")}`;
        datePickerRegistration.instance = Datepicker(datepickerSelector, options);

        const dateValue = datePickerRegistration.inputObject.val() as string;
        if (dateValue != "") {
            const parsedDateValue = parse(dateValue, "dd/MM/yyyy", new Date());
            CustomDatePicker.setDatePickerDate(parsedDateValue, datePickerRegistration);
        }

        const $datepickerIcon = datePickerRegistration.inputObject.closest(".input-group").find(`[type="button"]`);
        $datepickerIcon.on("click", (e: JQueryEventObject) => {
            e.stopPropagation();
            datePickerRegistration.instance.show();
        });

        datePickerRegistration.inputObject.on("change", (e: JQueryEventObject) => {
            const dateValue = parse(datePickerRegistration.inputObject.val() as string, "dd/MM/yyyy", new Date());
            const isEarlierDate = dateValue < datePickerRegistration.instance.minDate;
            const isLaterDate = dateValue > datePickerRegistration.instance.maxDate;
            const isAllowed = datePickerRegistration.allowedTimeList.length == 0 || datePickerRegistration.allowedTimeList.includes(dateValue.getTime());
            if (isEarlierDate || isLaterDate || isAllowed === false) {
                datePickerRegistration.inputObject.val("");
            } else {
                CustomDatePicker.setDatePickerDate(dateValue, datePickerRegistration);
            }
        });
    }

    private static getDatePickerRegistration(identifier: string): DatePickerRegistration | undefined {
        return CustomDatePicker.datePickerRegistrationList.find(i => i.identifier == identifier);
    }

    private static setDatePickerDate(value: Date, datePickerRegistration: DatePickerRegistration) {
        if (typeof value.getMonth !== 'function') {
            value = new Date(value);
        }

        try {
            datePickerRegistration.selectedDate = value;
            datePickerRegistration.instance.setDate(value, true);
        } catch (error) {
            // do nothing (throws exception when disabled date is set)
            console.error(error);
        }
    }
}
