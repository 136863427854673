<template>
    <div class="d-flex justify-content-between align-items-center">
        <h3>{{ titleContacts }}</h3>

        <div v-if="allowCreatePersons" class="col-md-6 text-end">
            <button :disabled="isBusy" class="btn btn-secondary" type="button" @click="newContact">
                {{ actionNewContact }}
            </button>
        </div>
    </div>

    <div v-show="isBusy" class="mb-5">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div v-show="contactList.length === 0 && !isBusy" class="alert alert-warning mb-5">
        {{ messageWarningNoContacts }}
    </div>

    <div v-show="contactList.length > 0 && !isBusy" class="table-responsive mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th>{{ labelContactsName }}</th>
                    <th>{{ labelContactsEmail }}</th>
                    <th>{{ labelContactsPhone }}</th>
                    <th>{{ labelContactsFunction }}</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="contact in contactList" :key="contact.id">
                    <tr v-if="!contact.mustRemove">
                        <td>
                            <span class="text-nowrap"><i class="fa fa-user text-neutral-400 me-2"></i>{{ contact.firstName }} {{ contact.lastName }}</span>
                        </td>
                        <td>
                            <span class="text-nowrap"><i class="fa fa-envelope text-neutral-400 me-2"></i>{{ contact.email }}</span>
                        </td>
                        <td>
                            <span class="text-nowrap"><i v-show="contact.phone" class="fa fa-phone text-neutral-400 me-2"></i>{{ contact.phone }}</span><br />
                            <span class="text-nowrap"><i v-show="contact.mobile" class="fa fa-mobile text-neutral-400 me-2"></i>{{ contact.mobile }}</span>
                        </td>
                        <td>
                            {{ functionName(contact) }}
                        </td>
                        <td class="text-end">
                            <button :disabled="isBusy" class="btn btn-primary" @click="editContact(contact)">
                                {{ actionEditContact }}
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <h3>{{ titleLogins }}</h3>

    <div v-show="isBusy" class="mb-5">
        <div class="spinner-border text-primary" role="status">
            <span class="visually-hidden">Loading...</span>
        </div>
    </div>

    <div v-show="loginList.length === 0 && !isBusy" class="alert alert-warning mb-5">
        {{ messageWarningNoLogins }}
    </div>

    <div v-show="loginList.length > 0 && !isBusy" class="table-responsive mb-3">
        <table class="table">
            <thead>
                <tr>
                    <th>{{ labelLoginsName }}</th>
                    <th>{{ labelLoginsEmail }}</th>
                    <th>{{ labelLoginsPhone }}</th>
                    <th>{{ labelLoginsRole }}</th>
                    <th>&nbsp;</th>
                    <th>&nbsp;</th>
                </tr>
            </thead>
            <tbody>
                <template v-for="login in loginList" :key="login.id">
                    <tr v-show="!login.mustRemove">
                        <td>
                            <span class="text-nowrap"><i :class="{ 'text-danger': login.isInternal }" class="fa fa-user text-neutral-400 me-2"></i>{{ login.firstName }} {{ login.lastName }}</span>
                        </td>
                        <td>
                            <span class="text-nowrap"><i class="fa fa-envelope text-neutral-400 me-2"></i>{{ login.email }}</span>
                        </td>
                        <td>
                            <span class="text-nowrap"><i v-show="login.phone" class="fa fa-phone text-neutral-400 me-2"></i>{{ login.phone }}</span><br />
                            <span class="text-nowrap"><i v-show="login.mobile" class="fa fa-mobile text-neutral-400 me-2"></i>{{ login.mobile }}</span>
                        </td>
                        <td>
                            {{ roleName(login) }}<br />
                            {{ functionName(login) }}
                        </td>
                        <td>
                            <span v-show="login.isBlocked" class="text-danger">
                                {{ labelLoginsBlocked }}
                            </span>
                            <br />
                            <span v-show="login.isLockedOut" class="text-warning">
                                {{ labelLoginsLocked }}
                            </span>
                        </td>
                        <td class="text-end">
                            <button :disabled="isBusy" class="btn btn-primary" @click="editContact(login)">
                                {{ actionEditLogin }}
                            </button>
                        </td>
                    </tr>
                </template>
            </tbody>
        </table>
    </div>

    <user-mgmt-editor ref="userMgmtEditor"
                      :allow-create-persons="allowCreatePersons"
                      :allow-delete-persons="allowDeletePersons"
                      :allow-edit-persons="allowEditPersons"
                      :allow-edit-rights="allowEditRights"
                      :allow-add-remove-username="allowAddRemoveUsername"
                      :contact="selectedContact"
                      :customer-id="encryptedCustomerId"
                      :emit-context="{ loadContacts, savedContact }"
                      :site-code="siteCode"></user-mgmt-editor>
</template>

<script lang="ts">
    import { computed, defineComponent, ref } from "vue";
    import axios, { AxiosError, AxiosResponse } from "axios";
    import ActionUrls from "../scripts/utils/action-urls";
    import Multilang from "../scripts/utils/multilang";
    import Toaster from "../scripts/components/toaster";
    import UserMgmtEditor from "./UserMgmtEditor.vue";
    import {
        UserMgmtCityData,
        UserMgmtContactAction,
        UserMgmtContactData,
        UserMgmtContactSaveResults,
        UserMgmtContactsResults,
        UserMgmtExtraResults,
        UserMgmtFunctionData,
        UserMgmtNewsletterSetting,
        UserMgmtProductSelection,
        UserMgmtRoleData,
    } from "../scripts/models/user-mgmt";

    const defaultRoleId1 = 30; // Aankoper Deschacht
    const defaultRoleId2 = 80; // Aankoper Foylo
    const defaultRoleIdIntern1 = 20; // Binnendienst Deschacht
    const defaultRoleIdIntern2 = 70; // Binnendienst Foylo

    const defaultFunctionId = 0;
    const defaultGender = "Male";
    const defaultLcid = 2067;

    type UserMgmtEditorType = {
        showModal: () => void;
        hideModal: () => void;
    };

    let userMgmtEditorRef: UserMgmtEditorType;

    export default defineComponent({
        name: "UserMgmt",
        components: { UserMgmtEditor },
        data() {
            return {
                isBusy: false,

                titleContacts: Multilang.getTranslation("contacts.title", "Contacten"),
                titleLogins: Multilang.getTranslation("logins.title", "Gebruikers"),

                labelContactsName: Multilang.getTranslation("contacts.label.name", "Naam"),
                labelContactsEmail: Multilang.getTranslation("contacts.label.email", "E-mail"),
                labelContactsPhone: Multilang.getTranslation("contacts.label.phone-mobile", "Telefoon / GSM"),
                labelContactsFunction: Multilang.getTranslation("contacts.label.function", "Functie"),
                labelLoginsName: Multilang.getTranslation("logins.label.name", "Naam"),
                labelLoginsEmail: Multilang.getTranslation("logins.label.email", "E-mail"),
                labelLoginsPhone: Multilang.getTranslation("logins.label.phone-mobile", "Telefoon / GSM"),
                labelLoginsRole: Multilang.getTranslation("logins.label.role-function", "Rol / Functie"),
                labelLoginsBlocked: Multilang.getTranslation("logins.label.blocked", "Blocked"),
                labelLoginsLocked: Multilang.getTranslation("logins.label.locked", "Locked"),

                actionNewContact: Multilang.getTranslation("contacts.action.new-contact", "Nieuw contact"),
                actionEditContact: Multilang.getTranslation("contacts.action.edit-contact", "Details"),
                actionEditLogin: Multilang.getTranslation("logins.action.edit-login", "Details"),

                messageWarningNoContacts: Multilang.getTranslation("contacts.message.warning.no-contacts", "Geen contacten gevonden"),
                messageWarningNoLogins: Multilang.getTranslation("logins.message.warning.no-logins", "Geen gebruikers gevonden")
            };
        },
        props: {
            siteCode: String,
            canEditPersons: String,
            canEditRights: String,
            canDeletePersons: String,
            canCreatePersons: String,
            canAddRemoveUsername: String,
            internal: String,
            customerId: String,
        },
        setup: (props) => {
            let isBusy = ref(false);


            ActionUrls.load();
            Multilang.load();

            const allowEditPersons: boolean = props.canEditPersons === "true";
            const allowEditRights: boolean = props.canEditRights === "true";
            const allowDeletePersons: boolean = props.canDeletePersons === "true";
            const allowCreatePersons: boolean = props.canCreatePersons === "true";
            const allowAddRemoveUsername: boolean = props.canAddRemoveUsername === "true";

            const isInternal: boolean = props.internal === "true";
            const encryptedCustomerId: string = props.customerId ?? "";

            // CITIES
            const cityList = ref([] as UserMgmtCityData[]);

            // CONTACTS
            let selectedContact = ref({} as UserMgmtContactData);

            const personList = ref([] as UserMgmtContactData[]);

            const contactList = computed(() => {
                return personList.value.filter((item) => {
                    return item.userName === null || item.userName === "" || !item.canLogOn;
                });
            });

            const loginList = computed(() => {
                return personList.value.filter((item) => {
                    return item.userName !== null && item.userName !== "" && item.canLogOn;
                });
            });

            const loadContacts = (encryptedCustomerId: string) => {
                isBusy.value = true;

                personList.value.splice(0, personList.value.length);

                axios
                    .get(`${ActionUrls.getUrl("load-contacts")}?ecid=${encryptedCustomerId}`)
                    .then((res: AxiosResponse) => res.data)
                    .then((data: UserMgmtContactsResults) => {
                        for (let contact of data.contacts) {
                            setDefaults(contact);

                            contact.zipCity = contact.zipCity.toUpperCase();
                            contact.zipCitySelection = cityList.value.find(city => city.zipCity === contact.zipCity) as UserMgmtCityData;

                            contact.mustRemove = false;

                            personList.value.push(contact);
                        }
                    })
                    .catch((err: AxiosError) => {
                        const defaultMessage = Multilang.getTranslation(
                            "user-mgmt.message.error.load-contacts",
                            "Could not load contacts."
                        );
                        Toaster.showAxiosMessage(err, defaultMessage);
                    })
                    .finally(() => {
                        isBusy.value = false;
                    });
            };

            const newContact = (): void => {
                selectedContact.value = {} as UserMgmtContactData;

                setDefaults(selectedContact.value);
                selectedContact.value.id = 0;
                selectedContact.value.userName = null as unknown as string;
                selectedContact.value.zipCity = "";
                selectedContact.value.wantsNewsletter = false;
                selectedContact.value.newsletterType = UserMgmtNewsletterSetting.None;
                selectedContact.value.effectiveRights = [];
                selectedContact.value.standardRights = [];
                selectedContact.value.canLogOn = false;

                userMgmtEditorRef.showModal();
            };

            const editContact = (contact: UserMgmtContactData): void => {
                selectedContact.value = contact;
                selectedContact.value.wantsNewsletter = selectedContact.value.newsletterType !== UserMgmtNewsletterSetting.None;

                userMgmtEditorRef.showModal();
            };

            const savedContact = (response: UserMgmtContactSaveResults, mode: UserMgmtContactAction): void => {
                let isFound = false;

                for (let person of personList.value) {
                    if (person.id === selectedContact.value.id) {
                        person = selectedContact.value;

                        if (mode === UserMgmtContactAction.RemoveUser) {
                            person.userName = null as unknown as string;
                            person.mustRemove = false;
                        } else if (mode === UserMgmtContactAction.Remove) {
                            personList.value.splice(personList.value.indexOf(person), 1);
                        } else {
                            person.mustRemove = false;
                        }

                        isFound = true;
                    }
                }

                if (!isFound) {
                    selectedContact.value.mustRemove = false;
                    personList.value.push(selectedContact.value);
                }

                if (
                    mode === UserMgmtContactAction.Create ||
                    mode === UserMgmtContactAction.Update ||
                    mode === UserMgmtContactAction.Remove ||
                    mode === UserMgmtContactAction.CreateUser ||
                    mode === UserMgmtContactAction.RemoveUser
                ) {
                    userMgmtEditorRef.hideModal();
                }

                let successMessage = "";

                if (mode === UserMgmtContactAction.Create || mode === UserMgmtContactAction.CreateUser) {
                    successMessage = Multilang.getTranslation("user-mgmt.editor.message.success.create-contact", "Het contact werd aangemaakt.");
                } else if (mode === UserMgmtContactAction.Update) {
                    successMessage = Multilang.getTranslation("user-mgmt.editor.message.success.update-contact", "Het contact werd aangepast.");
                } else if (mode === UserMgmtContactAction.Remove || mode === UserMgmtContactAction.RemoveUser) {
                    successMessage = Multilang.getTranslation("user-mgmt.editor.message.success.remove-contact", "Het contact werd verwijderd.");
                }

                if (successMessage.length > 0) {
                    Toaster.showFeedbackMessage("Success", successMessage);
                }
            };

            // FUNCTIONS
            const functionList = ref([] as UserMgmtFunctionData[]);

            const functionName = (contact: UserMgmtContactData): string => {
                for (let ftion of functionList.value) {
                    if (ftion.id === contact.functionId) {
                        return ftion.name;
                    }
                }

                return "-";
            };

            // PRODUCT SELECTION
            const productSelectionList = ref([] as UserMgmtProductSelection[]);

            // ROLES
            const internalRoleList = ref([] as UserMgmtRoleData[]);

            const getDefaultInternalRole = (): number => {
                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleIdIntern1) !== "undefined") {
                    return defaultRoleIdIntern1;
                }

                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleIdIntern2) !== "undefined") {
                    return defaultRoleIdIntern2;
                }

                return 0;
            };

            const externalRoleList = ref([] as UserMgmtRoleData[]);

            const getDefaultExternalRole = (): number => {
                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleId1) !== "undefined") {
                    return defaultRoleId1;
                }

                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleId2) !== "undefined") {
                    return defaultRoleId2;
                }

                return 0;
            };

            const roleName = (contact: UserMgmtContactData): string => {
                for (let externalRole of externalRoleList.value) {
                    if (externalRole.id === contact.roleId) {
                        return externalRole.name;
                    }
                }

                for (let internalRole of internalRoleList.value) {
                    if (internalRole.id === contact.roleId) {
                        return internalRole.name;
                    }
                }

                return "-";
            };

            // COMMON
            const setDefaults = (contact: UserMgmtContactData) => {
                if (typeof contact.roleId !== "undefined" && contact.roleId !== null) {
                    if (isInternal) {
                        if (typeof internalRoleList.value.find((role) => role.id === contact.roleId) === "undefined") {
                            contact.roleId = 0;
                        }
                    } else {
                        if (typeof externalRoleList.value.find((role) => role.id === contact.roleId) === "undefined") {
                            contact.roleId = 0;
                        }
                    }
                }

                if (typeof contact.roleId === "undefined" || contact.roleId === null || contact.roleId === 0) {
                    contact.roleId = getDefaultExternalRole();

                    if (isInternal) {
                        contact.roleId = getDefaultInternalRole();
                        contact.isInternal = true;
                    }
                }

                if (typeof contact.functionId === "undefined" || contact.functionId === 0) {
                    contact.functionId = defaultFunctionId;
                }

                if (typeof contact.gender === "undefined" || contact.gender === null) {
                    contact.gender = defaultGender;
                }

                if (typeof contact.lcid === "undefined" || contact.lcid === 0) {
                    contact.lcid = defaultLcid;
                }

                contact.encryptedCustomerId = encryptedCustomerId;
            };

            const loadExtraData = (encryptedCustomerId: string) => {
                isBusy.value = true;

                axios
                    .get(`${ActionUrls.getUrl("load-extra-data")}?ecid=${encryptedCustomerId}`)
                    .then((res: AxiosResponse) => res.data)
                    .then((data: UserMgmtExtraResults) => {
                        for (let city of data.cities) {
                            city.zipCity = (city.zipCode + " " + city.city).toUpperCase();
                            cityList.value.push(city);
                        }

                        for (let ftion of data.functions) {
                            functionList.value.push(ftion);
                        }

                        for (let productSelection of data.productSelections) {
                            productSelectionList.value.push(productSelection);
                        }

                        for (let role of data.roles) {
                            if (role.isInternal) {
                                internalRoleList.value.push(role);
                            } else {
                                externalRoleList.value.push(role);
                            }
                        }

                        loadContacts(encryptedCustomerId);
                    })
                    .catch((err: AxiosError) => {
                        const defaultMessage = Multilang.getTranslation(
                            "user-mgmt.message.error.load-extra-data",
                            "Could not load extra data."
                        );
                        Toaster.showAxiosMessage(err, defaultMessage);
                    })
                    .finally(() => {
                        isBusy.value = false;
                    });
            };

            // INIT
            const init = () => {
                loadExtraData(encryptedCustomerId);
            };

            init();

            return {
                allowEditPersons,
                allowEditRights,
                allowDeletePersons,
                allowCreatePersons,
                allowAddRemoveUsername,
                cityList,
                contactList,
                editContact,
                encryptedCustomerId,
                externalRoleList,
                functionList,
                functionName,
                internalRoleList,
                isBusy,
                isInternal,
                loadContacts,
                loginList,
                newContact,
                personList,
                productSelectionList,
                roleName,
                savedContact,
                selectedContact,
            };
        },
        mounted() {
            userMgmtEditorRef = this.$refs.userMgmtEditor as UserMgmtEditorType;
        },
    });
</script>

<style lang="scss" scoped></style>
