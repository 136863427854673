import { ref } from "vue";
import { MiniBasketResponse } from "./basket";

export default class MiniBasketData {
  static lineCount = ref(0);

  static set(data: MiniBasketResponse) {
    this.lineCount.value = data.lineCount;
  }

  static setLineCount(lineCount: number) {
    this.lineCount.value = lineCount;
  }
}

