import $ from "jquery";
import Swiper, { Autoplay, Navigation, Pagination, SwiperOptions } from 'swiper';

export default class CustomSwiper {
 
    public static init() {
        let bulletSwiperList = document.querySelectorAll("[data-nt-swiper='bullet']");
        for (let i = 0; i < bulletSwiperList.length; i++) {
            const el = bulletSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);

            new Swiper(el, {
                direction: "horizontal",
                watchOverflow: true,
                pagination: {
                    el: ".js-bullet-swiper-pagination",
                    clickable: true,
                },
                breakpoints: this.getSwiperBreakpointOptions($el),
                modules: [Pagination],
            });
        }

        let imageSwiperList = document.querySelectorAll("[data-nt-swiper='image']");
        for (let i = 0; i < imageSwiperList.length; i++) {
            const el = imageSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);

            let imageList: string[] = [];

            $el
                .find(".swiper-slide")
                .find("img")
                .each(function () {
                    imageList.push($(this).prop("src"));
                });

            new Swiper(el, {
                direction: "horizontal",
                watchOverflow: true,
                pagination: {
                    el: ".js-image-swiper-pagination",
                    clickable: true,
                    renderBullet: (index, className) => {
                        return `<img src="${imageList[index]}" alt="" class="${className}"></img>`;
                    },
                },
                breakpoints: this.getSwiperBreakpointOptions($el),
                modules: [Pagination],
            });
        }

        let navSwiperList = document.querySelectorAll("[data-nt-swiper='nav']");
        for (let i = 0; i < navSwiperList.length; i++) {
            const el = navSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);

            new Swiper(el, {
                direction: "horizontal",
                watchOverflow: true,
                navigation: {
                    prevEl: ".js-nav-swiper-button--prev",
                    nextEl: ".js-nav-swiper-button--next",
                },
                breakpoints: this.getSwiperBreakpointOptions($el),
                modules: [Navigation],
            });
        }

        let touchSwiperList = document.querySelectorAll("[data-nt-swiper='touch']");
        for (let i = 0; i < touchSwiperList.length; i++) {
            const el = touchSwiperList[i] as HTMLElement;
            const $el = $(el);

            // display just before initializing the component, to prevent non-swiper elements
            $el.toggleClass("d-none", false);
            
            const options: SwiperOptions = {
                direction: "horizontal",
                watchOverflow: true,
                breakpoints: this.getSwiperBreakpointOptions($el),
            };
            
            const setAutoplay = $el.data("nt-swiper-autoplay");
            
            if (setAutoplay) {
                options.autoplay = true,
                options.modules = [Autoplay];
            }

            new Swiper(el, options);
        }
    }

    private static getSwiperBreakpointOptions($el: JQuery<HTMLElement>): any {
        const setBreakpoints = $el.data("nt-swiper-breakpoints");

        if (setBreakpoints) {
            const initialColumns = $el.data("nt-swiper-breakpoints-col");

            if (initialColumns === 4) {
                return {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 24,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                    },
                    1200: {
                        slidesPerView: 4,
                        spaceBetween: 24,
                    },
                };
            }

            if (initialColumns === 3) {
                return {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 24,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                    992: {
                        slidesPerView: 3,
                        spaceBetween: 24,
                    },
                };
            }

            if (initialColumns === 2) {
                return {
                    0: {
                        slidesPerView: 1,
                        spaceBetween: 24,
                    },
                    768: {
                        slidesPerView: 2,
                        spaceBetween: 24,
                    },
                };
            }
        }

        // return default
        return {
            0: {
                slidesPerView: 1,
                spaceBetween: 24,
            },
        };
    }
}
