<template>
    <div id="user-mgmt-editor-modal" class="modal" tabindex="-1">
        <div class="modal-dialog modal-dialog-scrollable modal-fullscreen-sm-down">
            <div class="modal-content">
                <div class="modal-header border-0 pb-0">
                    <div v-show="isBusy" class="me-2">
                        <div class="spinner-border text-primary" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </div>

                    <div class="d-flex align-items-baseline">
                        <h4 class="modal-title me-2">{{ title }}</h4>
                        <i v-if="contact!.isInternal" class="fa fa-flash text-secondary-700 me-1"></i>
                        <span v-show="isGuid(contact!.code)" class="text-warning">{{ labelTemporary }}</span>
                        <span v-show="!isGuid(contact!.code)" class="text-neutral-400">{{ contact!.code }}</span>
                    </div>

                    <button aria-label="Close" class="btn-close" type="button" @click="hideModal"></button>
                </div>

                <div class="modal-body">
                    <form v-if="contact !== null" method="post" @submit.prevent="updateContact(contact!)">
                        <div class="mb-3" data-val="FirstName">
                            <label class="form-label mb-1" for="first-name">{{ labelFirstName }}</label>
                            <input id="first-name" v-model="contact!.firstName" class="form-control" tabindex="1" type="text" />
                            <span class="d-none text-danger" data-val-message="FirstName"></span>
                        </div>

                        <div class="mb-3" data-val="LastName">
                            <label class="form-label mb-1" for="last-name">{{ labelLastName }}</label>
                            <input id="last-name" v-model="contact!.lastName" class="form-control" tabindex="2" type="text" />
                            <span class="d-none text-danger" data-val-message="LastName"></span>
                        </div>

                        <div class="mb-3">
                            <label class="form-label mb-1" for="gender">{{ labelGender }}</label>
                            <select v-model="contact!.gender" class="form-select" tabindex="3">
                                <option v-for="gender in genderList" :key="gender.code" :value="gender.code">
                                    {{ decoder(gender.description) }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3">
                            <label class="form-label mb-1" for="language">Taal</label>
                            <select v-model="contact!.lcid" class="form-select" tabindex="4">
                                <option v-for="language in languageList" :key="language.lcid" :value="language.lcid">
                                    {{ language.description }}
                                </option>
                            </select>
                        </div>

                        <div class="mb-3" data-val="FunctionId">
                            <label class="form-label mb-1" for="function">{{ labelFunction }}</label>
                            <select v-model="contact!.functionId" class="form-select" tabindex="5">
                                <option disabled value="0">{{ labelSelect }}</option>
                                <option v-for="ftion in functionList" :key="ftion.id" :value="ftion.id">
                                    {{ ftion.name }}
                                </option>
                            </select>
                            <span class="d-none text-danger" data-val-message="FunctionId"></span>
                        </div>

                        <div class="mb-3" data-val="Email">
                            <label class="form-label mb-1" for="email">{{ labelEmail }}</label>
                            <input id="email" v-model="contact!.email" autocomplete="off" class="form-control" maxlength="320"
                                   spellcheck="false" tabindex="6" type="text" />
                            <span class="d-none text-danger" data-val-message="Email"></span>
                        </div>

                        <div v-show="contact!.userName !== null" class="mb-3">
                            <label class="form-label mb-1" for="username">{{ labelUserName }}</label>
                            <div class="input-group">
                                <input id="username" v-model="contact!.userName" class="form-control" readonly tabindex="7"
                                       type="text" />
                                <button v-show="allowAddRemoveUsername" :disabled="isBusy" class="btn btn-secondary"
                                        tabindex="8" type="button" @click="removeUserName(contact!)">
                                    {{ actionRemoveUserName }}
                                </button>
                            </div>
                        </div>

                        <div v-show="allowAddRemoveUsername && contact!.userName === null && contact!.id !== 0"
                             class="mb-3">
                            <button :disabled="isBusy" class="btn btn-secondary" tabindex="8" type="button"
                                    @click="createUserName(contact!)">
                                {{ actionCreateUserName }}
                            </button>
                            <div class="form-text">
                                {{ actionCreateUserNameHelp }}
                            </div>
                        </div>

                        <div class="mb-3" data-val="Phone">
                            <label class="form-label mb-1" for="phone">{{ labelPhone }}</label>
                            <input id="phone" v-model="contact!.phone" autocomplete="off" class="form-control" maxlength="50"
                                   spellcheck="false" tabindex="9" type="text" />
                            <span class="d-none text-danger" data-val-message="Phone"></span>
                        </div>

                        <div class="mb-3" data-val="Mobile">
                            <label class="form-label mb-1" for="mobile">{{ labelMobile }}</label>
                            <input id="mobile" v-model="contact!.mobile" autocomplete="off" class="form-control" maxlength="50"
                                   spellcheck="false" tabindex="10" type="text" />
                            <span class="d-none text-danger" data-val-message="Mobile"></span>
                        </div>

                        <div v-show="contact!.userName !== null" class="mb-3" data-val="RoleId">
                            <label class="form-label mb-1" for="role">
                                <i v-show="!contact!.isInternal" :data-bs-title="tooltipRole"
                                   class="fa fa-info-circle text-secondary-700 me-1" data-bs-html="true" data-bs-toggle="tooltip"></i>
                                {{ labelRole }}
                            </label>
                            <select id="role" v-model="contact!.roleId" :disabled="!allowEditPersons"
                                    class="form-select" tabindex="11" @change="setRoleChanged">
                                <option disabled value="0">{{ labelSelect }}</option>
                                <option v-for="role in internalRoleList.filter(r => r.signinAllowed || contact!.roleId == r.id)" v-if="contact!.isInternal" :key="role.id" :value="role.id">
                                    {{ role.name }}
                                </option>
                                <option v-for="role in externalRoleList.filter(r => r.signinAllowed || contact!.roleId == r.id)" v-if="!contact!.isInternal" :key="role.id" :value="role.id">
                                    {{ role.name }}
                                </option>
                            </select>
                            <span class="d-none text-danger" data-val-message="RoleId"></span>
                        </div>

                        <div v-show="contact!.userName !== null && mustHaveProductSelection(contact!)" class="mb-3"
                             data-val="UserName">
                            <label class="form-label mb-1" for="product-selection">
                                <i :data-bs-title="tooltipProductSelection" class="fa fa-info-circle text-secondary-700 me-1" data-bs-html="true"
                                   data-bs-toggle="tooltip"></i>
                                {{ labelProductSelection }}
                            </label>
                            <select id="product-selection" v-model="contact!.productSelectionId"
                                    class="form-select" tabindex="12" @change="setProductSelectionChanged">
                                <option disabled value="0">{{ labelSelect }}</option>
                                <option v-for="productSelection in productSelectionList" :key="productSelection.id"
                                        :value="productSelection.id">
                                    {{ productSelection.name }}
                                </option>
                            </select>
                            <span class="d-none text-danger" data-val-message="UserName"></span>
                        </div>

                        <div v-show="contact!.userName !== null && (contact!.isBlocked || contact!.isLockedOut)"
                             class="mb-3">
                            <span v-show="contact!.isBlocked" class="d-block text-danger">{{ labelBlocked }}</span>
                            <span v-show="contact!.isLockedOut" class="d-block text-warning">{{ labelLocked }}</span>
                        </div>

                        <div class="mb-3">
                            <div v-show="contact!.userName !== null" class="form-check">
                                <input id="blocked" v-model="contact!.isBlocked" class="form-check-input" tabindex="13"
                                       type="checkbox" />
                                <label class="form-check-label" for="blocked">{{ labelBlocked }}</label>
                            </div>

                            <div v-show="siteCode !== 'OUTILAC' && siteCode !== 'OUTILACFR'" class="form-check">
                                <input id="electronic-invoices" v-model="contact!.electronicInvoices" class="form-check-input"
                                       tabindex="14" type="checkbox" />
                                <label class="form-check-label" for="electronic-invoices">{{ labelElectronicInvoices }}</label>
                            </div>

                            <div v-show="siteCode !== 'OUTILAC' && siteCode !== 'OUTILACFR'" class="form-check">
                                <input id="wants-newsletter" v-model="contact!.wantsNewsletter"
                                       class="form-check-input" tabindex="15" type="checkbox"
                                       @change="toggleWantsNewsletter(contact!)" />
                                <label class="form-check-label" for="wants-newsletter">{{ labelWantsNewsletter }}</label>
                            </div>
                        </div>

                        <div v-show="contact!.wantsNewsletter && siteCode !== 'OUTILAC' && siteCode !== 'OUTILACFR'"
                             class="mb-3">
                            <div class="form-check form-check-inline">
                                <input id="newsletter-office" v-model="contact!.newsletterType" :value="1" class="form-check-input"
                                       tabindex="16" type="radio" />
                                <label class="form-check-label" for="newsletter-office">{{ labelNewsletterOffice }}</label>
                            </div>

                            <div class="form-check form-check-inline">
                                <input id="newsletter-home" v-model="contact!.newsletterType" :value="2" class="form-check-input"
                                       tabindex="17" type="radio" />
                                <label class="form-check-label" for="newsletter-home">{{ labelNewsletterHome }}</label>
                            </div>
                        </div>

                        <div v-show="contact!.newsletterType === 2" class="mb-3" data-val="AddressLine1">
                            <label class="form-label mb-1" for="address">{{ labelAddress }}</label>
                            <input id="address" v-model="contact!.addressLine1" autocomplete="off" class="form-control" maxlength="80"
                                   spellcheck="false" tabindex="18" type="text" />
                            <span class="d-none text-danger" data-val-message="AddressLine1"></span>
                        </div>

                        <div v-show="contact!.newsletterType === 2" class="mb-3" data-val="ZipCity">
                            <label class="form-label mb-1" for="city">{{ labelCity }}</label>
                            <vue-select v-model="contact!.zipCitySelection" :options="cityList" label="zipCity"></vue-select>
                            <span class="d-none text-danger" data-val-message="ZipCity"></span>
                        </div>

                        <div v-show="errorMessage.length > 0" class="alert alert-warning w-100">
                            {{ errorMessage }}
                        </div>

                        <div class="d-flex justify-content-between align-items-center w-100">
                            <div>
                                <button v-show="allowEditRights && contact!.userName !== null && contact!.id !== 0 && !roleChanged" :disabled="isBusy"
                                        class="btn btn-secondary me-2 mb-2"
                                        tabindex="-1" type="button" @click="startEditRights(contact!)">
                                    {{ actionEditRights }}
                                </button>

                                <button v-show="allowDeletePersons && contact!.id !== 0" :disabled="isBusy" class="btn btn-secondary me-2 mb-2"
                                        tabindex="-1" type="button" @click="removeContact(contact!)">
                                    {{ actionRemoveContact }}
                                </button>
                            </div>

                            <div class="d-flex flex-wrap justify-content-end">
                                <button :disabled="isBusy" class="btn btn-secondary ms-2 mb-2" type="button" @click="hideModal">
                                    {{ actionCancel }}
                                </button>

                                <button v-show="(allowEditPersons && contact!.id !== 0) || (allowCreatePersons && contact!.id === 0) " :disabled="isBusy" class="btn btn-primary ms-2 mb-2" type="submit">
                                    {{ actionSave }}
                                </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>

    <user-mgmt-rights ref="userMgmtRights" :allow-edit-persons="allowEditPersons" :allow-edit-rights="allowEditRights"
                      :contact="contact" :customer-id="customerId" :emit-context="{ savedRights }"
                      :site-code="siteCode"></user-mgmt-rights>
</template>

<script lang="ts">
    import { defineComponent, PropType, ref } from "vue";
    import axios, { AxiosError, AxiosResponse } from "axios";
    import { Modal, Tooltip } from "bootstrap";
    import VueSelect from "vue-select";
    import $$ from "../scripts/utils/double-dollar";
    import ActionUrls from "../scripts/utils/action-urls";
    import Multilang from "../scripts/utils/multilang";
    import Toaster from "../scripts/components/toaster";
    import UserMgmtRights from "./UserMgmtRights.vue";
    import {
        UserMgmtCityData,
        UserMgmtContactAction,
        UserMgmtContactData,
        UserMgmtContactSaveResults,
        UserMgmtExtraResults,
        UserMgmtFunctionData,
        UserMgmtNewsletterSetting,
        UserMgmtProductSelection,
        UserMgmtRoleData,
    } from "../scripts/models/user-mgmt";

    const defaultRoleId1 = 30; // Aankoper Deschacht
    const defaultRoleId2 = 80; // Aankoper Foylo
    const defaultRoleIdIntern1 = 20; // Binnendienst Deschacht
    const defaultRoleIdIntern2 = 70; // Binnendienst Foylo

    const rightIdNeedsProductSelection2 = 48;

    let userMgmtEditorModalInstance: Modal;
    let userMgmtRightsRef: UserMgmtRightsType;

    type EmitContextType = {
        savedContact: (data: UserMgmtContactSaveResults, mode: UserMgmtContactAction) => void;
        loadContacts: (encryptedCustomerId: string) => void;
    };

    type UserMgmtRightsType = {
        showModal: (contact: UserMgmtContactData) => void;
        hideModal: () => void;
    };

    export default defineComponent({
        name: "UserMgmtEditor",
        components: {
            UserMgmtRights,
            VueSelect
        },
        data() {
            return {
                title: Multilang.getTranslation("user-mgmt.editor.title", "Gebruiker / Contact"),

                labelTemporary: Multilang.getTranslation("user-mgmt.editor.label.temporary", "Tijdelijk"),
                labelFirstName: Multilang.getTranslation("user-mgmt.editor.label.first-name", "Voornaam"),
                labelLastName: Multilang.getTranslation("user-mgmt.editor.label.last-name", "Naam"),
                labelGender: Multilang.getTranslation("user-mgmt.editor.label.gender", "Geslacht"),
                labelLanguage: Multilang.getTranslation("user-mgmt.editor.label.language", "Taal"),
                labelFunction: Multilang.getTranslation("user-mgmt.editor.label.function", "Functie"),
                labelEmail: Multilang.getTranslation("user-mgmt.editor.label.email", "E-mail"),
                labelUserName: Multilang.getTranslation("user-mgmt.editor.label.username", "Gebruikersnaam"),
                labelPhone: Multilang.getTranslation("user-mgmt.editor.label.phone", "Telefoon"),
                labelMobile: Multilang.getTranslation("user-mgmt.editor.label.mobile", "GSM"),
                labelRole: Multilang.getTranslation("user-mgmt.editor.label.role", "Rol"),
                labelProductSelection: Multilang.getTranslation("user-mgmt.editor.label.product-selection", "Catalogus"),
                labelBlocked: Multilang.getTranslation("user-mgmt.editor.label.blocked", "Blocked"),
                labelLocked: Multilang.getTranslation("user-mgmt.editor.label.locked", "Locked"),
                labelElectronicInvoices: Multilang.getTranslation(
                    "user-mgmt.editor.label.electronic-invoices",
                    "Ja, ik wil digitale facturen ontvangen"
                ),
                labelWantsNewsletter: Multilang.getTranslation(
                    "user-mgmt.editor.label.wants-newsletter",
                    "Ja, ik wil de infokrant ontvangen"
                ),
                labelNewsletterOffice: Multilang.getTranslation("user-mgmt.editor.label.newsletter.office", "Op kantoor"),
                labelNewsletterHome: Multilang.getTranslation("user-mgmt.editor.label.newsletter.home", "Op onderstaand adres"),
                labelAddress: Multilang.getTranslation("user-mgmt.editor.label.address", "Adres"),
                labelCity: Multilang.getTranslation("user-mgmt.editor.label.city", "Gemeente"),
                labelSelect: Multilang.getTranslation("user-mgmt.editor.label.select", "-- Kies --"),

                tooltipProductSelection: Multilang.getTranslation(
                    "user-mgmt.editor.tooltip.product-selection",
                    "Kies de catalogus die deze persoon mag gebruiken."
                ),
                tooltipRole: Multilang.getTranslation(
                    "user-mgmt.editor.tooltip.role",
                    "Préparateur d’achat: Peut préparer mais ne pas envoyer  un achat, voit des prix bruts<br/>Acheteur: Peut envoyer des achats<br/>Administrateur: Peut gérer les utilisateurs et attribuer des logins et droits."
                ),

                actionCancel: Multilang.getTranslation("user-mgmt.editor.action.cancel", "Annuleren"),
                actionCreateUserName: Multilang.getTranslation(
                    "user-mgmt.editor.action.create-username",
                    "Gebruikersnaam aanmaken"
                ),
                actionCreateUserNameHelp: Multilang.getTranslation(
                    "user-mgmt.editor.action.create-username-help",
                    "Wanneer u een gebruikersnaam aanmaakt zal de persoon in kwestie een actievatiemail ontvangen. Na het volgen van de instructies in die email kan hij zich aanmelden in de webshop."
                ),
                actionEditRights: Multilang.getTranslation("user-mgmt.editor.action.edit-rights", "Rechten toekennen"),
                actionRemoveContact: Multilang.getTranslation("user-mgmt.editor.action.remove-contact", "Verwijderen"),
                actionRemoveUserName: Multilang.getTranslation("user-mgmt.editor.action.remove-username", "Verwijder"),
                actionSave: Multilang.getTranslation("user-mgmt.editor.action.save", "Bewaren"),
            };
        },
        props: {
            siteCode: String,
            allowEditPersons: Boolean,
            allowEditRights: Boolean,
            allowDeletePersons: Boolean,
            allowCreatePersons: Boolean,
            allowAddRemoveUsername: Boolean,
            contact: Object as PropType<UserMgmtContactData | null>,
            customerId: String,
            emitContext: Object as PropType<EmitContextType>,
        },
        setup: (props) => {
            let isBusy = ref(false);

            ActionUrls.load();
            Multilang.load();

            const encryptedCustomerId: string = props.customerId ?? "";
            const errorMessage = ref("");

            // CITIES
            const cityList = ref([] as UserMgmtCityData[]);

            // CONTACT
            const updateContact = (contact: UserMgmtContactData) => {
                saveContact(contact, contact.id === 0 ? UserMgmtContactAction.Create : UserMgmtContactAction.Update);
            };

            const removeContact = (contact: UserMgmtContactData) => {
                bootbox.confirm({
                    buttons: {
                        confirm: {
                            label: Multilang.getTranslation("common.yes", "Yes"),
                            className: "btn-danger",
                        },
                        cancel: {
                            label: Multilang.getTranslation("common.no", "No"),
                            className: "btn-secondary",
                        },
                    },
                    message: Multilang.getTranslation("user-mgmt.editor.message.confirm.remove-contact", "Wenst u dit contact te verwijderen?"),
                    centerVertical: true,
                    callback: function (result) {
                        if (result) {
                            contact.mustRemove = true;
                            saveContact(contact, UserMgmtContactAction.Remove);
                        }
                    },
                });
            };

            const saveContact = (contact: UserMgmtContactData, mode: UserMgmtContactAction) => {
                contact.action = mode;

                if (!contact.wantsNewsletter) {
                    contact.newsletterType = UserMgmtNewsletterSetting.None;
                }

                if (typeof contact.zipCitySelection !== "undefined") {
                    contact.zipCity = contact.zipCitySelection.zipCity;
                }

                if (typeof contact.roleId === "undefined" || contact.roleId === 0) {
                    if (contact.isInternal) {
                        contact.roleId = internalRoleList.value[0].id;
                    } else {
                        contact.roleId = externalRoleList.value[0].id;
                    }
                }

                isBusy.value = true;

                axios
                    .post<UserMgmtContactSaveResults>(ActionUrls.getUrl("save-contact"), contact)
                    .then((res) => res.data)
                    .then((data: UserMgmtContactSaveResults) => {
                        if (!data.success) {
                            if (mode === UserMgmtContactAction.CreateUser) {
                                contact.userName = null as unknown as string;
                            } else if (mode === UserMgmtContactAction.RemoveUser) {
                                contact.userName = contact.email;
                            }

                            setValidation(data);

                            return;
                        }

                        props.emitContext!.savedContact(data, mode);
                    })
                    .catch((err: AxiosError) => {
                        Toaster.showAxiosMessage(err, "");
                    })
                    .finally(() => {
                        isBusy.value = false;
                    });
            };

            // GENDER
            const genderList = [
                { code: "Male", description: Multilang.getTranslation("gender.male", "Man") },
                { code: "Female", description: Multilang.getTranslation("gender.female", "Vrouw") },
            ];

            // LANGUAGES
            let languageList: any = [];

            if (props.siteCode!.toUpperCase() === "MPP") {
                languageList.push({ lcid: 2057, description: Multilang.getTranslation("language.2057", "EN") });
                languageList.push({ lcid: 1031, description: Multilang.getTranslation("language.1031", "DE") });
            } else {
                languageList.push({ lcid: 2067, description: Multilang.getTranslation("language.2067", "NL") });
                languageList.push({ lcid: 1036, description: Multilang.getTranslation("language.1036", "FR") });
            }

            // FUNCTIONS
            const functionList = ref([] as UserMgmtFunctionData[]);

            // NEWSLETTER
            const toggleWantsNewsletter = (contact: UserMgmtContactData) => {
                if (!contact.wantsNewsletter) {
                    contact.newsletterType = UserMgmtNewsletterSetting.Company;
                }
            };

            // PRODUCT SELECTION
            const productSelectionList = ref([] as UserMgmtProductSelection[]);

            const mustHaveProductSelection = (contact: UserMgmtContactData) => {
                if (typeof contact === "undefined") {
                    return false;
                }

                if (typeof contact.effectiveRights === "undefined") {
                    return false;
                }

                const value = contact.effectiveRights.indexOf(rightIdNeedsProductSelection2) >= 0;

                if (!value) {
                    contact.productSelectionId = 0;
                }

                return value;
            };

            const setProductSelectionChanged = () => {
                // do nothing
            };

            // RIGHTS
            const startEditRights = (contact: UserMgmtContactData) => {
                userMgmtRightsRef.showModal(contact);
            };

            const savedRights = () => {
                userMgmtRightsRef.hideModal();
            };

            // ROLES
            const internalRoleList = ref([] as UserMgmtRoleData[]);

            const getDefaultInternalRole = (): number => {
                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleIdIntern1) !== "undefined") {
                    return defaultRoleIdIntern1;
                }

                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleIdIntern2) !== "undefined") {
                    return defaultRoleIdIntern2;
                }

                return 0;
            };

            const externalRoleList = ref([] as UserMgmtRoleData[]);

            const getDefaultExternalRole = (): number => {
                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleId1) !== "undefined") {
                    return defaultRoleId1;
                }

                if (typeof externalRoleList.value.find((role) => role.id === defaultRoleId2) !== "undefined") {
                    return defaultRoleId2;
                }

                return 0;
            };

            const roleChanged = ref(false);

            const setRoleChanged = () => {
                roleChanged.value = true;
            };

            // USERNAME
            const createUserName = (contact: UserMgmtContactData) => {
                bootbox.confirm({
                    buttons: {
                        confirm: {
                            label: Multilang.getTranslation("common.yes", "Yes"),
                            className: "btn-primary",
                        },
                        cancel: {
                            label: Multilang.getTranslation("common.no", "No"),
                            className: "btn-secondary",
                        },
                    },
                    message: Multilang.getTranslation(
                        "user-mgmt.editor.message.confirm.create-username",
                        "Wenst u een gebruikersnaam aan te maken?"
                    ),
                    centerVertical: true,
                    callback: function (result) {
                        if (result) {
                            contact.userName = contact.email;
                            contact.roleId = getDefaultExternalRole();

                            if (contact.isInternal) {
                                contact.roleId = getDefaultInternalRole();
                                contact.isInternal = true;
                            }

                            saveContact(contact, UserMgmtContactAction.CreateUser);
                        }
                    },
                });
            };

            const removeUserName = (contact: UserMgmtContactData) => {
                bootbox.confirm({
                    buttons: {
                        confirm: {
                            label: Multilang.getTranslation("common.yes", "Yes"),
                            className: "btn-danger",
                        },
                        cancel: {
                            label: Multilang.getTranslation("common.no", "No"),
                            className: "btn-secondary",
                        },
                    },
                    message: Multilang.getTranslation(
                        "user-mgmt.editor.message.confirm.remove-username",
                        "Wenst u de gebruikersnaam te verwijderen?"
                    ),
                    centerVertical: true,
                    callback: function (result) {
                        if (result) {
                            saveContact(contact, UserMgmtContactAction.RemoveUser);
                        }
                    },
                });
            };

            // COMMON
            const decoder = (value: string): string => {
                const textArea = document.createElement("textarea");
                textArea.innerHTML = value;

                return textArea.value;
            };

            const isGuid = (value: string | null | undefined): boolean => {
                if (typeof value === "undefined") {
                    return false;
                }

                if (value === null || value == "") {
                    return false;
                }

                if (value[0] === "{") {
                    value = value.substring(1, value.length - 1);
                }

                const regexGuid = /^(\{){0,1}[0-9a-fA-F]{8}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{4}\-[0-9a-fA-F]{12}(\}){0,1}$/gi;
                return regexGuid.test(value);
            };

            const loadExtraData = (encryptedCustomerId: string) => {
                isBusy.value = true;

                axios
                    .get(`${ActionUrls.getUrl("load-extra-data")}?ecid=${encryptedCustomerId}`)
                    .then((res: AxiosResponse) => res.data)
                    .then((data: UserMgmtExtraResults) => {
                        for (let city of data.cities) {
                            city.zipCity = (city.zipCode + " " + city.city).toUpperCase();
                            cityList.value.push(city);
                        }

                        for (let ftion of data.functions) {
                            functionList.value.push(ftion);
                        }

                        for (let productSelection of data.productSelections) {
                            productSelectionList.value.push(productSelection);
                        }

                        for (let role of data.roles) {
                            if (role.isInternal) {
                                internalRoleList.value.push(role);
                            } else {
                                externalRoleList.value.push(role);
                            }
                        }
                    })
                    .catch((err: AxiosError) => {
                        const defaultMessage = Multilang.getTranslation(
                            "user-mgmt.message.error.load-extra-data",
                            "Could not load extra data."
                        );
                        Toaster.showAxiosMessage(err, defaultMessage);
                    })
                    .finally(() => {
                        isBusy.value = false;
                    });
            };

            const setValidation = (response: UserMgmtContactSaveResults) => {
                errorMessage.value = response.errorMessage;

                if (response.validation !== null && response.validation.length > 0) {
                    for (let validationItem of response.validation) {
                        const selector = '[data-val="' + validationItem.field + '"]';
                        $(selector).find("input").addClass("input-validation-error");
                        $(selector).find("select").addClass("input-validation-error");
                        $(selector).find("[data-val-message]").removeClass("d-none");
                        $(selector).find("[data-val-message]").text(validationItem.message);
                    }
                }
            };

            // INIT
            const init = () => {
                loadExtraData(encryptedCustomerId);
            };

            init();

            return {
                cityList,
                createUserName,
                decoder,
                errorMessage,
                externalRoleList,
                functionList,
                genderList,
                internalRoleList,
                isBusy,
                isGuid,
                languageList,
                mustHaveProductSelection,
                productSelectionList,
                removeContact,
                removeUserName,
                roleChanged,
                savedRights,
                setProductSelectionChanged,
                setRoleChanged,
                startEditRights,
                toggleWantsNewsletter,
                updateContact,
            };
        },
        methods: {
            showModal() {
                userMgmtEditorModalInstance ??= new Modal(document.getElementById("user-mgmt-editor-modal") as HTMLElement);
                userMgmtEditorModalInstance.show();

                $$(`[data-bs-toggle="tooltip"]`, (el) => new Tooltip(el));

                this.errorMessage = "";

                $$("[data-val]", (el) => {
                    $(el).find("input").removeClass("input-validation-error");
                    $(el).find("select").removeClass("input-validation-error");
                    $(el).find("[data-val-message]").addClass("d-none");
                });
            },
            hideModal() {
                this.emitContext!.loadContacts(this.contact!.encryptedCustomerId);

                userMgmtEditorModalInstance ??= new Modal(document.getElementById("user-mgmt-editor-modal") as HTMLElement);
                userMgmtEditorModalInstance.hide();
            }
        },
        mounted() {
            userMgmtRightsRef = this.$refs.userMgmtRights as UserMgmtRightsType;
        }
    });
</script>

<style lang="scss" scoped></style>
