﻿import $ from "jquery";
import Multilang from "../utils/multilang";
import MiniBasketData from "../models/mini-basket-data";

export default class Documents {
    private static ProductsInBasketMessage: string;
    private static RemoveProductsMessage: string;
    private static CancelOrderQuoteMessage: string;

    static init() {
        Documents.ProductsInBasketMessage = Multilang.getTranslation("documents.products.in.basket");
        Documents.RemoveProductsMessage = Multilang.getTranslation("documents.remove.products");
        Documents.CancelOrderQuoteMessage = Multilang.getTranslation("documents.cancel.orderquote");

        Documents.initPager();
        Documents.initQuote();

        $(".js-documents--search-form").on(
            "change",
            ".js-documents--search-value, .js-documents--start-date, .js-documents--end-date",
            (e) => {
                const $form = $(e.currentTarget).closest("form");
                $form.trigger("submit");
            }
        );

        $(".js-documents--search-form").on("change", ".js-documents--status", (e) => {
            const $form = $(e.currentTarget).closest("form");
            $form.trigger("submit");
        });
    }

    static initQuote() {
        $(".js-order-quote").on("click", (e) => {
            const productCount = MiniBasketData.lineCount;

            let url = $(e.currentTarget).prop("href");
            const queryString = $(".nt-form-search").serialize();
            url = url + "&" + queryString;

            if (productCount.value > 0) {
                bootbox.confirm({
                    size: "small",
                    message: Documents.ProductsInBasketMessage,
                    buttons: {
                        confirm: {
                            label: Documents.RemoveProductsMessage,
                            className: ""
                        },
                        cancel: {
                            label: Documents.CancelOrderQuoteMessage,
                            className: ""
                        }
                    },
                    callback: function (result) {
                        if (result) {
                            window.location.href = url ?? "";
                        }
                    }
                });

                e.preventDefault();
                return false;
            } else {
                window.location.href = url;

                e.preventDefault();
                return false;
            }
        });
    }

    private static initPager() {
        $(".js-documents .js-pager-navigate").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();

            const pageIndex = $(e.currentTarget).data("nt-page-index");

            $("#PageIndex").val(pageIndex);
            $(".js-documents--search-form").trigger("submit");
        });
    }
}
