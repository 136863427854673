<template>
    <div id="js-lists-form" v-cloak>
        <div class="row">
            <div class="col-md-6">
                <div v-show="loadDone == false">
                    <div class="spinner-border text-primary" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                </div>
            </div>
        </div>

        <div class="nt-responsive-table" v-show="loadDone">
            <div class="nt-responsive-table__header d-none d-md-block">
                <div class="nt-responsive-table__header-row">
                    <div class="nt-responsive-table__header-row-item lists__col-chevron text-start">
                        &nbsp;
                    </div>
                    <div class="nt-responsive-table__header-row-item lists__col-name">
                        {{ labels.headerName }}
                    </div>
                    <div class="nt-responsive-table__header-row-item lists__col-created">
                        {{ labels.headerCreated }}
                    </div>
                    <div class="nt-responsive-table__header-row-item lists__col-count text-end">
                        {{ labels.headerCount }}
                    </div>
                    <div class="nt-responsive-table__header-row-item lists__col-actions text-end">
                        &nbsp;
                    </div>
                </div>
            </div>

            <div class="nt-responsive-table__body">
                <div class="nt-responsive-table__body-row" v-show="isBusy && loadProgress > 0">
                    <div class="nt-responsive-table__body-row-item col-12">
                        <div class="progress" role="progressbar" aria-label="Loading">
                            <div class="progress-bar bg-primary" :style="{ 'width': progressBarProgress + '%'}"></div>
                        </div>
                    </div>
                </div>

                <template v-for="list in lists" :key="list.id">
                    <div class="nt-responsive-table__body-row">
                        <div class="nt-responsive-table__body-row-item lists__col-chevron order-0 text-start nt-cursor-pointer">
                            <i @click="toggleExpand(list)" v-show="!list.expanded && list.hasDetails" class="fa fa-chevron-right" :data-document-id="list.id"></i>
                            <i @click="toggleExpand(list)" v-show="list.expanded && list.hasDetails" class="fa fa-chevron-down" :data-document-id="list.id"></i>
                        </div>

                        <div class="nt-responsive-table__body-row-item lists__col-name order-1">
                            <span v-show="!list.editMode"><i class="fa-solid fa-lock" v-show="list.fixed"></i>{{list.name}}</span>
                            <a href="#" @click.prevent="toggleListEdit(list)" v-show="!list.editMode && !list.fixed" class="mx-2">
                                <i class="fa fa-pencil" :title="labels.changeNameTooltip"></i>
                            </a>

                            <div class="input-group" v-show="list.editMode">
                                <input type="text" v-model="list.newName" @keyup.enter="saveListName(list)" @keyup.esc="toggleListEdit(list)" maxlength="64" :ref="'nameInput' + list.id" class="form-control" />
                                <button type="button" class="btn btn-outline-secondary" @click="saveListName(list)">
                                    <i class="fa fa-save"></i>
                                </button>
                            </div>
                        </div>

                        <div class="nt-responsive-table__body-row-item lists__col-created order-3 order-md-2">
                            <div class="d-flex flex-md-column justify-content-between justify-content-md-start">
                                <span class="d-block">{{ list.created }}</span>
                                <span class="d-block text-neutral-400">{{ list.createdBy }}</span>
                            </div>
                        </div>

                        <div class="nt-responsive-table__body-row-item lists__col-count order-2 order-md-3 text-end">
                            <span v-show="list.detailsLoaded">{{ itemCountAndSelected(list) }}</span>
                        </div>

                        <div class="nt-responsive-table__body-row-item lists__col-actions order-4 text-end" v-show="list.editMode">
                            <button type="button" class="btn btn-outline-secondary" v-on:click="toggleListEdit(list)">
                                <i class="fa-solid fa-arrow-left"></i>
                            </button>
                        </div>

                        <div class="nt-responsive-table__body-row-item lists__col-actions order-4 text-end" v-show="!list.editMode">
                            <button type="button" class="btn btn-secondary ms-2 mb-2" @click="listToBasket(list)" v-show="allowAddToBasket && list.items.length > 0 && hasSomeWithOrderQuantity(list)">
                                <i class="fa fa-shopping-cart me-2"></i>
                                {{ labels.toBasketButton }}
                            </button>

                            <button type="button" class="btn btn-secondary ms-2 mb-2" @click="exportList(list)" v-show="list.items.length > 0 || list.lineCount > 0">
                                <i class="fa fa-download me-2"></i>
                                {{ labels.downloadBarcodesButton }}
                            </button>

                            <button type="button" class="btn btn-secondary ms-2 mb-2" @click="printBarcodes(list)" v-show="list.items.length > 0 || list.lineCount > 0">
                                <i class="fa fa-print me-2"></i>
                                {{ labels.downloadPdfButton }}
                            </button>

                            <button type="button" class="btn btn-link ms-2 mb-2" @click="deleteList(list)" v-show="!list.fixed">
                                <i class="fa fa-trash-can" :title="labels.deleteListTooltip"></i>
                            </button>

                            <span type="button" class="btn btn-link ms-2 mb-2" @click="toggleLockList(list)" v-show="canLock">
                                <i class="fa fa-lock"></i>
                            </span>
                        </div>
                    </div>

                    <div class="nt-responsive-table__body-row" v-show="list.expanded && list.detailsLoaded === false">
                        <div class="nt-responsive-table__body-row-item lists__col-chevron">&nbsp;</div>
                        <div class="nt-responsive-table__body-row-item lists__col-sub">
                            <div class="spinner-border text-primary" role="status">
                                <span class="visually-hidden">Loading...</span>
                            </div>
                        </div>
                    </div>

                    <div class="nt-responsive-table__body-row" v-show="list.expanded && list.detailsLoaded">
                        <div class="nt-responsive-table__body-row-item lists__col-chevron">&nbsp;</div>
                        <div class="nt-responsive-table__body-row-item lists__col-sub">
                            <list-products :dto="list" :context="parentContext" />

                            <div class="nt-responsive-table__body-row-item text-end" v-show="!list.editMode">
                                <button type="button" class="btn btn-secondary ms-2 mb-2" @click="listToBasket(list)" v-show="allowAddToBasket && list.items.length > 0 && hasSomeWithOrderQuantity(list)">
                                    <i class="fa fa-shopping-cart me-2"></i>
                                    {{ labels.toBasketButton }}
                                </button>
                            </div>

                        </div>
                    </div>

                    <hr class="d-block d-md-none my-3" />
                </template>

                <div class="nt-responsive-table__body-row" v-show="lists.length === 0">
                    <div class="nt-responsive-table__body-row-item lists__col-chevron">&nbsp;</div>
                    <div class="nt-responsive-table__body-row-item lists__col-sub text-neutral-400">
                        {{ labels.noLists }}
                    </div>
                </div>
            </div>
        </div>

        <!-- Barcodes Modal Form -->
        <print-form ref="printForm" />
        <!-- /Barcodes Modal Form -->
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import axios from "axios";
    import $$ from "../scripts/utils/double-dollar";
    import { Popover, Tooltip } from "bootstrap";
    import ActionUrls from "../scripts/utils/action-urls";
    import CustomHelper from "../scripts/utils/custom-helper";
    import DatalayerHelper from "../scripts/utils/datalayer-helper";
    import MiniBasketData from "../scripts/models/mini-basket-data";
    import Multilang from "../scripts/utils/multilang";
    import ListProducts from "../components/ListProducts.vue";
    import PrintForm from "../components/PrintForm.vue";
    import { ListDto, ListItemDto, ListDtoFuncType, ListItemDtoFuncType, ParentContextType, NoParamsFuncType } from "../scripts/models/lists";

    type PrintFormType = {
        openForm: (listId: number) => void;
    }

    interface Labels {
        confirmDeleteListPrompt: string;
        confirmDeleteListButtonCancel: string;
        confirmDeleteListButtonConfirm: string;
        confirmListToBasketPrompt: string;
        confirmListToBasketButtonCancel: string;
        confirmListToBasketButtonConfirm: string;
        headerName: string;
        headerCreated: string;
        headerCount: string;
        noLists: string;
        toBasketButton: string;
        downloadBarcodesButton: string;
        downloadPdfButton: string;
        changeNameTooltip: string;
        deleteListTooltip: string
    }

    interface ListResponseData {
        success: boolean;
        canLock: boolean;
        canEditCustomerProductCodes: boolean;
        lists: ListDto[];
    }

    interface ToggleLockListResponseData {
        success: boolean;
        fixed: boolean;
    }

    interface ListToBasketRequestData {
        listId: number;
        listLineIds: number[];
    }

    interface ListToBasketResponseData {
        success: boolean;
        message: string;
        location: string;
        lineCount: number;
    }

    interface DeleteListResponseData {
        success: boolean;
    }

    interface RenameListResponseData {
        success: boolean;
    }

    interface ExportListResponseData {
        success: boolean;
    }

    interface ListDetailResponseData {
        success: boolean;
        noDetails: boolean;
        lineCount: number;
        lists: ListDto[];
    }

    interface ChangeQuantityResponseData {
        success: boolean;
        quantity: number;
        orderQuantity: number;
    }

    interface ListActionUrls {
        loadLists: string;
        loadDetails: string;
        renameList: string;
        toggleLockList: string;
        changeItemQuantity: string;
        listToBasket: string;
        deleteList: string;
        exportList: string;
        downloadList: string;
    }

    interface ComponentData {
        loadDone: boolean;
        isBusy: boolean;
        loadProgress: number;
        lists: ListDto[];
        canLock: boolean;
        listActionUrls: ListActionUrls;
        labels: Labels;
        itemsToSave: ListItemDto[];
        parentContext: ParentContextType;
        allowAddToBasket: boolean;
        allowEditCustomerProductCodes: boolean;
    }

    export default defineComponent({
        name: "Lists",
        data() {
            return {
                loadDone: false,
                isBusy: false,
                canLock: false,
                loadProgress: 0,
                lists: [],
                itemsToSave: [],
                allowAddToBasket: (this.canAddToBasket as unknown) == "true",
                allowEditCustomerProductCodes: (this.canEditCustomerProductCodes as unknown) == "true",
                labels:
                {
                    headerName: Multilang.getTranslation("lists.label.name"),
                    headerCreated: Multilang.getTranslation("lists.label.created"),
                    headerCount: Multilang.getTranslation("lists.label.count"),
                    noLists: Multilang.getTranslation("lists.label.none"),
                    toBasketButton: Multilang.getTranslation("list.tobasket.button"),
                    downloadBarcodesButton: Multilang.getTranslation("list.download.excel.button"),
                    downloadPdfButton: Multilang.getTranslation("list.download.pdf.button"),
                    changeNameTooltip: Multilang.getTranslation("list.editname.tooltip"),
                    deleteListTooltip: Multilang.getTranslation("list.delete.tooltip"),

                    confirmDeleteListPrompt: Multilang.getTranslation("lists.delete.list.confirm"),
                    confirmDeleteListButtonCancel: Multilang.getTranslation("lists.delete.list.confirm.button.cancel"),
                    confirmDeleteListButtonConfirm: Multilang.getTranslation("lists.delete.list.confirm.button.confirm"),

                    confirmListToBasketPrompt: Multilang.getTranslation("lists.tobasket.confirm"),
                    confirmListToBasketButtonCancel: Multilang.getTranslation("lists.tobasket.confirm.button.cancel"),
                    confirmListToBasketButtonConfirm: Multilang.getTranslation("lists.tobasket.confirm.button.confirm"),
                },
                listActionUrls: {
                    loadLists: ActionUrls.getUrl("load-lists"),
                    loadDetails: ActionUrls.getUrl("load-details"),
                    renameList: ActionUrls.getUrl("rename-list"),
                    toggleLockList: ActionUrls.getUrl("toggle-lock-list"),
                    changeItemQuantity: ActionUrls.getUrl("change-item-quantity"),
                    listToBasket: ActionUrls.getUrl("list-to-basket"),
                    deleteList: ActionUrls.getUrl("delete-list"),
                    exportList: ActionUrls.getUrl("export-list"),
                    downloadList: ActionUrls.getUrl("download-list"),
                },
                parentContext: {
                    addToSaveQueue: this.addToSaveQueue as ListItemDtoFuncType,
                    saveItemFromQueue: this.saveItemFromQueue as NoParamsFuncType,
                    cancelAllEdits: this.cancelAllEdits as NoParamsFuncType,
                    saveAllUnsavedItems: this.saveAllUnsavedItems as ListDtoFuncType,
                    saveItemQuantity: this.saveItemQuantity as ListItemDtoFuncType,
                    allowEditCustomerProductCodes: (this.canEditCustomerProductCodes as unknown) == "true",
                }
            } as ComponentData;
        },
        props: {
            canAddToBasket: {
                type: String,
                default: "false"
            },
            canEditCustomerProductCodes: {
                type: String,
                default: "false"
            }
        },
        components:
        {
            ListProducts,
            PrintForm
        },
        setup: (props) => {
            let canAddToBasket: string = props.canAddToBasket;
            let canEditCustomerProductCodes: string = props.canEditCustomerProductCodes;
            // console.log("List.vue setup ", canAddToBasket, canEditCustomerProductCodes);

            return { canAddToBasket, canEditCustomerProductCodes };
        },
        methods: {
            onCreateClick() { },
            onSelect() { },
            loadData() {
                // console.log("Lists.vue loadData()!", this.listActionUrls.loadLists, this.listActionUrls.loadDetails);

                axios
                    .post<ListResponseData>(this.listActionUrls.loadLists)
                    .then((res) => res.data)
                    .then((data: ListResponseData) => {
                        this.canLock = data.canLock;
                        this.allowEditCustomerProductCodes = data.canEditCustomerProductCodes;

                        for (let l of data.lists) {
                            l.detailsLoaded = false;
                            l.expanded = false;
                            l.editMode = false;
                            l.newName = l.name;
                            l.isAllSelected = true;

                            this.lists.push(l);
                        }

                        this.loadDone = true;

                        setTimeout(() => {
                            this.loadProgress = 0;
                            this.isBusy = true;
                            this.loadListDetails();
                        }, 100);
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadDone = true;
                    });
            },
            loadListDetails() {
                if (this.lists.length === 0) {
                    this.loadDone = true;
                    this.isBusy = false;
                    return;
                }

                let id = 0;
                let theList: ListDto;

                for (let l of this.lists) {
                    if (l.detailsLoaded === false && l.expanded) {
                        id = l.id;
                        theList = l;
                        break;
                    }
                }

                if (id === 0) {
                    for (let l of this.lists) {
                        if (l.detailsLoaded === false && l.expanded === false) {
                            id = l.id;
                            theList = l;
                            break;
                        }
                    }
                }

                if (id === 0) {
                    this.loadDone = true;
                    this.isBusy = false;
                    this.initPopovers();
                    this.initTooltips();
                    return;
                }

                this.loadProgress++;

                axios
                    .post<ListDetailResponseData>(this.listActionUrls.loadDetails + "?id=" + id)
                    .then((res) => res.data)
                    .then((data: ListDetailResponseData) => {

                        if (data.success) {
                            theList.hasDetails = !data.noDetails;
                            theList.items = [];

                            if (theList.hasDetails === false) {
                                theList.detailsLoaded = true;
                                theList.lineCount = data.lineCount;
                            } else {
                                const list = data.lists[0];

                                for (let i of list.items) {
                                    i.selected = true;
                                    i.editMode = false;
                                    i.newQuantity = i.quantity;
                                    i.newCustomerProductCode = i.customerProductCode;
                                    i.orderQuantity = i.quantity;
                                    theList.items.push(i);
                                }

                                theList.lineCount = theList.items.length;
                                theList.detailsLoaded = true;
                            }

                            this.loadDone = true;

                            setTimeout(() => {
                                this.loadListDetails();
                            }, 100);
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                        this.loadDone = true;
                        this.isBusy = false;
                    });
            },
            addToSaveQueue(item: ListItemDto) {
                let previx = -1;
                for (let ix = 0; ix < this.itemsToSave.length; ++ix) {
                    if (this.itemsToSave[ix].lineId == item.lineId) {
                        previx = ix;
                        this.itemsToSave[ix].orderQuantity = item.orderQuantity;
                        this.itemsToSave[ix].quantity = item.quantity;
                        break;
                    }
                }
                if (previx == -1) {
                    this.itemsToSave.push(item);
                }

                this.saveItemFromQueue();
            },
            initPopovers() {
                $$(`[data-bs-toggle="popover"]`, el => new Popover(el));
                $$(`[data-bs-toggle="popover-dom"]`, el => new Popover(el, { content: CustomHelper.getPopoverContent(el) }));
            },
            initTooltips() {
                $$(`[data-bs-toggle="tooltip"]`, el => new Tooltip(el));
            },
            hasSomeSelected(list: ListDto) {
                for (var item of list.items) {
                    if (item.selected) {
                        return true;
                    }
                }

                return false;
            },
            hasSomeWithOrderQuantity(list: ListDto) {
                for (let item of list.items) {
                    if (item.orderQuantity > 0) {
                        return true;
                    }
                }

                return false;
            },
            toggleExpand(list: ListDto) {
                list.expanded = !list.expanded;
                list.editMode = false;

                for (let i of list.items) {
                    i.editMode = false;
                }
            },
            canConvertToBasket(list: ListDto) {
                for (let i of list.items) {
                    if (i.quantity > 0) {
                        return true;
                    }
                }

                return false;
            },
            cancelAllEdits() {
                for (let list of this.lists) {
                    list.editMode = false;

                    for (let item of list.items) {
                        item.editMode = false;
                    }
                }
            },
            toggleListEdit(list: ListDto) {
                let temp = !list.editMode;
                this.cancelAllEdits();
                list.editMode = temp;

                if (!list.editMode) {
                    list.newName = list.name;
                } else {
                    ////Vue.nextTick(function () {
                    ////    const ref = Lists.vueApp.$refs["nameInput" + list.id][0];
                    ////    ref.focus();
                    ////    ref.select();
                    ////});
                }
            },
            itemCountAndSelected(list: ListDto) {
                let count = list.items.length;
                let selected = 0;

                if (!list.hasDetails) {
                    return +list.lineCount;
                }

                for (let item of list.items) {
                    if (item.orderQuantity > 0) {
                        selected += 1;
                    }
                }

                if (count === selected) { return +count; }
                else { return selected + "/" + count; }
            },
            listToBasket(list: ListDto) {
                this.saveAllUnsavedItems(list);
                this.cancelAllEdits();

                const ix = this.lists.indexOf(list);
                if (ix === -1) {
                    return;
                }

                CustomHelper.confirmWithCustomButtonLabels(
                    this.labels.confirmListToBasketPrompt,
                    this.labels.confirmListToBasketButtonCancel,
                    this.labels.confirmListToBasketButtonConfirm,
                    () => { this.listToBasketReal(list); },
                );
            },
            listToBasketReal(list: ListDto) {
                let postData: ListToBasketRequestData = { listId: list.id, listLineIds: [] };

                for (let listLine of list.items) {
                    if (listLine.orderQuantity > 0) {
                        postData.listLineIds.push(listLine.lineId);
                    }
                }

                axios
                    .post<ListToBasketResponseData>(this.listActionUrls.listToBasket, postData)
                    .then((res) => res.data)
                    .then((data: ListToBasketResponseData) => {
                        if (data.success) {
                            if (data.message != null) {
                                toastr.options.closeButton = true;
                                toastr.options.timeOut = 60000;
                                toastr.options.progressBar = false;
                                toastr.warning(data.message);
                            }

                            MiniBasketData.setLineCount(data.lineCount);
                            DatalayerHelper.trackEvent("bm_lists_add_to_cart", list.id.toString(), () => {
                              document.location.href = data.location;
                            });
                        } else {
                            toastr.options.progressBar = true;
                            toastr.error(data.message);
                        }
                    });
            },
            exportList(list: ListDto) {
                this.saveAllUnsavedItems(list);

                const postData = { listId: list.id }

                axios
                    .post<ExportListResponseData>(this.listActionUrls.exportList, postData)
                    .then((res) => res.data)
                    .then((data: ExportListResponseData) => {
                        if (data.success) {
                            DatalayerHelper.trackEvent("bm_lists_export_excel", list.id.toString());
                            let url: string = this.listActionUrls.downloadList + "?id=" + list.id;
                            window.open(url, "_blank");
                        }
                    });
            },
            printBarcodes(list: ListDto) {
                this.saveAllUnsavedItems(list);
                this.isBusy = false;

                let printForm = this.$refs.printForm as PrintFormType;
                printForm.openForm(list.id);
            },
            deleteList(list: ListDto) {
                this.cancelAllEdits();

                const ix = this.lists.indexOf(list);
                if (ix === -1) {
                    return;
                }

                CustomHelper.confirmWithCustomButtonLabels(
                    this.labels.confirmDeleteListPrompt,
                    this.labels.confirmDeleteListButtonCancel,
                    this.labels.confirmDeleteListButtonConfirm,
                    () => {
                        const postData = { listId: list.id }

                        axios
                            .post<DeleteListResponseData>(this.listActionUrls.deleteList, postData)
                            .then((res) => res.data)
                            .then((data: DeleteListResponseData) => {
                                if (data.success) {
                                    this.lists.splice(ix, 1);
                                }
                            });
                    });
            },
            saveAllUnsavedItems(list: ListDto) {
                for (let item of list.items) {
                    if (item.editMode) {
                        item.editMode = false;
                        this.itemsToSave.push(item);
                    }
                }

                this.saveItemFromQueue();
            },
            saveItemFromQueue() {
                if (this.itemsToSave.length === 0) {
                    return;
                }

                let item = this.itemsToSave.pop();

                if (typeof item !== "undefined") {
                    this.saveItemQuantity(item);
                }

                setTimeout(this.saveItemFromQueue, 100);
            },
            saveItemQuantity(item: ListItemDto) {
                if (item.minimumOrderQuantity > 1.0) {
                    if (item.newQuantity % item.minimumOrderQuantity > 0.0) {
                        item.newQuantity = Math.round(item.minimumOrderQuantity * (Math.floor(item.newQuantity / item.minimumOrderQuantity) + 1) * 100) / 100;
                    }

                    if (item.orderQuantity % item.minimumOrderQuantity > 0.0) {
                        item.orderQuantity = Math.round(item.minimumOrderQuantity * (Math.floor(item.orderQuantity / item.minimumOrderQuantity) + 1) * 100) / 100;
                    }
                }

                const postData = {
                    lineId: item.lineId,
                    listId: item.listId,
                    quantity: item.newQuantity,
                    orderQuantity: item.orderQuantity
                };

                axios
                    .post<ChangeQuantityResponseData>(this.listActionUrls.changeItemQuantity, postData)
                    .then((res) => res.data)
                    .then((data: ChangeQuantityResponseData) => {
                        if (data.success) {
                            item.quantity = data.quantity;
                            item.orderQuantity = data.orderQuantity;
                            item.editMode = false;
                        }
                    });
            },
            saveListName(list: ListDto) {
                const postData = { name: list.newName, listId: list.id };

                axios
                    .post<RenameListResponseData>(this.listActionUrls.renameList, postData)
                    .then((res) => res.data)
                    .then((data: RenameListResponseData) => {
                        if (data.success) {
                            list.name = list.newName;
                            list.editMode = false;
                            for (var i of list.items) {
                                i.editMode = false;
                            }
                        }
                    });
            },
            toggleLockList(list: ListDto) {
                this.cancelAllEdits();

                const postData = { listId: list.id };

                axios
                    .post<ToggleLockListResponseData>(this.listActionUrls.toggleLockList, postData)
                    .then((res) => res.data)
                    .then((data: ToggleLockListResponseData) => {
                        if (data.success) {
                            list.fixed = data.fixed;
                        }
                    });
            }
        },
        computed: {
            progressBarProgress(): Number {
                return this.loadProgress / this.lists.length * 100;
            },
        },
        created() {
            this.loadData();
        },
        mounted() {
        }
    });
</script>

<style lang="scss" scoped></style>
