export enum UserMgmtContactAction {
    Update,
    Create,
    Remove,
    CreateUser,
    RemoveUser
}

export enum UserMgmtNewsletterSetting {
    None,
    Company,
    Home,
}

export interface UserMgmtCityData {
    zipCode: string;
    city: string;
    zipCity: string;
}

export interface UserMgmtContactData {
    id: number;
    code: string;
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
    mobile: string;
    addressLine1: string;
    zipCity: string;
    zipCitySelection: UserMgmtCityData;
    gender: string;
    lcid: number;
    functionId: number;
    productSelectionId: number;
    roleId: number;
    userName: string;
    isBlocked: boolean;
    isLockedOut: boolean;
    canLogOn: boolean;
    isInternal: boolean;
    validateErrors: any;
    mustRemove: boolean;
    encryptedCustomerId: string;
    newsletterType: UserMgmtNewsletterSetting;
    wantsNewsletter: boolean;
    electronicInvoices: boolean;
    effectiveRights: number[];
    standardRights: number[];
    action: UserMgmtContactAction;
}

export interface UserMgmtFunctionData {
    id: number;
    code: string;
    name: string;
}

export interface UserMgmtRightData {
    id: number;
    name: string;
    description: string;
    disabled: boolean;
    isInternal: boolean;
    isExternal: boolean;
    isActive: boolean;
    isStandard: boolean;
}

export interface UserMgmtRoleData {
    id: number;
    isInternal: string;
    signinAllowed: string;
    name: string;
}

export interface UserMgmtProductSelection {
    id: number;
    name: string;
}

export interface UserMgmtContactsResults {
    contacts: UserMgmtContactData[];
    encryptedCustomerId: string;
}

export interface UserMgmtExtraResults {
    functions: UserMgmtFunctionData[];
    roles: UserMgmtRoleData[];
    cities: UserMgmtCityData[];
    rights: UserMgmtRightData[];
    productSelections: UserMgmtProductSelection[];
}

export interface UserMgmtContactSaveResults {
    success: boolean;
    errorMessage: string;
    contact: UserMgmtContactData;
    validation: UserMgmtValidationResult[];
}

export interface UserMgmtValidationResult {
    field: string;
    message: string;
}