﻿import ActionUrls from "../utils/action-urls";
import Multilang from "../utils/multilang";
import $ from "jquery";
import axios from "axios";

interface AsyncPriceResults {
    success: boolean;
    prices: AsyncPriceResult[];
}

interface AsyncPriceResult {
    id: number
    code: string;
    isGross: boolean
    priceDisplay: string;
}

export default class AsyncPrices {
    static asyncPriceBoxSelector: string = ".js-async-price";
    static asyncAutocompletePriceBoxSelector: string = ".js-autocomplete-products .js-async-price";
    static asyncProductSuggestionsPriceBoxSelector: string = ".js-product-suggestions--suggestions .js-async-price";
    // static cachedResponses = {};

    constructor() { }

    static init() {
        $(document).ready(AsyncPrices.startLoadPrices);
    }

    static startLoadPricesForAutoComplete() {
        AsyncPrices.startLoadPricesReal(AsyncPrices.asyncAutocompletePriceBoxSelector);
    }

    static startLoadPricesForProductSuggestions() {
        AsyncPrices.startLoadPricesReal(AsyncPrices.asyncProductSuggestionsPriceBoxSelector);
    }

    static startLoadPrices() {
        AsyncPrices.startLoadPricesReal(AsyncPrices.asyncPriceBoxSelector);
    }

    static startLoadPricesReal(selector: string) {
        // Get data from DOM
        // Find all product identifiers in DOM data-attributes
        let productIdList: number[] = [];

        $(selector).each(function () {
            const productId = $(this).data("nt-product-id") as number;
            productIdList.push(productId);
        });

        if (productIdList.length === 0) {
            return;
        }

        const url = ActionUrls.getUrl("load-async-prices");

        axios
            .get<AsyncPriceResults>(`${url}?ids=${productIdList.join()}`)
            .then((res) => res.data)
            .then((data: AsyncPriceResults) => {
                AsyncPrices.addPricesToDom(data, selector);
            }).catch((err) => {
                console.error(`startLoadPrices() fail (${err}`);
                AsyncPrices.handleError(selector);
            }).finally(() => {
                // do nothing
            });
    }

    static addPricesToDom(data: AsyncPriceResults, selector: string) {
        if (typeof data.prices == "undefined") {
            AsyncPrices.handleError(selector);
            return;
        }

        for (let price of data.prices) {
            if (price.priceDisplay === null || price.priceDisplay === "") {
                continue;
            }

            const $box = $(`${selector}[data-nt-product-id=${price.id}]`);

            if ($box.length > 0) {
                $box.find(".js-async-net-price--value").html(price.priceDisplay);

                if (price.isGross) {
                    $box.find(".js-async-net-price--label")
                        .html(Multilang.getTranslation("product.label.price-gross.short", "bruto"))
                        .addClass("text-muted");
                }

                // check for gross price visibility (when equal to net price)
                const $netPrice: JQuery<HTMLElement> = $box.find(".js-async-net-price--value");
                const $grossPrice: JQuery<HTMLElement> = $box.find(".js-async-gross-price--value");

                if ($netPrice.length > 0 && $grossPrice.length > 0) {
                    const netPrice: string = ($netPrice.text() as string).replace(" ", "");
                    const grossPrice: string = ($grossPrice.text() as string).replace(" ", "");

                    if (netPrice.length > 0 && grossPrice.length > 0) {

                        const $grossPriceContainer: JQuery<HTMLElement> = $box.find(".js-async-gross-price--container");
                        $grossPriceContainer.toggleClass("d-none", netPrice === grossPrice);

                        const $grossPriceEmptyContainer: JQuery<HTMLElement> = $box.find(".js-async-gross-price--empty-container");
                        $grossPriceEmptyContainer.toggleClass("d-none", netPrice !== grossPrice);
                    }
                }
            }
            else {
                console.error("Box not found");
            }
        }

        AsyncPrices.handleError(selector);
    }

    static handleError(selector: string) {
        $(selector).each(function () {
            var pid = $(this).data("nt-product-id");
            const $box = $(`${selector}[data-nt-product-id=${pid}]`);
            if ($box.length > 0) {
                const $priceElement = $box.find(".js-async-net-price--value");
                const html = $priceElement.html();
                if (html === "...") {
                    $priceElement.html("n/a");
                }
            }
        });
    }
}
