﻿import $ from "jquery";
import axios, { AxiosError } from "axios";
import Multilang from "../utils/multilang";
import ActionUrls from "../utils/action-urls";

type HauratonItem = {
    articleNumber: string,
    amount: number,
    description: string
};

type HauratonProject = {
    cartItems: Array<HauratonItem>;
    description: string;
    projectCode: string;
};

type HauratonEventData = {
    message: string,
    url: string,
    project: HauratonProject,
};

type AddHauratonToBasketResult = {
    productsAdded :number,
    productsNotAdded: number,
    success: boolean,
    message: string
    redirectTo: string;
};

export default class Hauraton {
    static iframeElement: HTMLIFrameElement | null;
    static spinnerElement: HTMLElement | null;
    static languageCode: string = "";

    static init() {
        if ($(".nt-hauraton").length === 0) {
            return;
        }

        console.log("Hauraton.init");
        Hauraton.languageCode = Multilang.getLongLanguageCode();

        ActionUrls.load();

        Hauraton.iframeElement = document.getElementById("toolComponent") as HTMLIFrameElement;
        Hauraton.spinnerElement = document.getElementById("hauraton-submit-spinner") as HTMLElement;

        $("#hauraton-generatepdf-button").on("click", Hauraton.generatePdfClicked);
        $("#hauraton-addtobasket-button").on("click", Hauraton.addToBasketClicked);

        window.onmessage = async (e: MessageEvent<HauratonEventData>) => {
            if (e.data.message === "TRIGGER_PDF_GEN_DC_RESULT") {
                if (e.data.url !== null && e.data.url.length > 0) {

                    Hauraton.handleLeadsPopup().then((data) => {
                        window.open(e.data.url);
                    }).finally(() => {
                        Hauraton.hideSpinner();
                    });
                }
            }
            else if (e.data.message === "TRIGGER_ADD_TO_CART_DC_RESULT") {
                if (e.data.project.cartItems.length === 0) {
                    toastr.error(Multilang.getTranslation("hauraton.message.error.noproducts", "No products to add to basket. Please check your inputs."));
                    Hauraton.hideSpinner();
                }
                else {
                    Hauraton.showSpinner();
                    Hauraton.addToBasket(e.data.project);
                }
            }
        };
    }

    private static handleLeadsPopup(): Promise<string> {
        return new Promise<string>((resolve, reject) => {
            var content = document.getElementById("content");

            content?.addEventListener("acceptLeadsPopup", acceptLeadsPopup);
            content?.addEventListener("cancelLeadsPopup", cancelLeadsPopup);

            content?.dispatchEvent(new CustomEvent<string>("startLeadsPopup", { detail: "hauraton_pdf" }));

            function acceptLeadsPopup(e: Event) {
                content?.removeEventListener("acceptLeadsPopup", acceptLeadsPopup);
                content?.removeEventListener("cancelLeadsPopup", cancelLeadsPopup);
                resolve("");
            }

            function cancelLeadsPopup(e: Event) {
                content?.removeEventListener("acceptLeadsPopup", acceptLeadsPopup);
                content?.removeEventListener("cancelLeadsPopup", cancelLeadsPopup);
                reject("");
            }
        });
    }

    private static addToBasketClicked(e) {
        if (Hauraton.iframeElement === null) {
            console.error("No iframe");
            return;
        }

        if (Hauraton.iframeElement.contentWindow === null) {
            console.error("No iframe contentWindow");
            return;
        }

        Hauraton.showSpinner();
        // console.log("addToBasketClicked !");
        Hauraton.iframeElement.contentWindow.postMessage("TRIGGER_ADD_TO_CART_DC", "*");
    }

    private static generatePdfClicked(e) {
        if (Hauraton.iframeElement === null) {
            console.error("No iframe");
            return;
        }

        if (Hauraton.iframeElement.contentWindow === null) {
            console.error("No iframe contentWindow");
            return;
        }

        Hauraton.showSpinner();
        // console.log("generatePdfClicked !");
        Hauraton.iframeElement.contentWindow.postMessage("TRIGGER_PDF_GEN_DC", "*");
    }

    private static addToBasket(project: HauratonProject) {
        const actionUrl = ActionUrls.getUrl("add-hauraton-configuration-to-basket");

        axios
            .post(actionUrl, project)
            .then((res) => res.data)
            .then((model: AddHauratonToBasketResult) => {
                if (model.success) {
                    if (model.redirectTo !== null && model.redirectTo.length > 0) {
                        document.location.href = model.redirectTo;
                    }
                    if (model.message !== null && model.message.length > 0) {
                        toastr.success(model.message);
                    }

                    if (model.productsAdded != 0) {
                        document.location.href = `/${Hauraton.languageCode}/checkout`;
                    }
                    else {
                        toastr.error(Multilang.getTranslation("hauraton.message.error.noproducts", "No products to add to basket. Please check your inputs."));
                    }
                } else {
                    toastr.error(model.message);
                }
            }).catch((err) => {
                toastr.error(err, Multilang.getTranslation("hauraton.message.error.addtobasket", "Could not add products to basket. Please check your inputs."));
            }).finally(() => {
                
            });
    }

    private static showSpinner() {
        if (Hauraton.spinnerElement === null) {
            return;
        }

        Hauraton.spinnerElement.style.visibility = "visible";
    }

    private static hideSpinner() {
        if (Hauraton.spinnerElement === null) {
            return;
        }

        Hauraton.spinnerElement.style.visibility = "hidden";
    }
}
