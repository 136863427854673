<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="list-selector-modal">
        <div class="modal-dialog modal-sm modal-fullscreen-sm-down" role="document" id="vue-list-selector-app">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body nt-list-selector">
                    <div class="nt-item-list mb-3">
                        <div class="nt-item" v-for="list in lists">
                            <div class="form-check">
                                <input type="checkbox" class="form-check-input" :id="list.name" v-model="list.containsProduct" @click="checkClicked(list)">
                                <label class="form-check-label" :for="list.name">{{ list.name }}</label>
                            </div>
                        </div>
                    </div>
                    <div v-show="!creatingNew">
                        <a href="#" class="btn btn-primary d-flex align-items-center" @click="createNew">
                            <i class="fa fa-circle-plus me-2"></i>
                            {{ createButton }}
                        </a>
                    </div>
                    <div v-show="creatingNew">
                        <div class="input-group">
                            <input type="text" class="form-control" :placeholder="placeholder" v-model="newName" ref="newListInput" maxlength="64" @keyup.enter="saveNew">
                            <button type="submit" class="btn btn-outline-secondary border-neutral-200" @click="saveNew">
                                <i class="fa fa-save"></i>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">{{ okButton }}</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import $ from "jquery";
    import axios from "axios";
    import Multilang from "../scripts/utils/multilang";
    import ActionUrl from "../scripts/utils/action-urls";

    interface ListProductData {
        listId: number;
        name: string;
        containsProduct: boolean;
    }

    interface IdAndQuantity {
        productId: number;
        quantity: number;
    }

    interface ListResponseData {
        customerId: number;
        lists: ListProductData[];
    }

    interface ListSimpleResponseData {
        success: boolean;
    }

    interface ListRequestData {
        productId: number;
    }

    interface ComponentData {
        pid: number;
        q: number;
        qref: any;
        lists: ListProductData[] | null;
        creatingNew: boolean;
        newName: string;
        childSelector: any;
        activator: any;
        many: boolean;
        title: string;
        placeholder: string;
        okButton: string;
        createButton: string;
    }

    export default defineComponent({
        name: "ListSelector",
        data() {
            return {
                pid: 0,
                q: 0,
                qref: null,
                lists: null,
                creatingNew: false,
                newName: "",
                childSelector: null,
                activator: null,
                many: false,
                title: Multilang.getTranslation("lists.modal.title", "Toevoegen aan lijst"),
                placeholder: Multilang.getTranslation("lists.modal.placeholder", "Naam lijst"),
                okButton: Multilang.getTranslation("common.ok", "OK"),
                createButton: Multilang.getTranslation("lists.modal.new", "Nieuwe lijst aanmaken"),
            } as ComponentData;
        },
        props: {
            getUrl: String,
            updateUrl: String,
            saveNewUrl: String,
        },
        methods: {
            onCreateClick() { },
            onSelect() { },
            checkClicked(item: ListProductData) {
                item.containsProduct = !item.containsProduct;
                const productDataList = this.buildProductDataList();

                axios
                    .post<ListSimpleResponseData>(ActionUrl.getUrl("update-list"), {
                        listId: item.listId,
                        isMember: item.containsProduct,
                        products: productDataList
                    })
                    .then((res) => res.data)
                    .then((data: ListSimpleResponseData) => {
                        this.creatingNew = false;
                        this.newName = "";
                        this.loadData();
                        this.toggleActivator();
                    })
                    .catch((err) => console.log(err));

            },
            createNew() {
                this.creatingNew = true;

                let self = this;
                self.$nextTick(function () {
                    const ref: any = self.$refs["newListInput"];
                    ref.focus();
                    ref.select();
                });
            },
            saveNew() {
                const productDataList = this.buildProductDataList();

                axios
                    .post<ListSimpleResponseData>(ActionUrl.getUrl("save-list"), { name: this.newName, products: productDataList })
                    .then((res) => res.data)
                    .then((data: ListSimpleResponseData) => {
                        this.creatingNew = false;
                        this.newName = "";
                        this.loadData();
                    })
                    .catch((err) => console.log(err));
            },
            loadData() {
                let postData: ListRequestData = { productId: this.pid };

                axios
                    .post<ListResponseData>(ActionUrl.getUrl("get-lists"), postData)
                    .then((res) => res.data)
                    .then((data: ListResponseData) => {
                        this.creatingNew = false;
                        this.newName = "";
                        this.lists = data.lists;
                        this.toggleActivator();
                    })
                    .catch((err) => console.log(err));
            },
            getQuantity(q, qref) {
                if (typeof q !== "undefined" && q !== null && q !== "") return parseFloat(q as string);
                if (typeof qref === "undefined" || qref === null || qref === "") return 0;

                const qfield = $("#" + qref);
                if (typeof qfield !== "undefined" && qfield !== null) {
                    const value = qfield.val();

                    if (value === "") return 0;
                    return parseFloat(value as string);
                }

                return 0;
            },
            toggleActivator() {
                const self = this;

                if (self.activator === null) {
                    return;
                }

                const productDataList = this.buildProductDataList();

                for (let productData of productDataList) {
                    const containsProduct = (self.lists as Array<ListProductData>).filter(l => l.containsProduct).length > 0;
                    const $activator = $(`.js-list-selector--activator[data-nt-product-id="${productData.productId}"]`);
                    $activator.find("i").toggleClass("text-success", containsProduct);
                }
            },
            buildProductDataList() {
                let productDataList: IdAndQuantity[] = [];

                const self = this;

                if (self.many) {
                    $("body").find(self.childSelector).each((ix: number, el: HTMLElement) => {
                        let $el = $(el);

                        const productId = $el.data("nt-product-id");
                        const q = $el.data("q");
                        const qref = $el.data("qref");

                        let quantity = self.getQuantity(q, qref);
                        let p: IdAndQuantity = { productId: productId, quantity: quantity };

                        if (!productDataList.find((iq) => iq.productId == productId))
                        {
                            productDataList.push(p);
                        }
                    });
                } else {
                    const p: IdAndQuantity = {
                        productId: self.pid,
                        quantity: self.getQuantity(self.q, self.qref)
                    };

                    productDataList.push(p);
                }

                return productDataList;
            }
        },
        computed: {
            countSquared(): Number {
                return this.pid * this.pid;
            },
        },
        created() {
            document.getElementById("content")?.addEventListener("setListSelectorProductId", ((e: CustomEvent) => {
                this.many = false;
                this.pid = e.detail.pid;
                this.q = e.detail.q;
                this.qref = e.detail.qref;
                this.childSelector = e.detail.childSelector;
                this.activator = e.detail.activator;

                if (typeof this.childSelector !== "undefined" && this.childSelector !== null && this.childSelector !== "") {
                    this.many = true;
                    this.pid = $(this.childSelector).data("nt-product-id");
                }

                this.loadData();
            }) as EventListener);

            document.getElementById("content")?.addEventListener("closeListSelector", (e: Event) => {
                if (this.creatingNew && this.newName !== "") {
                    this.saveNew();
                }
            });
        },
        mounted() {
        },
        setup: (props) => {
            Multilang.load();
            let getListsUrl = props.getUrl as string;
            let updateListUrl = props.updateUrl as string;
            let saveNewListUrl = props.saveNewUrl as string;
            return { getListsUrl, updateListUrl, saveNewListUrl }
        },
    });
</script>

<style lang="scss" scoped>
</style>
