﻿export default class MiniBasketOptions {
    static anonymousCheckoutUrl: string = "";
    static checkoutUrl: string = "";
    static enableAnonymousBaskets: boolean = false;
    static getCurrentQuantitiesUrl: string = "";
    static getMiniUrl: string = "";
    static isAuthenticated: boolean = false;

    static set(
        anonymousCheckoutUrl: string,
        checkoutUrl: string,
        enableAnonymousBaskets: boolean,
        getCurrentQuantitiesUrl: string,
        getMiniUrl: string,
        isAuthenticated: boolean) {
        this.anonymousCheckoutUrl = anonymousCheckoutUrl;
        this.checkoutUrl = checkoutUrl;
        this.enableAnonymousBaskets = enableAnonymousBaskets;
        this.getCurrentQuantitiesUrl = getCurrentQuantitiesUrl;
        this.getMiniUrl = getMiniUrl;
        this.isAuthenticated = isAuthenticated;
    }
}
