import { AxiosError } from "axios";

export default class Toaster {
    static showAxiosMessage(error: AxiosError, defaultMessage: string) {
        const errorMessage = error.response && error.response.data != null ? error.response.data as string : "";
        toastr.error(errorMessage.length > 0 ? errorMessage : defaultMessage);
    }
    
    static showFeedbackMessage(messageType: string, message: string) {
        toastr[messageType.toToastrMessageType()](message);
    }
}