import $ from "jquery";
import $$ from "./scripts/utils/double-dollar";
import { Button, Modal, Popover, Tab, Tooltip } from "bootstrap";
import { default as NatchGtm4, EventNamesSchema } from "natch-gtm4";

import "toastr";
import "jquery-validation";
import "./scripts/http/set-axios-defaults";

import { BootstrapBreakpoint } from "./scripts/models/enums";

import ActionUrls from "./scripts/utils/action-urls";
import Multilang from "./scripts/utils/multilang";
import CustomHelper from "./scripts/utils/custom-helper";
import Validators from "./scripts/utils/validators";

import AnonymousBaskets from "./scripts/components/anonymous-baskets";
import Autocomplete2 from "./scripts/components/autocomplete2";
import AsyncPrices from "./scripts/components/async-prices";
import BasketAttachment from "./scripts/components/basket-attachment";
import CmsConfiguratorLauncher from "./scripts/components/cms-configurator-launcher";
import CustomDatePicker from "./scripts/components/custom-date-picker";
import CustomMenuAim from "./scripts/components/custom-menu-aim";
import CustomSwiper from "./scripts/components/custom-swiper";
import Favorite from "./scripts/components/favorite";
import ListSelector from "./scripts/components/list-selector";
import ProductOrderBox from "./scripts/components/product-order-box";
import ProductVolumeDiscount from "./scripts/components/product-volume-discount";
import PwaHelper from "./scripts/components/pwa-helper";
import QuickOrder from "./scripts/components/quick-order";
import SSO from "./scripts/components/sso";
import StockInWarehouse from "./scripts/components/stock-in-warehouse";
import UpgradeOverpack from "./scripts/components/upgrade-overpack";

import Approvals from "./scripts/pages/approvals";
import BlanketOrder from "./scripts/pages/blanket-order";
import Checkout from "./scripts/pages/checkout";
import Documents from "./scripts/pages/documents";
import Hauraton from "./scripts/pages/hauraton";
import ListsUpload from "./scripts/pages/lists-upload";
import LoginRequest from "./scripts/pages/login-request";
import PriceLists from "./scripts/pages/price-lists"
import PriceListRequest from "./scripts/pages/pricelist-request"
import Products from "./scripts/pages/products";
import ProductSearch from "./scripts/pages/product-search";
import Skylux from "./scripts/pages/skylux";

import VueComponentLoader from "./scripts/vue-component-loader";

class Index {
    static init() {
        toastr.options = {
            closeButton: true,
            positionClass: "toast-bottom-left",
            progressBar: true,
            timeOut: 10000
        }

        var isMobile = Math.min(window.screen.width, window.screen.height) < 768 || navigator.userAgent.indexOf("Mobi") > -1;
        document.cookie = "mobile=" + (isMobile ? "1" : "0") + "; expires=Sun, 1 Jan 2040 03:14:15 UTC; path=/";

        Index.processPageForGA();

        Multilang.load();
        ActionUrls.load();

        // controls
        Index.initBootstrap();
        Index.initCheckboxes()
        Index.initForms();
        Index.initInputFocus();
        Index.initMoveToTop();
        Index.initPasswords();
        Index.initProductOrderBoxes();
        Index.initStickyComponents();

        // pages
        Approvals.init();
        BlanketOrder.init();
        Checkout.init();
        Documents.init();
        Hauraton.init();
        ListsUpload.init();
        LoginRequest.init();
        PriceLists.init();
        PriceListRequest.init();
        Products.init();
        ProductSearch.init();
        Skylux.init();

        // vue components (loader)
        VueComponentLoader.load();

        // custom components
        AnonymousBaskets.init({
            addToAnonymousBasketUrl: ActionUrls.getUrl("add-to-anonymous-basket"),
        });

        AsyncPrices.init();

        Autocomplete2.init({
            serviceUrl: $("[data-nt-provide='autocomplete2']").data("nt-action-get-suggestions"),
            ajaxSettings: { timeout: 2000 },
            minimumInputLength: 2,
            onSelectProduct: function () { },
            onSelectBrand: function () { },
            onSelectCategory: function () { },
            onSelectAll: function () { }
        });

        BasketAttachment.init();
        CmsConfiguratorLauncher.init();
        CustomDatePicker.init();
        CustomMenuAim.initES6();
        CustomSwiper.init();
        Favorite.init();
        ListSelector.init();
        ProductOrderBox.init();
        ProductVolumeDiscount.init();
        PwaHelper.init();
        QuickOrder.init();
        SSO.init();
        StockInWarehouse.init();
        UpgradeOverpack.init();

        // validation
        Validators.init();

        // Code to test CLS (https://web.dev/cls/)
        ////    new PerformanceObserver((entryList) => {
        ////        for (const entry of entryList.getEntries()) {
        ////            console.log('Layout shift:', entry);
        ////        }
        ////    }).observe({ type: 'layout-shift', buffered: true });
    }

    private static processPageForGA() {
        // Remove empty attributes
        $('*[data-datalayer-list-item=""]').removeAttr('data-datalayer-list-item');
        $('*[data-datalayer-cart-item=""]').removeAttr('data-datalayer-cart-item');

        try {
            // These can sometimes fail
            const natchGtm4 = new NatchGtm4(EventNamesSchema.OfficialGA4);
            natchGtm4.processPage();        // Process page for GA4, using official GA4 event names.
        } catch (e) {
            console.error("processPageForGA()");
            console.error(e);
        }
    }

    private static initBootstrap() {
        $$(`[data-bs-toggle="button"]`, el => new Button(el));
        $$(`[data-bs-toggle="tooltip"]`, el => new Tooltip(el));
        $$(`[data-bs-toggle="popover"]`, el => new Popover(el));
        $$(`[data-bs-toggle="popover-dom"]`, el => new Popover(el, { content: CustomHelper.getPopoverContent(el) }));
    }

    private static initCheckboxes() {
        const $body = $("body");

        $body.find(`[data-nt-action="toggle-on-check"]`).each((ix: number, el: HTMLElement) => {
            const $checkbox = $(el);
            this.toggleOnCheck($checkbox);
        });

        $body.on("click", `[data-nt-action="toggle-on-check"]`, e => {
            const $checkbox = $(e.currentTarget);
            this.toggleOnCheck($checkbox);
        });
    }

    private static initForms() {
        const $body = $("body");

        $body.on("change", `[data-nt-action="submit-on-change"]`, e => {
            const $form = $(e.currentTarget).closest("form");
            CustomHelper.submitForm($form);
        });

        $body.on("click", `[data-nt-action="submit-on-click"]`, e => {
            const $form = $(e.currentTarget).closest("form");
            CustomHelper.submitForm($form);
        });
    }

    private static initInputFocus() {
        $(`[data-nt-action="focus-on-load"]`).trigger("focus");
    }

    private static initMoveToTop() {
        window.addEventListener("scroll", () => {
            if (window.scrollY > 300) {
                $(".nt-move-to-top").fadeIn(200);
            } else {
                $(".nt-move-to-top").fadeOut(200);
            }
        });
    }

    private static initStickyComponents(): void {
        let stickyHeaderHeight: number = 0;
        let checkoutContainerHeight: number = 0;
        let checkoutOrderBoxTop: number = 0;
        let checkoutOrderBoxWidth: number = 0;
        let checkoutOrderBoxHeight: number = 0;

        const stickyHeader = document.querySelector("header.sticky-top") as HTMLElement;
        stickyHeaderHeight = CustomHelper.getStickyHeaderHeight(false);

        const checkoutContainer = document.querySelector(".js-checkout-container") as HTMLElement;
        const checkoutOrderBox = document.querySelector('.js-checkout-order-box') as HTMLElement;

        if (checkoutContainer !== null && checkoutOrderBox !== null) {
            checkoutContainerHeight = checkoutContainer.getBoundingClientRect().height;

            // data attributes were added as early as possible (bottom of layout page)
            // slower JS load could result in a negative top value, breaking the intended behaviour
            checkoutOrderBoxTop = checkoutOrderBox.hasAttribute("data-nt-top")
                ? checkoutOrderBox.getAttribute("data-nt-top") as unknown as number
                : checkoutOrderBox.getBoundingClientRect().top;

            checkoutOrderBoxWidth = checkoutOrderBox.getBoundingClientRect().width;
            checkoutOrderBoxHeight = checkoutOrderBox.getBoundingClientRect().height;
        }

        const bootstrapBreakpoint: BootstrapBreakpoint = CustomHelper.getBootstrapBreakpoint();

        window.addEventListener("scroll", () => {
            stickyHeaderHeight = Index.tryActivateStickyHeader(
                bootstrapBreakpoint,
                stickyHeader,
                stickyHeaderHeight);

            Index.tryActivateStickyCheckoutOrderBox(
                bootstrapBreakpoint,
                checkoutContainer,
                checkoutContainerHeight,
                checkoutOrderBox,
                checkoutOrderBoxTop,
                checkoutOrderBoxWidth,
                checkoutOrderBoxHeight,
                stickyHeaderHeight);
        });

        // NOTE: listening to the resize event results in bad performance,
        // mainly because of the usage of the getBootstrapBreakpoint method
        // window.addEventListener("resize", debounce(() => {
        //     Index.initStickyComponents();
        // }, 300));
    }

    private static toggleOnCheck($checkbox: any) {
        const isChecked = $checkbox.is(":checked");

        const enableTargetList = ($checkbox.data("nt-toggle-enable") as string).toArray();
        for (let enableTarget of enableTargetList) {
            $(enableTarget).prop("disabled", !isChecked);
        }

        const hideTargetList = ($checkbox.data("nt-toggle-hide") as string).toArray();
        for (let hideTarget of hideTargetList) {
            $(hideTarget).toggleClass("d-none", isChecked);
            $(hideTarget).toggleClass("d-block", !isChecked);
        }
    }

    private static tryActivateStickyHeader(
        bootstrapBreakpoint: BootstrapBreakpoint,
        stickyHeader: HTMLElement,
        stickyHeaderHeight: number): number {
        // do not activate sticky header functionality when header could not be found
        if (stickyHeaderHeight <= 0) {
            return 0;
        }

        const isDesktop = bootstrapBreakpoint === BootstrapBreakpoint.md
            || bootstrapBreakpoint === BootstrapBreakpoint.lg
            || bootstrapBreakpoint === BootstrapBreakpoint.xl
            || bootstrapBreakpoint === BootstrapBreakpoint.xxl;

        const activateStickyHeader = window.scrollY > stickyHeaderHeight;

        if (activateStickyHeader) {
            $$(`.js-hide-for-sticky-header--item`,
                el => (el as HTMLElement).style.setProperty("display", "none", "important"));

            stickyHeader.classList.add("shadow-navbar");

            const searchBarElement = document.getElementById("search-bar") as HTMLElement;
            const searchBarForm = document.querySelector(".js-product-search--top-form") as HTMLElement;

            if (searchBarElement != null) {
                searchBarElement.style.setProperty("width", "100%", "important");
            }

            if (isDesktop) {
                const gridContainer = $(".js-hide-for-sticky-header--grid-container")
                gridContainer.css("display", "initial");

                if (searchBarForm != null) {
                    searchBarForm.style.setProperty("padding-top", "8px", "important");
                }
            }
        }
        else {
            $$(`.js-hide-for-sticky-header--item`,
                el => (el as HTMLElement).style.removeProperty("display"));

            stickyHeader.classList.remove("shadow-navbar");

            const searchBarElement = document.getElementById("search-bar") as HTMLElement;
            const searchBarForm = document.querySelector(".js-product-search--top-form") as HTMLElement;

            if (searchBarElement != null) {
                searchBarElement.style.removeProperty("width");
            }

            if (isDesktop) {
                const gridContainer = $(".js-hide-for-sticky-header--grid-container")
                gridContainer.css("display", "grid");

                if (searchBarForm != null) {
                    searchBarForm.style.removeProperty("padding-top");
                }
            }
        }

        return stickyHeader.getBoundingClientRect().height;
    }

    private static tryActivateStickyCheckoutOrderBox(
        bootstrapBreakpoint: BootstrapBreakpoint,
        checkoutContainer: HTMLElement,
        checkoutContainerHeight: number,
        checkoutOrderBox: HTMLElement,
        checkoutOrderBoxTop: number,
        checkoutOrderBoxWidth: number,
        checkoutOrderBoxHeight: number,
        stickyHeaderHeight: number): void {
        // do not activate sticky order-box functionality when checkout info height is less than the order box height
        if (checkoutContainerHeight <= checkoutOrderBoxHeight) {
            return;
        }

        // do not activate sticky order-box functionality when viewport is mobile
        const isMobile = bootstrapBreakpoint === BootstrapBreakpoint.xs
            || bootstrapBreakpoint === BootstrapBreakpoint.sm
            || bootstrapBreakpoint === BootstrapBreakpoint.md;

        if (isMobile) {
            return;
        }

        const checkoutContainerBottom = checkoutContainer.getBoundingClientRect().bottom;

        // when the current scroll position passes the order box ...
        const activateStickyCheckoutOrderBox = window.scrollY >= checkoutOrderBoxTop - stickyHeaderHeight;

        if (activateStickyCheckoutOrderBox) {
            // ... set position of order box as fixed to the bottom of the sticky header
            checkoutOrderBox.style.position = "fixed";

            let newTop: number = stickyHeaderHeight; // = the bottom of the sticky header

            // update top position when the current scroll position passes the bottom of the overview
            if (checkoutContainerBottom <= checkoutOrderBoxHeight) {
                newTop = (checkoutOrderBoxHeight - checkoutContainerBottom) * -1;
            }

            checkoutOrderBox.style.top = `${newTop}px`;
        } else {
            // otherwise, set as static (default)
            checkoutOrderBox.style.position = "static";
            checkoutOrderBox.style.top = "";
        }

        // set width of order box as position "fixed" does not stay within the page container
        checkoutOrderBox.style.width = `${checkoutOrderBoxWidth}px`;
    }

    private static initProductOrderBoxes() {
        $(".js-product-order-box").each((ix: number, el: HTMLElement) => {
            const listContainer = el.closest("[data-datalayer-list-name]");
            if (listContainer != null) {
                const listName = listContainer.getAttribute("data-datalayer-list-name");
                const listId = listContainer.getAttribute("data-datalayer-list-id");
                $(el).find(".js-product-order-box--attribution-listid").val(listId ?? "");
                $(el).find(".js-product-order-box--attribution-listname").val(listName ?? "");
            }
        });
    }

    private static initPasswords() {
        $(".js-password-reveal").on("mousedown touchstart", (e) => {
            const $pw = $($(e.currentTarget).data("nt-for"));
            $pw.attr("type", "text");
        });

        $(".js-password-reveal").on("mouseup mouseleave touchend touchmove", (e) => {
            const $pw = $($(e.currentTarget).data("nt-for"));
            $pw.attr("type", "password");
        });
    }
}

(function () {
    Index.init();
})();
