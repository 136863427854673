﻿import $ from "jquery";
import Multilang from "../utils/multilang";
import ActionUrls from "../utils/action-urls";
import { Collapse } from "bootstrap";
import NProgress from "nprogress";

export default class ProductSearch {
    private static refreshDelay: any = 500;
    private static currentTimeout: any = null;
    private static $form: any = null;
    private static $uiForm: any = null;
    private static clientData: ClientDataModel;

    public static init() {
        this.$form = $(".js-product-search--left-filter-form");
        this.$uiForm = $(".js-product-search--top-form");
        
        if (this.$form.length === 0 && this.$uiForm.length === 0) {
            return;
        }

        this.clientData = new ClientDataModel();

        //// console.log("Search ***** ", ActionUrls.getUrl("product-search"));

        this.initSearchUI();

        if (this.$form.length === 0) {
            return;
        }

        this.initSortOrderSelection();
        this.initViewModeSelection();
        this.initPager();
        this.initFilters();
        this.initPreviousClientData();
    }

    private static initSortOrderSelection() {
        $(".js-product-search--sort-order").on("change", (e) => {
            const newSortOrder = $(e.currentTarget).val() as string;
            document.cookie = "psso2" + "=" + newSortOrder + "; expires=Sun, 1 Jan 2040 03:14:15 UTC; path=/";
            ProductSearch.doRefresh();
        });
    }

    private static initViewModeSelection() {
        $(".js-product-search--view-mode").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            
            const newViewMode = $(e.currentTarget).data("nt-view-mode") as string;
            document.cookie = "rvm" + "=" + newViewMode + "; expires=Sun, 1 Jan 2040 03:14:15 UTC; path=/";
            ProductSearch.doRefresh();
        });
    }

    private static initPager() {
        $(".nt-product-search .js-pager-navigate").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();

            const pageIndex = $(e.currentTarget).data("nt-page-index");
            ProductSearch.changePage(pageIndex);
        });
    }

    private static initFilters() {
        $(".js-filter--more").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            
            const $block = $(e.currentTarget).closest(".js-filter");
            $block.find(".js-filter--more-container").toggleClass("d-none", true);
            $block.find(".js-filter--more-container").toggleClass("d-block", false);
            $block.find(".js-filter--less-container").toggleClass("d-none", false);
            $block.find(".js-filter--less-container").toggleClass("d-block", true);
            $block.find(".js-filter--extra").toggleClass("d-none", false);
            $block.find(".js-filter--extra").toggleClass("d-block", true);

            ProductSearch.sortFilterValues($block, 2);
            ProductSearch.delayRefresh();
        });

        $(".js-filter--less").on("click", (e) => {
            e.preventDefault();
            e.stopPropagation();
            
            const $block = $(e.currentTarget).closest(".js-filter");
            $block.find(".js-filter--more-container").toggleClass("d-none", false);
            $block.find(".js-filter--more-container").toggleClass("d-block", true);
            $block.find(".js-filter--less-container").toggleClass("d-none", true);
            $block.find(".js-filter--less-container").toggleClass("d-block", false);
            $block.find(".js-filter--extra").toggleClass("d-none", true);
            $block.find(".js-filter--extra").toggleClass("d-block", false);

            ProductSearch.sortFilterValues($block, 1);
            ProductSearch.delayRefresh();
        });

        $(".js-filter--brand").on("click", this.brandFilterClicked);
        $(".js-filter--category").on("click", this.categoryFilterClicked);

        $(".js-filter--all-prop-remove").on("click", this.clearPropertyFilters);
        $(".js-filter--brand-remove").on("click", this.removableBrandFilterClicked);
        $(".js-filter--cat-remove").on("click", this.removableCatFilterClicked);
        $(".js-filter--product-model-remove").on("click", this.removableProductModelFilterClicked);
        $(".js-filter--prop-remove").on("click", this.removablePropFilterClicked);
        $(".js-filter--word-remove").on("click", this.removableWordFilterClicked);

        $(".js-filter--title").on("click", this.delayRefresh);
        $(".js-filter--value-input input").on("click", this.propertyFilterClicked);
        $(".js-filter--value-line").on("mouseenter", this.delayRefresh);
    }

    private static initSearchUI() {
        $(".js-product-search--filter").on("change", (e) => {
            const $button = $(e.currentTarget);
            const filterType = $button.data("nt-filter-type");

            switch (filterType) {
                case "favorites":
                    ProductSearch.clientData.FilterFavorites = !ProductSearch.clientData.FilterFavorites;
                    break;
                case "promotions":
                    ProductSearch.clientData.FilterPromotions = !ProductSearch.clientData.FilterPromotions;
                    if (ProductSearch.clientData.FilterPromotions) {
                        ProductSearch.clientData.FilterLiquidations = false;
                    }

                    break;
                case "liquidations":
                    ProductSearch.clientData.FilterLiquidations = !ProductSearch.clientData.FilterLiquidations;
                    if (ProductSearch.clientData.FilterLiquidations) {
                        ProductSearch.clientData.FilterPromotions = false;
                    }

                    break;
                case "ordered":
                    ProductSearch.clientData.FilterOrdered = !ProductSearch.clientData.FilterOrdered;
                    break;
                case "in-stock":
                    ProductSearch.clientData.FilterInStock = !ProductSearch.clientData.FilterInStock;
                    break;
            }

            const arg = $(".js-product-search--query").val();

            if (ProductSearch.areEqualOrEmpty(ProductSearch.clientData.SearchTerm, String(arg)) === false) {
                // if using new search word, reset the filters
                ProductSearch.clientData.CategoryIds = [];
                ProductSearch.clientData.PropertyValueIds = [];
            }

            ProductSearch.clientData.SearchTerm = String(arg);
            ProductSearch.initiateRefresh();
        });

        ProductSearch.$form.bind("submit", ProductSearch.handleSearchSubmit);
        $(".js-product-search--submit").on("click", ProductSearch.handleSearchSubmit);
    }

    private static handleSearchSubmit(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const arg = $(".js-product-search--query").val();
        if (ProductSearch.clientData.SearchTerm !== String(arg)) {
            // if using new search word, reset the filters
            ProductSearch.clientData.CategoryIds = [];
            ProductSearch.clientData.PropertyValueIds = [];
            ProductSearch.clientData.BrandId = 0;
            ProductSearch.clientData.ProductModelId = 0;
        }

        ProductSearch.clientData.SearchTerm = String(arg);
        ProductSearch.initiateRefresh();
    }

    private static sortFilterValues($block: JQuery<HTMLElement>, order: number) {
        var $wrapper = $block.find(".js-filter--values");
        var $elements = $wrapper.find(".js-filter--value-line");
        var sorted = $elements.toArray().sort(function (a, b) {
            const val1 = a.getAttribute(order === 1 ? "data-sortorder1" : "data-sortorder2") ?? "";
            const val2 = b.getAttribute(order === 1 ? "data-sortorder1" : "data-sortorder2") ?? "";
            return val1 > val2 ? 1 : -1;
        });
        $wrapper.append(sorted);
    }

    private static areEqualOrEmpty(value1, value2) {
        if (value1 === null) value1 = "";
        if (value2 === null) value2 = "";
        return value1 === value2;
    }

    private static removableWordFilterClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        $(".js-product-search--query").val("");
        ProductSearch.clientData.SearchTerm = null;
        ProductSearch.initiateRefresh();
    }

    private static removableBrandFilterClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        ProductSearch.clientData.BrandId = 0;
        ProductSearch.initiateRefresh();
    }

    private static removableProductModelFilterClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        ProductSearch.clientData.ProductModelId = 0;
        ProductSearch.initiateRefresh();
    }

    private static removablePropFilterClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const entityIds = String($(this).data("nt-entities"));
        for (let entityId of entityIds.split(",")) {
            ProductSearch.clientData.RemovedPropertyValueId = +entityId;
            ProductSearch.removeFilterValueId(+entityId);
        }

        ProductSearch.initiateRefresh();
    }

    private static removableCatFilterClicked(e) {
        e.preventDefault();
        e.stopPropagation();
        
        const entityIds = String($(this).data("nt-entities"));
        for (let entityId of entityIds.split(",")) {
            ProductSearch.removeCategoryId(+entityId);
        }

        ProductSearch.initiateRefresh();
    }

    private static clearPropertyFilters(e) {
        e.preventDefault();
        e.stopPropagation();
        
        ProductSearch.resetFilterUI();
        ProductSearch.clientData.PropertyValueIds = [];
        ////ProductSearch.clientData.CategoryIds = [];
        ////ProductSearch.clientData.BrandId = 0;
        ////ProductSearch.clientData.ProductModelId = 0;
        ProductSearch.initiateRefresh();
    }

    private static resetFilterUI() {
        $(".js-filter--value-input input").prop("checked", false);
    }

    private static propertyFilterClicked(e) {
        const valueId = $(this).data("nt-value-id");
        const checked = $(this).is(":checked");

        if (checked) {
            ProductSearch.addFilterValueId(valueId);
        } else {
            ProductSearch.removeFilterValueId(valueId);
        }

        ProductSearch.initiateRefresh();
    }

    private static categoryFilterClicked(e) {
        const categoryId = $(this).data("nt-category-id") as string;
        ProductSearch.addCategoryId(categoryId);
        ProductSearch.initiateRefresh();
    }

    private static brandFilterClicked(e) {
        ProductSearch.clientData.BrandId = $(this).data("nt-category-id") as number;
        ProductSearch.initiateRefresh();
    }

    public static changePage(pageIndex) {
        ProductSearch.clientData.PageIndex = pageIndex;
        ProductSearch.initiateRefresh();
    }

    public static changeViewMode(mode) {
        // TODO: set cookie
        ProductSearch.initiateRefresh();
    }

    public static changeSortOrder(order) {
        // TODO: set cookie
        ProductSearch.initiateRefresh();
    }

    private static initiateRefresh() {
        if (this.currentTimeout !== null) {
            clearTimeout(this.currentTimeout);
            this.currentTimeout = null;
        }

        ProductSearch.currentTimeout = setTimeout(this.doRefresh, this.refreshDelay);
    }

    private static delayRefresh() {
        if (ProductSearch.currentTimeout !== null) {
            ProductSearch.initiateRefresh(); // will restart the delay timer
        }
    }

    private static doRefresh() {
        ProductSearch.currentTimeout = null;
        const qs = ProductSearch.buildQueryString();

        if (qs === "t=") {
            NProgress.start();
            document.location.href = "/" + Multilang.getLongLanguageCode();
            return;
        }

        let url = ActionUrls.getUrl("product-search");
        url = url + (url.indexOf("?") !== -1 ? "&" : "?") + qs;

        NProgress.start();
        document.location.href = url;
    }

    private static buildQueryString() {
        let qs = "";
        const propertyValueOrderValue = $(".js-value-order-string").val() as string ?? "";

        if (this.clientData.SearchTerm !== null && this.clientData.SearchTerm != "") {
            qs += "q=" + encodeURIComponent(this.clientData.SearchTerm) + "&";
        }

        if (this.clientData.CategoryIds.length > 0) {
            qs += "cc=" + this.joinIds(this.clientData.CategoryIds) + "&";
        }

        if (this.clientData.FilterFavorites) {
            qs += "ff=1&";
        }

        if (this.clientData.FilterOrdered) {
            qs += "fo=1&";
        }

        if (this.clientData.FilterPromotions) {
            qs += "fp=1&";
        }

        if (this.clientData.FilterLiquidations) {
            qs += "fl=1&";
        }

        if (this.clientData.FilterInStock) {
            qs += "fs=1&";
        }

        if (this.clientData.PropertyValueIds.length > 0) {
            qs += "pv=" + this.joinIds(this.clientData.PropertyValueIds) + "&";
        }

        const lpvcsq = this.collectFromPreviousData();
        if (lpvcsq !== "") {
            qs += "lpvc=" + lpvcsq + "&";
        }

        if (this.clientData.BrandId !== 0) {
            qs += "b=" + String(this.clientData.BrandId) + "&";
        }

        if (this.clientData.ProductModelId !== 0) {
            qs += "m=" + String(this.clientData.ProductModelId) + "&";
        }

        if (this.clientData.PageIndex !== 0) {
            qs += "p=" + String(this.clientData.PageIndex) + "&";
        }

        if (this.clientData.RemovedPropertyValueId !== 0) {
            qs += "rp=" + String(this.clientData.RemovedPropertyValueId) + "&";
        }

        if (qs.length + propertyValueOrderValue.length < 1024) {
            qs += "pvov=" + propertyValueOrderValue + "&";
        }

        qs += "t=" + new Date().getTime();

        return qs;
    }

    private static joinIds(array) {
        return array.join(",");
    }

    private static pushIfNotExists(array: PropertyValueCountCombinations[], value: PropertyValueCountCombinations) {
        for (let pvcc of array) {
            if (pvcc.c === value.c && pvcc.p === value.p && pvcc.v === value.v) {
                return;
            }
        }

        array.push(value);
    }

    private static collectFromPreviousData() {
        // take last propertyValueId
        let lastPropertyValueId = this.clientData.PropertyValueIds[this.clientData.PropertyValueIds.length - 1];
        
        let lastPropertyId = 0;
        for (let lpvc of this.clientData.PreviousPropertyValues) {
            if (lpvc.v == lastPropertyValueId) {
                lastPropertyId = lpvc.p;
                break;
            }
        }

        let newData: PropertyValueCountCombinations[] = [];

        // find all value-count pairs from lastPropertyId
        if (lastPropertyId !== 0) {
            for (let lpvc of this.clientData.PreviousPropertyValues.filter(function(item) {
                return item.p == lastPropertyId;
            })) {
                ProductSearch.pushIfNotExists(newData, lpvc);
            }
        }

        // find all value-count pairs from selectedproperties
        for (let lpvc of this.clientData.PreviousPropertyValues) {
            if (this.clientData.PropertyValueIds.indexOf(lpvc.v) >= 0) ProductSearch.pushIfNotExists(newData, lpvc);
        }

        let lpvcsq = "";
        for (let lpvc of newData) {
            if (lpvcsq !== "") lpvcsq += ",";
            lpvcsq += lpvc.p + ":" + lpvc.v + ":" + lpvc.c;
            if (lpvcsq.length > 1500) break;
         }

        return lpvcsq;
    }

    private static initPreviousClientData() {
        const json = ProductSearch.$form.find("#clientdatasearchdata").val();
        ProductSearch.clientData = JSON.parse(json);

        ProductSearch.clientData.RemovedPropertyValueId = 0;
        let selector;

        // set the top-filter checkboxes
        selector = ".js-product-search--filter[data-nt-filter-type=favorites]";
        ProductSearch.setFilterButtonState(selector, ProductSearch.clientData.FilterFavorites);

        selector = ".js-product-search--filter[data-nt-filter-type=promotions]";
        ProductSearch.setFilterButtonState(selector, ProductSearch.clientData.FilterPromotions);

        selector = ".js-product-search--filter[data-nt-filter-type=liquidations]";
        ProductSearch.setFilterButtonState(selector, ProductSearch.clientData.FilterLiquidations);

        selector = ".js-product-search--filter[data-nt-filter-type=ordered]";
        ProductSearch.setFilterButtonState(selector, ProductSearch.clientData.FilterOrdered);

        selector = ".js-product-search--filter[data-nt-filter-type=in-stock]";
        ProductSearch.setFilterButtonState(selector, ProductSearch.clientData.FilterInStock);

        const hideInStockFilter = ProductSearch.clientData.ProductCount === 0 && ProductSearch.clientData.FilterInStock === false;
        
        const $inStockFilterDesktop = $(selector).closest(".form-check");
        $inStockFilterDesktop.toggleClass("d-none", hideInStockFilter);
        $inStockFilterDesktop.toggleClass("d-inline-block", hideInStockFilter === false);
        
        const $inStockFilterMobile = $(selector).closest(".nav-item");
        $inStockFilterMobile.toggleClass("d-none", hideInStockFilter);
        $inStockFilterMobile.toggleClass("d-block", hideInStockFilter === false);

        if (ProductSearch.clientData.SearchTerm !== null && ProductSearch.clientData.SearchTerm !== "") {
            $(".js-product-search--query").val(ProductSearch.clientData.SearchTerm);
        }
    }

    private static setFilterButtonState(selector: string, flag: boolean) {
        $(selector).prop("checked", flag);
    }

    private static addFilterValueId(id) {
        const ix = ProductSearch.clientData.PropertyValueIds.indexOf(id);
        if (ix === -1) {
            ProductSearch.clientData.PropertyValueIds.push(id);
        }
    }

    private static removeFilterValueId(id) {
        const ix = ProductSearch.clientData.PropertyValueIds.indexOf(id);
        if (ix === -1) {
            return;
        }

        ProductSearch.clientData.RemovedPropertyValueId = id;
        ProductSearch.clientData.PropertyValueIds.splice(ix, 1);
    }

    private static addCategoryId(id) {
        const ix = ProductSearch.clientData.CategoryIds.indexOf(id);
        if (ix === -1) {
            ProductSearch.clientData.CategoryIds.push(id);
        }
    }

    private static removeCategoryId(id) {
        const ix = ProductSearch.clientData.CategoryIds.indexOf(id);
        if (ix === -1) {
            return;
        }

        ProductSearch.clientData.CategoryIds.splice(ix, 1);
    }
}

class PropertyValueCountCombinations {
    p: number = 0;
    v: number = 0;
    c: number = 0;
}

class ClientDataModel {
    PreviousPropertyValues: PropertyValueCountCombinations[] = [];
    PropertyValueIds: number[] = [];
    CategoryIds: number[] = [];
    SearchTerm: string | null = null;
    FilterFavorites: boolean = false;
    FilterPromotions: boolean = false;
    FilterLiquidations: boolean = false;
    FilterInStock: boolean = false;
    FilterOrdered: boolean = false;
    BrandId: number = 0;
    ProductModelId: number = 0;
    RemovedPropertyValueId: number = 0;
    ProductCount: number = 0;
    PageIndex: number = 0;
}
