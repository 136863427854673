﻿var Global = Global || {};

export default class LoginRequest {
    static savedPlaceHolder: any = null;

    static init() {
        LoginRequest.initIsEmptyVat();
        LoginRequest.handleVat();
    }
    
    private static initIsEmptyVat() {
        $("body").on("click", ".js-login-request--is-empty-vat", LoginRequest.handleVat);
    }

    private static handleVat() {
        const $vat = $(".js-login-request--vat");
        const $isEmptyVat = $(".js-login-request--is-empty-vat");

        $vat.prop("disabled", $isEmptyVat.is(":checked"));

        if ($isEmptyVat.is(":checked")) {
            LoginRequest.savedPlaceHolder = $vat.prop("placeholder") as string;
            
            $vat.prop("placeholder", "");
            $vat.val("");
        } else {
            if (LoginRequest.savedPlaceHolder !== null) {
                $vat.prop("placeholder", LoginRequest.savedPlaceHolder);
            }
        }
    }
}
