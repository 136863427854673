﻿export default class PriceListRequest {
    private static $categoriesSelectElement: JQuery<HTMLSelectElement>;
    private static $cataloguesSelectElement: JQuery<HTMLSelectElement>;
    private static $brandsSelectElement: JQuery<HTMLSelectElement>;
    private static $listsSelectElement: JQuery<HTMLSelectElement>;
    private static $pricetypeSelectElement: JQuery<HTMLSelectElement>;
    private static $stocktypeSelectElement: JQuery<HTMLSelectElement>;
    private static $submitButtonElement: JQuery<HTMLButtonElement>;
    private static $allCategoriesElement: JQuery<HTMLInputElement>;
    private static $categorysElements: JQuery<HTMLInputElement>;

    static init() {
        this.initLoad();
        this.initOnChangeSelectionKind();
        this.initAllCategoriesChange();
    }

    private static initLoad()
    {
        this.$categoriesSelectElement = $<HTMLSelectElement>(".js-categories-select");
        this.$cataloguesSelectElement = $<HTMLSelectElement>(".js-catalogues-select");
        this.$brandsSelectElement = $<HTMLSelectElement>(".js-brands-select");
        this.$listsSelectElement = $<HTMLSelectElement>(".js-lists-select");
        this.$pricetypeSelectElement = $<HTMLSelectElement>(".js-pricetype-select");
        this.$stocktypeSelectElement = $<HTMLSelectElement>(".js-stocktype-select");
        this.$submitButtonElement = $<HTMLButtonElement>(".js-pricelist-submit-button");
        this.$allCategoriesElement = $<HTMLInputElement>(".js-categories-all");
        this.$categorysElements = $<HTMLInputElement>(".js-categories-select-category");
               

        if (this.$listsSelectElement.length === 0) {
            const $listRadio = $<HTMLInputElement>(".js-lists-radio");
            $listRadio.prop("disabled", true);
        }
    }

    private static initAllCategoriesChange() {
        this.$allCategoriesElement.on("change", (event) => this.allCategoriesChanged(event));
        this.$categorysElements.on("change", (event) => this.categoryChanged(event));

    }

    private static categoryChanged(event: JQuery.ChangeEvent) {
        var checkBoxes = $(".js-categories-select-category:not(checked)");
        if (checkBoxes.length > 0) {
            this.$allCategoriesElement.prop("checked", false);
        }

        this.onChangeSelectionKind(null);
    }

    private static allCategoriesChanged(event: JQuery.ChangeEvent | null) {
        this.$categoriesSelectElement.prop("checked", this.$allCategoriesElement.prop("checked"));
        this.onChangeSelectionKind(null);
    }

    private static initOnChangeSelectionKind() {
        $(".js-form-pricelistrequest input").on("change", (event) => this.onChangeSelectionKind(event));
        this.onChangeSelectionKind(null);
    }

    private static onChangeSelectionKind(event: JQuery.ChangeEvent | null) {
        const value: number = $("input[name='SelectionKind']:checked").val() as number;
        const selectedKind = SelectionKind[value];

        type Window = { storeFrontCode: string };
        var currentWindow = window as unknown as Window;

        this.$categoriesSelectElement.prop("disabled", selectedKind !== SelectionKind[SelectionKind.Category]);
        this.$brandsSelectElement.prop("disabled", selectedKind !== SelectionKind[SelectionKind.Brand]);
        this.$cataloguesSelectElement.prop("disabled", selectedKind !== SelectionKind[SelectionKind.Catalogue]);
        this.$listsSelectElement.prop("disabled", selectedKind !== SelectionKind[SelectionKind.List]);

        // below is some store specific dirty code
        if (currentWindow.storeFrontCode == "schac") {
            if (selectedKind == SelectionKind[SelectionKind.Brand] || selectedKind == SelectionKind[SelectionKind.Category]) {
                PriceListRequest.$stocktypeSelectElement.val("true");
            }
        }
        else if (currentWindow.storeFrontCode == "outil" || currentWindow.storeFrontCode == "outfr") {
            if (selectedKind == SelectionKind[SelectionKind.Category]) {
                PriceListRequest.$stocktypeSelectElement.val("true");
                PriceListRequest.$stocktypeSelectElement.prop("disabled", true);
            }
            else {
                PriceListRequest.$stocktypeSelectElement.prop("disabled", false);
            }
        }
        else if (currentWindow.storeFrontCode == "techn") {
            if (selectedKind == SelectionKind[SelectionKind.Brand] || selectedKind == SelectionKind[SelectionKind.Category]) {
                PriceListRequest.$stocktypeSelectElement.val("true");
            }
        }

        if (selectedKind == SelectionKind[SelectionKind.Category]) {
            // is at least one category selected ?
            var checkBoxes = $(".js-categories-select:checked");
            this.$submitButtonElement.prop("disabled", checkBoxes.length == 0);
        }
        else {
            this.$submitButtonElement.prop("disabled", false);
        }
    }
}

enum SelectionKind {
    NotSet = 0,
    Category = 1,
    Brand = 2,
    Catalogue = 3,
    Favorites = 4,
    List = 5,
    AlreadyBought = 6,
}
