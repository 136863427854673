import $ from "jquery";
import {BootstrapBreakpoint} from "../models/enums";
import Multilang from "./multilang";

export default class CustomHelper {
    static confirm(msg: string, func: () => void): void {
        bootbox.confirm({
            title: "",
            swapButtonOrder: true,
            message: msg,
            onEscape: true,
            buttons: {
                cancel: {
                    label: Multilang.getTranslation("common.no"),
                    className: ""
                },
                confirm: {
                    label: Multilang.getTranslation("common.yes"),
                    className: ""
                }
            },
            callback: function (result) {
                if (result) {
                    func();
                }
            }
        });
    }

    static confirmWithCustomButtonLabels(msg: string, cancelButtonText: string, confirmButtonText: string, func: () => void): void {
        bootbox.confirm({
            title: "",
            swapButtonOrder: true,
            message: msg,
            onEscape: true,
            buttons: {
                cancel: {
                    label: cancelButtonText,
                    className: ""
                },
                confirm: {
                    label: confirmButtonText,
                    className: ""
                }
            },
            callback: function (result) {
                if (result) {
                    func();
                }
            }
        });
    }

    /**
     * Gets the current Bootstrap v5 breakpoint.
     * The class list, per breakpoint, contains the classes for which the element will be hidden.
     */
    static getBootstrapBreakpoint(): BootstrapBreakpoint {
        const breakpointList = [
            {name: BootstrapBreakpoint.xs, classList: ["d-none", "d-sm-block"]},
            {name: BootstrapBreakpoint.sm, classList: ["d-sm-none", "d-md-block"]},
            {name: BootstrapBreakpoint.md, classList: ["d-md-none", "d-lg-block"]},
            {name: BootstrapBreakpoint.lg, classList: ["d-lg-none", "d-xl-block"]},
            {name: BootstrapBreakpoint.xl, classList: ["d-xl-none", "d-xxl-block"]},
            {name: BootstrapBreakpoint.xxl, classList: ["d-xxl-none"]}
        ];

        for (let breakpoint of breakpointList) {
            const divElement = window.document.createElement("div");
            divElement.classList.add(...breakpoint.classList);

            window.document.body.appendChild(divElement);

            if (divElement.offsetParent === null) {
                window.document.body.removeChild(divElement);

                console.log("Bootstrap Breakpoint", breakpoint.name.toString());
                return breakpoint.name;
            }

            window.document.body.removeChild(divElement);
        }

        return BootstrapBreakpoint.none;
    }

    static getDateFromString(value: any): Date {
        if (typeof value === "undefined") {
            return new Date();
        }

        const parts = value.split(/\-|T/);
        return new Date(parts[0], parts[1] - 1, parts[2]);
    }

    static getPopoverContent(el: Element): string {
        const element = document.getElementById(el.getAttribute("data-bs-content-id") as string);
        if (element === null) {
            return "Missing or wrong data-bs-content-id."
        }

        return element.innerHTML;
    }

    static getStickyHeaderHeight(current: boolean): number {
        const stickyHeader = document.querySelector("header.sticky-top") as HTMLElement;

        if (stickyHeader === null) {
            return 0;
        }

        let height: string;

        if (current) {
            height = stickyHeader.getBoundingClientRect().height.toString();
        } else {
            height = stickyHeader.hasAttribute("data-nt-height")
                ? stickyHeader.getAttribute("data-nt-height") as string
                : stickyHeader.getBoundingClientRect().height.toString();
        }

        // explicit parsing as it's seen as a string when checking its value via DevTools
        return parseFloat(height);
    }

    static smoothScrollTo(anchor: string, offset: number) {
        if (anchor === "#top") {
            $("html,body").animate({scrollTop: 0}, 300);

            return true;
        }

        let $target = $(anchor);
        $target = ($target.length && $target) || $("[name=" + anchor.slice(1) + "]");

        if ($target.length > 0) {
            const targetOffset: number = $target.offset()?.top ?? 0;
            $("html,body").animate({scrollTop: targetOffset - offset}, 300);

            return true;
        }

        return false;
    }

    static startLoad() {
        $("body").css("cursor", "progress");
    }

    static stopLoad() {
        $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
    }

    static submitForm($form: JQuery<any>) {
        if ($form.length === 0) {
            return;
        }

        // $form[0] gets native DOM element
        if ($form[0].requestSubmit) {
            $form[0].requestSubmit();
        } else {
            $form[0].submit();
        }
    }
}