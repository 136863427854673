﻿import $ from "jquery";
import axios, { AxiosError } from "axios";
import Multilang from "../utils/multilang";
import { default as NatchGtm4, EventNamesSchema } from "natch-gtm4";
import Toaster from "./toaster";

export default class Favorite {
    static init() {
        $("body").on("click", `[data-nt-action="favorite-on-click"]`, e => {
            e.preventDefault();
            e.stopPropagation();

            const $favoriteLink = $(e.currentTarget);
            const $favoriteIcon = $(e.currentTarget).find("i");

            const postData = {
                productId: $favoriteLink.data("nt-product-id") as number,
                isFavorite: $favoriteIcon.hasClass("far")
            };

            axios
                .post($favoriteLink.prop("href"), postData)
                .then((res) => res.data)
                .then((data: any) => {
                    console.log("Product #" + postData.productId + " is updated to favorite " + postData.isFavorite);
                    $favoriteIcon.toggleClass("far fas");

                    Favorite.tryTrackAddToWishlist($favoriteLink);
                }).catch((err: AxiosError) => {
                    const defaultMessage = Multilang.getTranslation("product.message.error.update-favorite", "Could not update favorite.");
                    Toaster.showAxiosMessage(err, defaultMessage);
                }).finally(() => {
                    // do nothing
                });
        });
    }

    private static tryTrackAddToWishlist($favoriteLink) {
        // Track in GA4
        let productDto = null;
        let $productContainer = $favoriteLink.closest(".js-product-item");
        if ($productContainer.length == 0) {
            $productContainer = $favoriteLink.closest(".js-product-detail");
        }

        if ($productContainer.length == 0) {
            console.warn("No product container found");
            return;
        }

        productDto = $productContainer.data("datalayer-list-item");

        if (productDto == null) {
            productDto = $productContainer.data("datalayer-detail");
        }

        if (productDto != null) {
            const natchGtm4 = new NatchGtm4(EventNamesSchema.OfficialGA4);
            natchGtm4.trackAddToFavorites(productDto);
        }
    }
}