import $ from "jquery";
import axios, {AxiosError} from "axios";
import {Modal} from "bootstrap";
import NProgress from "nprogress";
import ActionUrls from "../utils/action-urls";
import {ContactLeadData} from "../models/contact-lead-data";
import {
    AddResult,
    RemoveResult,
    UpdateResult,
    SendToResult,
    CurrentQuantities,
    Message,
    AnonymousBasketFormData
} from "../models/anonymous-basket-data";
import MiniBasketData from "../models/mini-basket-data";
import CustomHelper from "../utils/custom-helper";
import Toaster from "./toaster";
import DatalayerHelper from "../utils/datalayer-helper";

type Options = {
    addToAnonymousBasketUrl: string;
};

export default class AnonymousBaskets {
    static modalInstance: Modal;
    static options: Options;
    static addButtonSelector: string = ".js-anonymous-basket-add";

    static quantityInputSelector: string = ".js-quantity-check";
    static basketCheckoutContainer: string = ".js-anonymous-basket-checkout-form";
    static basketInfoContainer: string = ".js-anonymous-basket-info-form";
    static mailBasketMailToButton: string = ".js-anonymous-basket-mailto";
    static mailBasketDownloadButton: string = ".js-anonymous-basket-download";
    static mailBasketInitiateButton: string = ".js-anonymous-basket-mailto-initiate";
    static mailBasketModalId: string = "#anonymous-basket-mail-modal";
    static mailBasketAddressInputId = "#anonymous-basket-mail-input";

    static changeQuantityTimerId: number | null = null;
    static ignoreUpdates: boolean = false;

    constructor() {
    }

    static defaults: Options = {
        addToAnonymousBasketUrl: "",
    };

    static init(options: object) {
        if ($(AnonymousBaskets.mailBasketModalId).length > 0) {
            AnonymousBaskets.modalInstance = new Modal(AnonymousBaskets.mailBasketModalId, {keyboard: false});
        }

        AnonymousBaskets.options = $.extend({}, AnonymousBaskets.defaults, options);

        // Product Pages
        $("body").on("click", AnonymousBaskets.addButtonSelector, AnonymousBaskets.addButtonHandler);
        //// setTimeout(AnonymousBaskets.getCurrentQuantities, 50);

        //// Checkout Page
        $(AnonymousBaskets.basketCheckoutContainer + " .js-anonymous-basket--delete-line").on("click", AnonymousBaskets.deleteButtonHandler);
        $(AnonymousBaskets.basketCheckoutContainer + " .js-anonymous-basket--input-quantity").on("keyup change", AnonymousBaskets.changeQuantityHandler);

        // MailTo
        $(AnonymousBaskets.mailBasketMailToButton).on("click", AnonymousBaskets.startMailToFlow);
        $(AnonymousBaskets.mailBasketDownloadButton).on("click", AnonymousBaskets.startDownload);
        $(AnonymousBaskets.mailBasketInitiateButton).on("click", AnonymousBaskets.startMailTo);
        $(AnonymousBaskets.mailBasketAddressInputId).on("keyup", AnonymousBaskets.handleEmailChanged);
        $(AnonymousBaskets.basketInfoContainer).on("submit", AnonymousBaskets.handleInfoFormSubmit);

        AnonymousBaskets.tryAutoFillForm();
    }

    static addButtonHandler(e: JQueryEventObject) {
        console.log("addButtonHandler");
        const $self = $(e.currentTarget);
        const $parent = $($self.data("nt-parent"));
        const productId = +$parent.data("nt-product-id");
        e.preventDefault();
        e.stopPropagation();
        AnonymousBaskets.addToBasket(productId);
    }

    static deleteButtonHandler(e: JQueryEventObject) {
        const $self = $(e.currentTarget);
        const $parent = $self.closest(".js-anonymous-basket-line");
        const productId = +$parent.data("nt-product-id");
        e.preventDefault();
        e.stopPropagation();
        AnonymousBaskets.removeFromList(productId);
    }

    static changeQuantityHandler(e: JQueryEventObject) {
        if (AnonymousBaskets.ignoreUpdates) {
            return;
        }
        const $self = $(e.currentTarget);
        const $parent = $self.closest(".js-anonymous-basket-line");
        const originalQuantity = +$self.data("nt-original-quantity");
        const productId = +$parent.data("nt-product-id");
        let quantity = parseInt('' + $self.val());
        if (isNaN(quantity) || quantity === 0) {
            quantity = 1;
            $self.val(quantity);
        }

        if (quantity === originalQuantity) {
            return;
        }

        if (AnonymousBaskets.changeQuantityTimerId !== null) {
            clearTimeout(AnonymousBaskets.changeQuantityTimerId);
        }

        AnonymousBaskets.changeQuantityTimerId = setTimeout(function () {
            AnonymousBaskets.changeQuantityTimerId = null;
            AnonymousBaskets.updateInList(productId, quantity);
        }, 500);
    }

    static addToBasket(productId: number) {
        const postData = {
            productId: productId
        };

        axios
            .post<AddResult>(AnonymousBaskets.options.addToAnonymousBasketUrl, postData)
            .then((res) => res.data)
            .then((data: AddResult) => {
                console.log(data);
                AnonymousBaskets.handleAddToBasketResult(data);
            }).catch((err: AxiosError) => {
            Toaster.showAxiosMessage(err, "");
        }).finally(() => {
            // do nothing
        });
    }

    static removeFromList(productId: number) {
        const postData = {
            productId: productId
        };

        axios
            .post<RemoveResult>(ActionUrls.getUrl("remove-item"), postData)
            .then((res) => res.data)
            .then((data: RemoveResult) => {
                console.log(data);
                AnonymousBaskets.handleRemoveFromListResult(data);
            }).catch((err: AxiosError) => {
            Toaster.showAxiosMessage(err, "");
        }).finally(() => {
            // do nothing
        });
    }

    static updateInList(productId: number, quantity: number) {
        const postData = {
            productId: productId,
            quantity: quantity
        };

        axios
            .post<UpdateResult>(ActionUrls.getUrl("update-item"), postData)
            .then((res) => res.data)
            .then((data: UpdateResult) => {
                console.log(data);
                AnonymousBaskets.handleUpdateInListResult(data);
            }).catch((err: AxiosError) => {
            Toaster.showAxiosMessage(err, "");
        }).finally(() => {
            // do nothing
        });
    }

    static handleAddToBasketResult(data: AddResult) {
        AnonymousBaskets.showToasts(data.messages);
        MiniBasketData.setLineCount(data.lineCount);
    }

    static handleRemoveFromListResult(data: RemoveResult) {
        if (data.lineCount === 0) {
            document.location.reload();
            return;
        }

        $(AnonymousBaskets.basketCheckoutContainer + " .js-anonymous-basket-line").each(function () {
            const $self = $(this);
            const productId = $self.data("nt-product-id") as number;

            if (productId === data.productId) {
                $self.addClass("hiding");
                setTimeout(() => {
                    $self.hide()
                }, 1000)
            }
        });

        MiniBasketData.setLineCount(data.lineCount);
        AnonymousBaskets.showToasts(data.messages);
    }

    static handleUpdateInListResult(data: UpdateResult) {
        AnonymousBaskets.ignoreUpdates = true;

        $(AnonymousBaskets.basketCheckoutContainer + " .js-basketline").each(function () {
            const $self = $(this);
            const productId = $self.data("nt-product-id") as number;

            if (productId === data.productId) {
                const $input = $self.find(".js-anonymous-basket--input-quantity")
                $input.val(data.quantity);
            }
        });

        AnonymousBaskets.ignoreUpdates = false;
        AnonymousBaskets.showToasts(data.messages);
    }

    static showToasts(messages: Message[]) {
        for (let message of messages) {
            if (message.level == 0) {
                toastr.success(message.content);
            }
            if (message.level == 1) {
                toastr.warning(message.content);
            }
            if (message.level == 2) {
                toastr.error(message.content);
            }
        }
    }

    static openMailToModal() {
        $(AnonymousBaskets.mailBasketAddressInputId).val("");
        $(AnonymousBaskets.mailBasketInitiateButton).prop("disabled", true);
        AnonymousBaskets.modalInstance.show();
    }

    static closeMailToModal() {
        AnonymousBaskets.modalInstance.hide();
    }

    static startDownload() {
        const url = ActionUrls.getUrl("generate-excel");

        DatalayerHelper.trackEvent("bm_anonymousbasket_export_excel", null, () => {
            window.open(url);
        });
    }

    static startMailToFlow(e: JQueryEventObject) {
        e.preventDefault();
        e.stopPropagation();
        AnonymousBaskets.openMailToModal();
    }

    static startMailTo(e: JQueryEventObject) {
        const email = $(AnonymousBaskets.mailBasketAddressInputId).val() as string;

        if (AnonymousBaskets.validateEmail(email) === false) {
            return;
        }

        $(AnonymousBaskets.mailBasketInitiateButton).prop("disabled", true);
        NProgress.start();

        const postData = {
            email: email,
        };

        axios
            .post<SendToResult>(ActionUrls.getUrl("send-to"), postData)
            .then((res) => res.data)
            .then((data: SendToResult) => {
                AnonymousBaskets.handleSendToResult(data);
            }).catch((err: AxiosError) => {
            Toaster.showAxiosMessage(err, "");
        }).finally(() => {
            NProgress.done();
            $(AnonymousBaskets.mailBasketInitiateButton).prop("disabled", false);
        });

    }

    static handleSendToResult(data: SendToResult) {
        AnonymousBaskets.showToasts(data.messages);
        AnonymousBaskets.closeMailToModal();
    }

    static handleEmailChanged(e: JQueryEventObject) {
        const email = $(AnonymousBaskets.mailBasketAddressInputId).val() as string;

        if (AnonymousBaskets.validateEmail(email)) {
            $(AnonymousBaskets.mailBasketInitiateButton).prop("disabled", false);
        } else {
            $(AnonymousBaskets.mailBasketInitiateButton).prop("disabled", true);
        }
    }

    static handleInfoFormSubmit(e: JQueryEventObject) {
        const formData = AnonymousBaskets.readFormFields();
        localStorage.setItem("AnonymousBasketFormData", JSON.stringify(formData));
    }

    static validateEmail(email: string): boolean {
        return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email);
    }

    static tryAutoFillForm() {
        if ($(AnonymousBaskets.basketInfoContainer).length === 0) {
            return;
        }

        const formDataJson = localStorage.getItem("AnonymousBasketFormData");

        if (formDataJson === null) {
            AnonymousBaskets.tryAutoFillFormFromLeadsForm();
            return;
        }

        const formData = JSON.parse(formDataJson) as AnonymousBasketFormData;

        if (formData === null) {
            AnonymousBaskets.tryAutoFillFormFromLeadsForm();
            return;
        }

        AnonymousBaskets.populateFormFields(formData);
    }

    static tryAutoFillFormFromLeadsForm() {
        const leadDataJson = localStorage.getItem("ContactLeadData");
        if (leadDataJson === null) {
            return;
        }

        const leadData = JSON.parse(leadDataJson) as ContactLeadData;

        if (leadData) {
            $("#FirstName").val(leadData.firstName);
            $("#LastName").val(leadData.lastName);
            $("#CompanyName").val(leadData.companyName);
            $("#PostalCode").val(leadData.zip);
            $("#EmailAddress").val(leadData.emailAddress);
            $("#SectorId").val(leadData.sectorId);
        }
    }

    static populateFormFields(formData: AnonymousBasketFormData) {
        $("#FirstName").val(formData.firstName);
        $("#LastName").val(formData.lastName);
        $("#EmailAddress").val(formData.emailAddress);
        $("#Phone").val(formData.phone);

        $("#OrganizationLevelId").val(formData.organizationLevel);
        ////$("#Comment").val(formData.comment);

        $("#CompanyName").val(formData.companyName);
        $("#SectorId").val(formData.sector);
        $("#VatNumber").val(formData.vatNumber);

        $("#AddressLine").val(formData.address);
        $("#PostalCode").val(formData.zip);
        $("#City").val(formData.city);
    }

    static readFormFields(): AnonymousBasketFormData {
        const formData = new AnonymousBasketFormData();

        formData.firstName = $("#FirstName").val() as string;
        formData.lastName = $("#LastName").val() as string;
        formData.emailAddress = $("#EmailAddress").val() as string;
        formData.phone = $("#Phone").val() as string;

        formData.organizationLevel = $("#OrganizationLevelId").val() as string;
        ////formData.comment = $("#Comment").val() as string;

        formData.companyName = $("#CompanyName").val() as string;
        formData.sector = $("#SectorId").val() as string;
        formData.vatNumber = $("#VatNumber").val() as string;

        formData.address = $("#AddressLine").val() as string as string;
        formData.zip = $("#PostalCode").val() as string;
        formData.city = $("#City").val() as string;

        return formData;
    }
}