﻿import $ from "jquery";
import axios from "axios";
import NProgress from "nprogress";

// make clear to server that axios requests are AJAX requests
axios.defaults.headers.common["X-Requested-With"] = "XMLHttpRequest";

const urlsExemptFromOverlay = ["/api/pricing/fetch"];

// add a request interceptor
axios.interceptors.request.use(
    function (config) {
        // console.log("request interceptor:", config);
        if (NProgress) NProgress.start();
        if (!urlsExemptFromOverlay.includes(config.url ?? "")) {
            $("body").css("cursor", "progress");
        }
        return config;
    },
    function (error) {
        if (NProgress) NProgress.done();
        $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
        return Promise.reject(error);
    }
);

// add a response interceptor
axios.interceptors.response.use(
    function (response) {
        const config = response.config;
        // any status code that lies within the range of 2xx causes this function to trigger
        if (NProgress) NProgress.done();
        if (!urlsExemptFromOverlay.includes(config.url ?? "")) {
            $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
        }
        return response;
    },
    function (error) {
        // any status codes that falls outside the range of 2xx causes this function to trigger
        if (NProgress) NProgress.done();
        $("body").css("cursor", "default"); // cursor doesn't revert until mouse is moved - in chrome
        return Promise.reject(error);
    }
);
