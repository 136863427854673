<template>
  <div id="user-mgmt-rights-modal" class="modal" tabindex="-1">
    <div class="modal-dialog modal-fullscreen-sm-down">
      <div class="modal-content">
        <form v-if="contact !== null" method="post" @submit.prevent="saveRights(contact!)">
          <div class="modal-header border-0 pb-0">
            <button aria-label="Close" class="btn-close" type="button" @click="hideModal"></button>
          </div>
          <div class="modal-body">
            <h1>{{ titleStandardRights }}</h1>
            <template v-for="right in standardRightsList">
              <div class="form-check">
                <input :id="right.name" v-model="right.isActive" :disabled="right.disabled" class="form-check-input"
                       type="checkbox"/>
                <label :for="right.name" class="form-check-label">
                  <i v-show="right.isActive && !right.isStandard" class="fa fa-plus text-success me-1"></i>
                  <i v-show="!right.isActive && right.isStandard" class="fa fa-minus text-danger me-1"></i>
                  <i v-show="right.isInternal && !right.isExternal" class="fa fa-flashtext-secondary-500 me-1"></i>
                  {{ right.name }}
                </label>
              </div>
            </template>

            <h1>{{ titleExtraRights }}</h1>
            <template v-for="right in extraRightsList">
              <div class="form-check">
                <input :id="right.name" v-model="right.isActive" :disabled="right.disabled" class="form-check-input"
                       type="checkbox"/>
                <label :for="right.name" class="form-check-label">
                  <i v-show="right.isActive && !right.isStandard" class="fa fa-plus text-success me-1"></i>
                  <i v-show="!right.isActive && right.isStandard" class="fa fa-minus text-danger me-1"></i>
                  <i v-show="right.isInternal && !right.isExternal" class="fa fa-flash text-secondary-500 me-1"></i>
                  {{ right.name }}
                </label>
              </div>
            </template>
          </div>
          <div class="modal-footer">
            <div v-show="errorMessage.length > 0" class="alert alert-warning w-100">
              {{ errorMessage }}
            </div>

            <button :disabled="isBusy" class="btn btn-secondary ms-2" type="button" @click="hideModal">
              {{ actionCancel }}
            </button>

            <button :disabled="isBusy" class="btn btn-primary ms-2" type="submit">
              {{ actionSave }}
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import {computed, defineComponent, PropType, ref} from "vue";
import axios, {AxiosError, AxiosResponse} from "axios";
import {Modal} from "bootstrap";
import ActionUrls from "../scripts/utils/action-urls";
import Multilang from "../scripts/utils/multilang";
import Toaster from "../scripts/components/toaster";
import {UserMgmtContactData, UserMgmtExtraResults, UserMgmtRightData} from "../scripts/models/user-mgmt";

let userMgmtRightsModalInstance: Modal;

type EmitContextType = {
  savedRights: () => void;
};

export default defineComponent({
  name: "UserMgmtEditor",
  data() {
    return {
      titleStandardRights: Multilang.getTranslation("user-mgmt.rights.title.standard", "Standaard rechten"),
      titleExtraRights: Multilang.getTranslation("user-mgmt.rights.title.extra", "Extra rechten"),

      actionCancel: Multilang.getTranslation("user-mgmt.rights.action.cancel", "Annuleren"),
      actionSave: Multilang.getTranslation("user-mgmt.rights.action.save", "Bewaren"),
    };
  },
  props: {
    siteCode: String,
    allowEditPersons: Boolean,
    allowEditRights: Boolean,
    contact: Object as PropType<UserMgmtContactData | null>,
    customerId: String,
    emitContext: Object as PropType<EmitContextType>,
  },
  setup: (props) => {
    let isBusy = ref(false);

    ActionUrls.load();
    Multilang.load();

    const encryptedCustomerId: string = props.customerId ?? "";
    const errorMessage = ref("");

    // RIGHTS
    const rightsList = ref([] as UserMgmtRightData[]);

    const standardRightsList = computed(() => {
      return rightsList.value.filter((item) => {
        return item.isStandard && ((item.isExternal && !props.contact!.isInternal) || (item.isInternal && props.contact!.isInternal)) && item.description !== null;
      });
    });

    const extraRightsList = computed(() => {
      return rightsList.value.filter((item) => {
        return !item.isStandard && ((item.isExternal && !props.contact!.isInternal) || (item.isInternal && props.contact!.isInternal)) && item.description !== null;
      });
    });

    const saveRights = (contact: UserMgmtContactData) => {
      contact.effectiveRights = [];

      for (let right of rightsList.value) {
        if (right.isActive) {
          contact.effectiveRights.push(right.id);
        }
      }

      props.emitContext!.savedRights();

      const editorModal = new Modal(document.getElementById("user-mgmt-rights-modal") as HTMLElement);
      editorModal.hide();
    };

    // COMMON
    const loadExtraData = (encryptedCustomerId: string) => {
      isBusy.value = true;

      axios
          .get(`${ActionUrls.getUrl("load-extra-data")}?ecid=${encryptedCustomerId}`)
          .then((res: AxiosResponse) => res.data)
          .then((data: UserMgmtExtraResults) => {
            for (let right of data.rights) {
              rightsList.value.push(right);
            }
          })
          .catch((err: AxiosError) => {
            const defaultMessage = Multilang.getTranslation(
                "user-mgmt.message.error.load-extra-data",
                "Could not load extra data."
            );
            Toaster.showAxiosMessage(err, defaultMessage);
          })
          .finally(() => {
            isBusy.value = false;
          });
    };

    // INIT
    const init = () => {
      loadExtraData(encryptedCustomerId);
    };

    init();

    return {
      errorMessage,
      extraRightsList,
      isBusy,
      rightsList,
      saveRights,
      standardRightsList,
    };
  },
  methods: {
    showModal(contact: UserMgmtContactData) {
      userMgmtRightsModalInstance ??= new Modal(document.getElementById("user-mgmt-rights-modal") as HTMLElement);
      userMgmtRightsModalInstance.show();

      this.errorMessage = "";

      for (let right of this.rightsList) {
        right.isActive = false;
        right.isStandard = false;

        if (contact.effectiveRights.filter(r => r === right.id).length > 0) {
          right.isActive = true;
        }

        if (contact.standardRights.filter(r => r === right.id).length > 0) {
          right.isStandard = true;
        }
      }

      this.rightsList.sort(function (_a, _b) {
        const aa = (_a.isStandard ? "0" : "1") + _a.name;
        const bb = (_b.isStandard ? "0" : "1") + _b.name;

        if (aa < bb) {
          return -1;
        }

        if (aa > bb) {
          return 1;
        }

        return 0;
      });
    },
    hideModal() {
      userMgmtRightsModalInstance ??= new Modal(document.getElementById("user-mgmt-rights-modal") as HTMLElement);
      userMgmtRightsModalInstance.hide();
    }
  }
});
</script>
