﻿import $ from "jquery";
import axios, { AxiosError } from "axios";
import ActionUrls from "../utils/action-urls";
import Multilang from "../utils/multilang";
import MiniBasketData from "../models/mini-basket-data";
import { default as NatchGtm4, EventNamesSchema, NatchOsDataLayerProduct } from "natch-gtm4";
import { AddToBasketResult, UpdateInBasketResult, DeleteFromBasketResult, CountInBasketResult } from "../models/basket";
import Checkout from "../pages/checkout";
import Toaster from "./toaster";
import UpgradeOverpack from "./upgrade-overpack";
import CmsConfiguratorLauncher from "./cms-configurator-launcher";
import ProductSuggestions from "./product-suggestions";
import ProductVolumeDiscount from "./product-volume-discount";
import { Question, Choice } from "../models/configurator-question";
import { Toast } from "bootstrap";

export default class ProductOrderBox {
    private static addToBasketAction: string;
    private static updateInBasketAction: string;
    private static deleteFromBasketAction: string;
    private static countInBasketAction: string;
    private static maxQuantity: number = 999999.99;

    static init() {
        ActionUrls.load();
        ProductOrderBox.addToBasketAction = ActionUrls.getUrl("add-to-basket");
        ProductOrderBox.updateInBasketAction = ActionUrls.getUrl("update-in-basket");
        ProductOrderBox.deleteFromBasketAction = ActionUrls.getUrl("delete-from-basket");
        ProductOrderBox.countInBasketAction = ActionUrls.getUrl("count-in-basket");

        ProductOrderBox.initAddToBasket();
        ProductOrderBox.initChangeOrderQuantity();
        ProductOrderBox.initChangeOverpack();
        ProductOrderBox.initDeleteFromBasket();
        ProductOrderBox.initEditSkyluxConfiguration();
    }

    private static initAddToBasket() {
        $("body").on("click", ".js-product-order-box--submit", e => {
            e.preventDefault();
            e.stopPropagation();

            const $submit = $(e.currentTarget);
            const $form = $submit.closest(".js-product-order-box");
            const $totalOrderQuantity = $form.find(".js-product-order-box--total-order-quantity");

            let val = $totalOrderQuantity.val() as string;
            val = val.replace(/,/g, ".");

            if (val !== "") {
                let quantity = parseFloat(val);
                if (quantity > ProductOrderBox.maxQuantity) {
                    $totalOrderQuantity.val(ProductOrderBox.maxQuantity);
                }
            }

            if (CmsConfiguratorLauncher.needsConfiguration($form)) {
                // configuration takes presedence over upgradeoverpack

                // check if product is already in basket
                const productId = $form.data("nt-product-id");
                axios
                    .get(ProductOrderBox.countInBasketAction + `?productId=${productId}`)
                    .then((res) => res.data)
                    .then((data: CountInBasketResult) => {
                        if (data.quantity > 0) {
                            Toaster.showFeedbackMessage("Error", Multilang.getTranslation("product-order-box.message.error.only-one-configurable"));
                            return;
                        }

                        let val = $totalOrderQuantity.val() as string;
                        val = val.replace(/,/g, ".");
                        let quantity = parseFloat(val);

                        CmsConfiguratorLauncher.askConfiguration($form, quantity)
                            .then(questions => {
                                ProductOrderBox.addToBasketWithConfiguration($form, questions);
                            })
                            .catch(e => {
                                // console.log("Cms Configuration failed or cancelled", e);
                            });

                    }).catch((err: AxiosError) => {
                        const defaultMessage = Multilang.getTranslation("product-order-box.message.error.add-to-basket", "Could not add to basket.");
                        Toaster.showAxiosMessage(err, defaultMessage);
                    }).finally(() => {
                        // do nothing
                    });

                return;
            }

            UpgradeOverpack.tryOverpack($form, $totalOrderQuantity)
                .then(number => {
                    const decimalSeparator = window["Global"].DecimalSeparator;
                    $totalOrderQuantity.val(number.toString().replace(".", decimalSeparator));
                    ProductOrderBox.addToBasket($form);
                })
                .catch(e => {
                    // console.log("Overpack Upgrade Rejected", e);
                    ProductOrderBox.addToBasket($form);
                });
        })
    }

    private static initChangeOrderQuantity() {
        $("body").on("change", ".js-product-order-box--order-quantity", e => {
            e.preventDefault();
            e.stopPropagation();

            ////console.log(".js-product-order-box--order-quantity");

            const $orderQuantity = $(e.currentTarget);
            const $form = $orderQuantity.closest(".js-product-order-box");
            const $overpack = $form.find(".js-product-order-box--overpack");
            const $totalOrderQuantity = $form.find(".js-product-order-box--total-order-quantity");
            const $totalUnits = $form.find(".js-product-order-box--total-units");

            // order quantity
            const decimalSeparator = window["Global"].DecimalSeparator;
            const orderQuantity = $orderQuantity.val()?.toString().replace(decimalSeparator, ".") as unknown as number;
            const unitsPerPack = ($overpack.length > 0 ? $overpack.find("option:selected").data("nt-upp") : 1) as number;
            const totalOrderQuantity = orderQuantity * unitsPerPack;

            if (totalOrderQuantity <= 0) {
                const originalQuantity = $orderQuantity.data("nt-original-quantity");
                $orderQuantity.val(originalQuantity);

                return;
            }

            // hidden field (used for POST data)
            let fixedOrderQuantity: string = totalOrderQuantity === null || typeof totalOrderQuantity === "undefined"
                ? "1"
                : totalOrderQuantity.toString().replace(".", decimalSeparator);
            $totalOrderQuantity.val(fixedOrderQuantity);

            // informative text ("= x units")
            $totalUnits.text(totalOrderQuantity);

            // volume discount
            ProductVolumeDiscount.selectOption(totalOrderQuantity, $(".js-product-detail-order-info"));
            ProductVolumeDiscount.selectOption(totalOrderQuantity, $orderQuantity.closest(".js-product-item"));

            const orderAction = $form.data("nt-action");
            if (orderAction === "update-in-basket") {
                ProductOrderBox.updateInBasket($form);
            }
        });
    }

    private static initChangeOverpack() {
        $("body").on("staffelchange", ".js-product-order-box--overpack", e => {
            e.preventDefault();
            e.stopPropagation();

            ////console.log("Staffelchange");

            const $overpack = $(e.currentTarget);
            const $form = $overpack.closest(".js-product-order-box");
            const $orderQuantity = $form.find(".js-product-order-box--order-quantity");
            const $totalOrderQuantity = $form.find(".js-product-order-box--total-order-quantity")
            const $totalUnits = $form.find(".js-product-order-box--total-units");
            const $baseUnit = $form.find(".js-product-order-box--base-unit");

            // selected overpack
            const $selectedOverpack = $overpack.find("option:selected");
            const selectedMinimumOrderQuantity = $selectedOverpack.data("nt-min") as number;
            const selectedIncrementalOrderQuantity = $selectedOverpack.data("nt-step") as number;
            const selectedUnitsPerPack = $selectedOverpack.data("nt-upp") as number;
            const selectedBaseUnit = $selectedOverpack.data("nt-bu") as string;

            // order quantity
            const totalOrderQuantity = $totalOrderQuantity.val() as number;
            ////console.log("totalOrderQuantity ", totalOrderQuantity);
            const orderQuantity = ProductOrderBox.validateOrderQuantity(totalOrderQuantity, selectedUnitsPerPack, selectedMinimumOrderQuantity);

            ////console.log("selectedMinimumOrderQuantity ", selectedMinimumOrderQuantity);
            ////console.log("selectedIncrementalOrderQuantity", selectedIncrementalOrderQuantity);
            ////console.log("selectedUnitsPerPack", selectedUnitsPerPack);
            ////console.log("selectedBaseUnit", selectedBaseUnit);
            ////console.log("totalOrderQuantity", totalOrderQuantity);
            ////console.log("orderQuantity", orderQuantity);

            const decimalSeparator = window["Global"].DecimalSeparator;

            // hidden field (used for POST data)
            const newTotalQuantityNumber = Math.round((orderQuantity * selectedUnitsPerPack + Number.EPSILON) * 10000) / 10000;
            let newTotalQuantity = (newTotalQuantityNumber).toString().replace(".", decimalSeparator);

            // $totalOrderQuantity.val(newTotalQuantity);

            ////console.log("totalOrderQuantity", totalOrderQuantity);
            ////console.log("newTotalQuantity", newTotalQuantity);

            // informative text ("= x units")
            // $totalUnits.text(orderQuantity * selectedUnitsPerPack);
            $totalUnits.text(totalOrderQuantity);
            $baseUnit.text(selectedBaseUnit);

            ////console.log("$totalUnits", orderQuantity * selectedUnitsPerPack);
        });

        $("body").on("change", ".js-product-order-box--overpack", e => {
            e.preventDefault();
            e.stopPropagation();

            ////console.log("Change Overpack");

            const $overpack = $(e.currentTarget);
            const $form = $overpack.closest(".js-product-order-box");
            const $orderQuantity = $form.find(".js-product-order-box--order-quantity");
            const $totalOrderQuantity = $form.find(".js-product-order-box--total-order-quantity")
            const $totalUnits = $form.find(".js-product-order-box--total-units");
            const $baseUnit = $form.find(".js-product-order-box--base-unit");

            // selected overpack
            const $selectedOverpack = $overpack.find("option:selected");
            const selectedMinimumOrderQuantity = $selectedOverpack.data("nt-min") as number;
            const selectedIncrementalOrderQuantity = $selectedOverpack.data("nt-step") as number;
            const selectedUnitsPerPack = $selectedOverpack.data("nt-upp") as number;
            const selectedBaseUnit = $selectedOverpack.data("nt-bu") as string;

            // order quantity
            const totalOrderQuantity = $totalOrderQuantity.val() as number;
            ////console.log("totalOrderQuantity ", totalOrderQuantity);
            const orderQuantity = ProductOrderBox.validateOrderQuantity(totalOrderQuantity, selectedUnitsPerPack, selectedMinimumOrderQuantity);

            ////console.log("selectedMinimumOrderQuantity ", selectedMinimumOrderQuantity);
            ////console.log("selectedIncrementalOrderQuantity", selectedIncrementalOrderQuantity);
            ////console.log("selectedUnitsPerPack", selectedUnitsPerPack);
            ////console.log("selectedBaseUnit", selectedBaseUnit);
            ////console.log("totalOrderQuantity", totalOrderQuantity);
            ////console.log("orderQuantity", orderQuantity);

            // input field
            $orderQuantity.val(orderQuantity);
            $orderQuantity.prop("min", selectedMinimumOrderQuantity);
            $orderQuantity.prop("step", selectedIncrementalOrderQuantity);

            const decimalSeparator = window["Global"].DecimalSeparator;

            // hidden field (used for POST data)
            const newTotalQuantityNumber = Math.round((orderQuantity * selectedUnitsPerPack + Number.EPSILON) * 10000) / 10000;
            let newTotalQuantity = (newTotalQuantityNumber).toString().replace(".", decimalSeparator);

            $totalOrderQuantity.val(newTotalQuantity);

            ////console.log("newTotalQuantity", newTotalQuantity);

            // informative text ("= x units")
            $totalUnits.text(newTotalQuantity);
            $baseUnit.text(selectedBaseUnit);

            ////console.log("$totalUnits", orderQuantity * selectedUnitsPerPack);

            // volume discount
            ProductVolumeDiscount.selectOption(totalOrderQuantity, $(".js-product-detail-order-info"));
            ProductVolumeDiscount.selectOption(totalOrderQuantity, $orderQuantity.closest(".js-product-item"));

            const orderAction = $form.data("nt-action");
            if (orderAction === "update-in-basket") {
                ProductOrderBox.updateInBasket($form);
            }
        });
    }

    private static initEditSkyluxConfiguration() {
        $("body").on("click", `[data-nt-action="edit-skylux-configuration"]`, e => {
            e.preventDefault();
            e.stopPropagation();

            const $editLink = $(e.currentTarget);
            const $form = $editLink.closest(".js-product-order-box");
            const configurationId = $form.data("nt-skylux-configuration-id");

            const url = ActionUrls.getUrl("edit-skylux") + "?skyluxConfigurationId=" + configurationId;
            document.location.href = url;
        });
    }

    private static initDeleteFromBasket() {
        $("body").on("click", `[data-nt-action="delete-from-basket"]`, e => {
            e.preventDefault();
            e.stopPropagation();

            const $deleteLink = $(e.currentTarget);

            bootbox.confirm({
                message: Multilang.getTranslation("product-order-box.label.delete-from-basket", "Wenst u het product te verwijderen?"),
                buttons: {
                    confirm: {
                        label: Multilang.getTranslation("product-order-box.action.confirm-delete-from-basket", "Verwijderen"),
                        className: 'btn-primary'
                    },
                    cancel: {
                        label: Multilang.getTranslation("product-order-box.action.cancel-delete-from-basket", "Annuleren"),
                        className: 'btn-secondary'
                    }
                },
                callback: (result: boolean) => {
                    if (!result) {
                        return;
                    }

                    const $form = $deleteLink.closest(".js-product-order-box");
                    ProductOrderBox.deleteFromBasket($form);
                }
            });
        })
    }

    private static addToBasketByProductAndQuantity(productId: number, quantity: number) {
        const data = {
            productId: productId,
            orderQuantity: quantity,
            totalOrderQuantity: quantity,
            selectedOverpackId: 0,
            basketLineId: 0
        };

        const toUrlEncoded = obj => Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');

        axios
            .post(ProductOrderBox.addToBasketAction, toUrlEncoded(data))
            .then((res) => res.data)
            .then((data: AddToBasketResult) => {
                Toaster.showFeedbackMessage(data.messageType, data.message);
                MiniBasketData.setLineCount(data.lineCount);
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("product-order-box.message.error.add-to-basket", "Could not add to basket.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }

    public static addToBasketWithConfiguration($form, configuration: Question[]) {
        let postData = $form.serialize();

        let configData = "";
        for (let question of configuration) {
            configData += `&Configuration.Questions[${question.id}].Question=${encodeURIComponent(question.question)}`;
            configData += `&Configuration.Questions[${question.id}].Answer=${encodeURIComponent(question.choices[question.selectedChoiceId].choice)}`;
        }

        postData = postData + configData;
        
        axios
            .post(ProductOrderBox.addToBasketAction, postData)
            .then((res) => res.data)
            .then((data: AddToBasketResult) => {
                ProductOrderBox.handleAddToBasketResult(data, $form);
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("product-order-box.message.error.add-to-basket", "Could not add to basket.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }

    private static handleAddToBasketResult(data: AddToBasketResult, $form) {
        MiniBasketData.setLineCount(data.lineCount);

        ProductOrderBox.tryTrackAddToBasket($form, data.quantity, data.totalLinePrice, data.unitPrice);

        // When on checkout page, do a refresh
        if ($(".js-checkout-step-indicator").length > 0) {
            // timeout for GA/GTM
            Toaster.showFeedbackMessage(data.messageType, data.message);
            setTimeout(() => { location.reload(); }, 1000);
            return;
        }

        // show a popup when there are suggestions for the added product
        // otherwise, show the toaster
        const suggestionList = $form.data("nt-suggestions-json") as Array<number>;
        const suggestionRatioList = $form.data("nt-suggestionratios-json") as Array<number>;

        if (suggestionList.length > 0) {
            const productId = $form.data("nt-product-id");

            const $productCard = $form.closest(`#product-card-${productId}`);
            const $promoNetPrice = $productCard.find(".js-promo-net-price--value");
            const $asyncNetPrice = $productCard.find(".js-async-net-price--value");

            let productSetPrice = $promoNetPrice.length > 0
                ? $promoNetPrice.text() : $asyncNetPrice.length > 0
                    ? $asyncNetPrice.text() : "";

            ProductSuggestions.trySuggest(productId, data.quantity, productSetPrice, suggestionList, suggestionRatioList);
        }
        else {
            Toaster.showFeedbackMessage(data.messageType, data.message);
        }
    }

    private static addToBasket($form) {
        const postData = $form.serialize();

        axios
            .post(ProductOrderBox.addToBasketAction, postData)
            .then((res) => res.data)
            .then((data: AddToBasketResult) => {
                ProductOrderBox.handleAddToBasketResult(data, $form);
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("product-order-box.message.error.add-to-basket", "Could not add to basket.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }

    private static updateInBasket($form) {
        axios
            .post(ProductOrderBox.updateInBasketAction, $form.serialize())
            .then((res) => res.data)
            .then((data: UpdateInBasketResult) => {
                Checkout.updateBasketTotals(data.needRefresh);
                Checkout.updateBasketLineInfo(data.basketLineId, data.newLineTotalGross, data.newLineTotalNet, data.quantity);
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("product-order-box.message.error.update-in-basket", "Could not update in basket.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }

    private static deleteFromBasket($form) {
        axios
            .post(ProductOrderBox.deleteFromBasketAction, $form.serialize())
            .then((res) => res.data)
            .then((data: DeleteFromBasketResult) => {
                ProductOrderBox.tryTrackDeleteFromBasket($form);
                MiniBasketData.setLineCount(data.lineCount);
                Checkout.setLineCount(data.lineCount);
                if (data.lineCount == 0 || data.needRefresh) {

                    document.location.reload();
                    return;
                }

                Checkout.updateBasketTotals(data.needRefresh);
                Checkout.deleteBasketLines(data.deletedBasketLineIdList);
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("product-order-box.message.error.delete-from-basket", "Could not delete from basket.");
                Toaster.showAxiosMessage(err, defaultMessage)
            }).finally(() => {
                // do nothing
            });
    }

    private static validateOrderQuantity(totalOrderQuantity: number, requestedUnitsPerPack: number, minimumOrderQuantity: number): number {

        const decimalSeparator = window["Global"].DecimalSeparator;

        if (typeof (totalOrderQuantity) === "string") {
            totalOrderQuantity = parseFloat((totalOrderQuantity as string).toString().replace(decimalSeparator, "."));
        }

        const requestedMultiplier = totalOrderQuantity / requestedUnitsPerPack;
        if (requestedMultiplier > 1) {
            // return Math.ceil(requestedMultiplier);
            return Math.round((requestedMultiplier + Number.EPSILON) * 10000) / 10000;
            // return requestedMultiplier;
        }

        return minimumOrderQuantity;
    }

    private static tryTrackAddToBasket($form, quantity: number, monetaryValue: number, unitPrice: number) {
        // Track in GA
        let productDto = null;
        if (quantity == 0) {
            let quantityBox = $form.find(".js-product-order-box--total-order-quantity");
            if (quantityBox.length == 0) {
                quantityBox = $form.find(".js-product-order-box--order-quantity");
            }

            if (quantityBox.length == 0) {
                console.warn("No quantity box");
                return;
            }

            quantity = quantityBox.val();
        }

        let listName: string = "";
        let listId: string = "";
        let $listContainer = $form.closest("[data-datalayer-list-name]");
        if ($listContainer.length > 0) {
            listName = $listContainer.data("datalayer-list-name");
            listId = $listContainer.data("datalayer-list-id");
        }

        let $item = $form.closest(".js-product-item");

        if ($item.length > 0) {
            productDto = $item.data("datalayer-list-item");
        }

        if (productDto == null) {
            $item = $form.closest(".js-product-detail");

            if ($item.length > 0) {
                productDto = $item.data("datalayer-detail");
            }
        }

        if (productDto != null) {
            (productDto as NatchOsDataLayerProduct)._quantity = quantity;
            (productDto as NatchOsDataLayerProduct)._price = unitPrice;
            (productDto as NatchOsDataLayerProduct).list_name = listName;
            (productDto as NatchOsDataLayerProduct).list_id = listId;

            const natchgtm4 = new NatchGtm4(EventNamesSchema.OfficialGA4);
            natchgtm4.trackAddToCart(productDto, monetaryValue);
        }
    }

    private static tryTrackDeleteFromBasket($form) {
        const $item = $form.closest(".js-checkout-basket-line");

        if ($item.length === 0) {
            return;
        }

        const productDto = $item.data("datalayer-list-item");

        // type of productDto is a string when datalayer attribute does not contain a value
        if (typeof productDto === "string" && (productDto as String).length === 0) {
            return;
        }

        const quantity = $form.find(".js-product-order-box--order-quantity").val();

        const natchGtm4 = new NatchGtm4(EventNamesSchema.OfficialGA4);
        natchGtm4.trackRemoveFromCart(productDto, quantity);
    }
}
