<template>
    <div class="ps-2 pe-3" v-if="isEnabledAnonymousBaskets">
        <button type="button" class="btn btn-primary d-flex align-items-center js-mini-anonymous-basket">
            <i class="fa fa-shopping-cart"></i>
            <span class="mx-2 text-nowrap">{{ anonymousBasketButtonTitle }}</span>
            <span class="badge bg-light text-primary-500 js-quantity-badge">{{ lineCount }}</span>
        </button>
    </div>

    <div class="ps-2 pe-3" v-if="isAuthenticated">
        <a :href="checkoutUrl" class="position-relative nt-mini-basket">
            <i class="fa fa-shopping-cart mx-2 nt-mini-basket__icon"></i>
            <span class="position-absolute top-0 start-100 translate-middle badge rounded-pill nt-mini-basket__counter">
                {{ lineCount }}
            </span>
        </a>
    </div>
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import axios from "axios";
    import Multilang from "../scripts/utils/multilang";
    import MiniBasketData from "../scripts/models/mini-basket-data";
    import MiniBasketOptions from "../scripts/models/mini-basket-options";
    import { MiniBasketResponse } from "../scripts/models/basket";
    import { CurrentQuantities, Message } from "../scripts/models/anonymous-basket-data";

    const miniBasketContainerSelector: string = "button.js-mini-anonymous-basket";

    const showToasts = function (messages: Message[]) {
        for (let message of messages) {
            if (message.level == 0) {
                toastr.success(message.content);
            }
            if (message.level == 1) {
                toastr.warning(message.content);
            }
            if (message.level == 2) {
                toastr.error(message.content);
            }
        }
    };

    const getMiniBasketData = function() {
        axios
            .get<MiniBasketResponse>(MiniBasketOptions.getMiniUrl as string)
            .then((res) => res.data)
            .then((data: MiniBasketResponse) => {
                MiniBasketData.set(data);
            })
            .catch((err) => console.log(err));
    };

    const renderCurrentQuantities = function (data: CurrentQuantities) {
        MiniBasketData.setLineCount(data.lineCount);

        $(".js-anonymous-basket-box").each(function () {
            const $self = $(this);
            const $isInBasket = $self.find(".js-is-in-basket");

            const productId = $self.data("nt-product-id") as number;

            let foundQuantities = data.quantities.filter(i => { return i.id == productId });
            if (foundQuantities.length === 1) {
                $isInBasket.show();
            }
            else {
                $isInBasket.hide();
            }
        });
    };

    export default defineComponent({
        name: "MiniBasket",
        data() {
            return {
            };
        },
        props: {
            authenticated: String,
            anonymousCheckoutUrl: String,
            checkoutUrl: String,
            enableAnonymousBaskets: String,
            getCurrentQuantitiesUrl: String,
            getMiniUrl: String
        },
        setup: (props) => {
            Multilang.load();
            
            MiniBasketOptions.set(
                props.anonymousCheckoutUrl as string,
                props.checkoutUrl as string,
                props.enableAnonymousBaskets === "true",
                props.getCurrentQuantitiesUrl as string,
                props.getMiniUrl as string,
                props.authenticated === "true"
            );

            // only "return" values that have to be accessible in the template
            const anonymousBasketButtonTitle = Multilang.getTranslation("anonymous-basket.button.title");
            const checkoutUrl = props.checkoutUrl as string;
            const lineCount = MiniBasketData.lineCount;
            const isAuthenticated = props.authenticated === "true";
            const isEnabledAnonymousBaskets = props.enableAnonymousBaskets === "true";

            return {
                anonymousBasketButtonTitle,
                checkoutUrl,
                lineCount,
                isAuthenticated,
                isEnabledAnonymousBaskets
            };
        },
        methods: {
            getCurrentQuantities() {
                let productIds: number[] = [];

                $(".js-anonymous-basket-box").each((ix, element: HTMLElement) => {
                    const $self = $(element);
                    
                    const productId = $self.data("nt-product-id");

                    if (productIds.indexOf(productId) === -1) {
                        productIds.push(productId);
                    }
                });

                const postData = {
                    productIds: productIds,
                };

                axios
                    .post<CurrentQuantities>(MiniBasketOptions.getCurrentQuantitiesUrl as string, postData)
                    .then((res) => res.data)
                    .then((data: CurrentQuantities) => {
                        renderCurrentQuantities(data);
                        showToasts(data.messages);
                    }).catch((err) => {
                        toastr["error"](err);
                    }).finally(() => {
                        // do nothing
                    });
            },
            miniButtonHandler(e: JQueryEventObject) {
                document.location.href = MiniBasketOptions.anonymousCheckoutUrl as string;
            }
        },
        mounted() {
            if (MiniBasketOptions.enableAnonymousBaskets) {
                $(miniBasketContainerSelector).on("click", this.miniButtonHandler);
                this.getCurrentQuantities();
            }
            else if (MiniBasketOptions.isAuthenticated) {
                getMiniBasketData();
            }
        }
    });
</script>

<style lang="scss" scoped></style>
