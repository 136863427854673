﻿import $ from "jquery";
import $$ from "../utils/double-dollar";
import axios, { AxiosResponse, AxiosError } from "axios";
import CustomHelper from "../utils/custom-helper";
import Multilang from "../utils/multilang";
import MiniBasketData from "../models/mini-basket-data";
import { AddToBasketResult } from "../models/basket";
import Toaster from "./toaster";
import { Popover } from "bootstrap";
import DatalayerHelper from "../utils/datalayer-helper";
import { default as NatchGtm4, EventNamesSchema } from "natch-gtm4";
import CmsConfiguratorLauncher from "./cms-configurator-launcher";
import { Question } from "../models/configurator-question";

export default class QuickOrder {
    private static quickOrderInputSelector: string = ".js-quick-order--input-value";
    private static cmsConfiguration: Question[] | null = null;

    static init() {
        QuickOrder.initAddToBasket();
    }

    private static initAddToBasket() {
        const quickOrderDropdown = document.querySelector(".js-quick-order--dropdown") as HTMLElement;
        if (quickOrderDropdown != null) {
            quickOrderDropdown.addEventListener("shown.bs.dropdown", event => {
                const $inputValue = $(QuickOrder.quickOrderInputSelector);
                $inputValue.trigger("focus");
            });
        }

        $("body").on("click", ".js-quick-order--submit", e => {
            e.preventDefault();
            e.stopPropagation();

            const $submit = $(e.currentTarget);
            const $form = $submit.closest(".js-quick-order");

            $form.validate();

            if (!$form.valid()) {
                return;
            }

            QuickOrder.addToBasket($form);
        })
    }

    private static addToBasket($form) {
        const $inputValue = $form.find(QuickOrder.quickOrderInputSelector);

        let postData = QuickOrder.parseQuickOrderValue($inputValue);
        if (postData === null) {
            return;
        }

        if (QuickOrder.cmsConfiguration != null) {
            postData.configuration = { questions: [] };
            for (let question of QuickOrder.cmsConfiguration) {
                const q = {
                    question: question.question,
                    answer: question.choices[question.selectedChoiceId].choice
                };
                postData.configuration.questions.push(q);
            }

            postData.cmsConfiguration = QuickOrder.cmsConfiguration;
        }

        if (isNaN(postData.totalOrderQuantity)) {
            toastr.error(Multilang.getTranslation("quick-order.message.error.invalid-entry", "Invalid quick order entry."));
            return;
        }

        axios
            .post($form.prop("action"), postData)
            .then((res: AxiosResponse) => res.data)
            .then((data: AddToBasketResult) => {
                Toaster.showFeedbackMessage(data.messageType, data.message);
                MiniBasketData.setLineCount(data.lineCount);

                if (data.cmsConfiguration != null && data.needsConfiguration) {
                    // First ask for configuration (answer the questions)
                    console.log(data.cmsConfiguration);

                    CmsConfiguratorLauncher.askConfigurationEx(data.productId, data.quantity, data.cmsConfiguration, $form, true)
                        .then((questions: Question[]) => {
                            QuickOrder.cmsConfiguration = questions;
                            // call this method again, but this time QuickOrder.cmsConfiguration has a value, and the POST handler will use that data
                            QuickOrder.addToBasket($form);
                        })
                        .catch((reason: any) => {
                            QuickOrder.cmsConfiguration = null;
                            console.error(reason);
                        })
                    return;
                }

                DatalayerHelper.trackEvent("bm_basket_quick_entry", postData.productCode);
                $inputValue.val("").focus();

                if (data.dataLayerProduct) {
                    const gtm = new NatchGtm4(EventNamesSchema.OfficialGA4);
                    gtm.trackAddToCart(data.dataLayerProduct, data.totalLinePrice);
                }

                QuickOrder.cmsConfiguration = null;

                const needRefresh = $form.data("nt-need-refresh");
                if (needRefresh) {
                    QuickOrder.refreshWithAjax(document.location.href, $inputValue);
                    return;
                }
            }).catch((err: AxiosError) => {
                console.log(err);
                const defaultMessage = Multilang.getTranslation("quick-order.message.error.add-to-basket", "Could not add to basket.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }

    private static parseQuickOrderValue($inputValue: any): any | null {
        const inputValue = $inputValue.val() as string;
        const spaceIndex = inputValue.lastIndexOf(" ") as number;

        if (spaceIndex === -1) {
            toastr.error(Multilang.getTranslation("quick-order.message.error.invalid-entry", "Invalid quick order entry."));
            return null;
        }

        const parsedData = {
            productCode: inputValue.substr(0, spaceIndex) as string,
            totalOrderQuantity: parseFloat(inputValue.substr(spaceIndex + 1).replace(",", ".") as string)
        };

        return parsedData;
    }

    private static refreshWithAjax(url: string, $inputValue: any) {
        axios.get<string>(url)
            .then((res: AxiosResponse) => res.data)
            .then((data: string) => {
                const $fullPage = $(data);

                // refresh checkout overview
                const $checkoutOverview = $fullPage.find(".js-checkout-overview-async");
                $(".js-checkout-overview-async").html($checkoutOverview.html());

                // refresh basket line count
                const $basketLineCount = $fullPage.find(".js-checkout-order-box--basket-line-count");
                $(".js-checkout-order-box--basket-line-count").text($basketLineCount.text());

                const miniBasketLineCount = $basketLineCount.text() as unknown as number;
                MiniBasketData.setLineCount(miniBasketLineCount);

                // refresh totals
                const $checkoutTotals = $fullPage.find(".js-checkout-totals");
                $(".js-checkout-totals").html($checkoutTotals.html());

                // reset bootstrap toggles
                $$(`[data-bs-toggle="popover"]`, el => new Popover(el));
                $$(`[data-bs-toggle="popover-dom"]`, el => new Popover(el, { content: CustomHelper.getPopoverContent(el) }));

                // reset input value
                $inputValue.val("");
                $inputValue.focus();
            }).catch((err: AxiosError) => {
                const defaultMessage = Multilang.getTranslation("quick-order.message.error.refresh-checkout-page", "Could not refresh checkout page.");
                Toaster.showAxiosMessage(err, defaultMessage);
            }).finally(() => {
                // do nothing
            });
    }
}