﻿declare var dataLayer: any[];

export default class DatalayerHelper {
    static trackEvent(customEventName: string, parameterValue: string | null = null, callback: Function | null = null) {
        customEventName = customEventName.toLowerCase();
        if (!customEventName.startsWith("bm_")) {
            customEventName = "bm_" + customEventName;
        }

        if (!dataLayer) {
            callback && callback();
            return;
        }

        console.debug("Pushing event to datalayer", customEventName)
        dataLayer.push({
            event: customEventName,
            eventParameter: parameterValue,
            eventCallback: function () {
                // console.log("in the callback calling the callback", callback);
                callback && callback();
            },
            eventTimeout: 2000
        });
        return;
    }
};