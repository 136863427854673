﻿import $ from "jquery";
import axios from "axios";
import * as FilePond from 'filepond';
import Multilang from "../utils/multilang";

var Global = Global || { CultureCode: null, LanguageCode: null };

// https://pqina.nl/filepond/docs/patterns/api/server/

export default class BasketAttachment {
    static currentAttachmentUri;
    static pondInstance;
    static uploadUrl;
    static deleteUrl;

    static init() {
        const $basketAttachment = $(".js-basket-attachment");
        BasketAttachment.uploadUrl = $basketAttachment.data("nt-action-upload");
        BasketAttachment.deleteUrl = $basketAttachment.data("nt-action-delete");

        if (typeof FilePond.setOptions === "undefined") {
            return;
        }

        FilePond.setOptions({
            maxFiles: 1,
            allowBrowse: true,
            server: {
                process: BasketAttachment.uploadUrl,
                revert: BasketAttachment.deleteUrl
            },
            labelIdle: Multilang.getTranslation("basket.attachment.label.idle", "Klik hier of sleep hier een bestand"),
            labelFileProcessing: Multilang.getTranslation("basket.attachment.label.file-processing", "Please wait..."),
            labelFileProcessingComplete: Multilang.getTranslation("basket.attachment.label.file-processing-complete", "Upload klaar"),
            labelFileProcessingError: Multilang.getTranslation("basket.attachment.label.file-processing-error", "Fout tijdens opladen (bestand te groot?)"),
            labelTapToUndo: Multilang.getTranslation("basket.attachment.label.tap-to-undo", "Verwijder"),
            labelButtonRemoveItem: Multilang.getTranslation("basket.attachment.label.remove-item", "Verwijder"),
            labelButtonProcessItem: Multilang.getTranslation("basket.attachment.label.process-item", "Upload"),
            onerror: BasketAttachment.onError,
            onprocessfile: BasketAttachment.onProcessFile,
            onremovefile: BasketAttachment.onRemoveFile,
            credits: false
        });

        const inputElement = document.getElementById('basket-attachment-input');
        if (inputElement === null) {
            return ;
        }

        BasketAttachment.pondInstance = FilePond.create(inputElement);
        BasketAttachment.currentAttachmentUri = $("#current-basket-attachment-uri").val();
        BasketAttachment.viewOrHide();

        $(".js-basket-attachment--delete-attachment").on("click", BasketAttachment.deleteAttachment);
    }

    static viewOrHide() {
        const $withAttachment = $(".js-basket-attachment--with-attachment");
        const $withoutAttachment = $(".js-basket-attachment--without-attachment");
        
        const showWithAttachment = BasketAttachment.currentAttachmentUri !== ""
        $withAttachment.toggleClass("d-none", !showWithAttachment);
        $withAttachment.toggleClass("d-block", showWithAttachment);
        $withoutAttachment.toggleClass("d-none", showWithAttachment);
        $withoutAttachment.toggleClass("d-block", !showWithAttachment);
    }

    static deleteAttachment(e) {
        e.preventDefault();
        e.stopPropagation();
        
        if (BasketAttachment.currentAttachmentUri === "") {
            BasketAttachment.viewOrHide();
            return;
        }
        
        axios
            .delete(BasketAttachment.deleteUrl)
            .then(() => {
                BasketAttachment.currentAttachmentUri = "";
                BasketAttachment.pondInstance.removeFile();
                BasketAttachment.viewOrHide();
            })
            .catch((err) => {
                console.error(`Basket attachment deletion failed.`, err);
            })
            .finally(() => { 
                // do nothing 
            });
    }

    static onError(error, file, status) {
        console.error("Basket attachment error.", error, file, status);
    }

    static onProcessFile(error, file) {
        if (error == null) {
            BasketAttachment.currentAttachmentUri = file.serverId;
            
            const $currentAttachmentLink = $("#current-basket-attachment-link");
            $currentAttachmentLink.prop("href", BasketAttachment.currentAttachmentUri);
            $currentAttachmentLink.html(file.filename);

            BasketAttachment.viewOrHide();
        } else {
            console.error("Basket attachment process file error.", error, file);
        }
    }

    static onRemoveFile(error, file) {
        if (error == null) {
            BasketAttachment.currentAttachmentUri = "";
            BasketAttachment.viewOrHide();
        } else {
            console.error(error, file);
        }
    }
}