import { MenuAim } from 'es6-menu-aim';

export default class CustomMenuAim {
    /** Uses ES6 module to initialize the menu-aim component. */
    public static initES6() {
        let menuAimConfig = {
            /** Function to call when a row is purposefully activated.
             *  Use this to show a submenu's content for the activated row.*/
            activate: (targetRow) => {
                CustomMenuAim.closeAll();

                const toggleTarget = targetRow.dataset.menuAimTarget;
                if (typeof toggleTarget === "undefined") {
                    return;
                }

                const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
                // console.log("activate", toggleTargetElement);

                if (!toggleTargetElement) {
                    return;
                }

                toggleTargetElement.classList.toggle("d-none", false);
                toggleTargetElement.classList.toggle("d-block", true);

                targetRow.querySelector("a").classList.toggle("hover", true);

                CustomMenuAim.reactivateParent(toggleTargetElement);
            },

            /** Function to call when a row is deactivated */
            deactivate: (targetRow) => {
                const toggleTarget = targetRow.dataset.menuAimTarget;
                if (typeof toggleTarget === "undefined") {
                    return;
                }

                const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
                // console.log("deactivate", toggleTargetElement);

                if (!toggleTargetElement) {
                    return;
                }

                targetRow.querySelector("a").classList.toggle("hover", false);

                toggleTargetElement.classList.toggle("d-none", true);
                toggleTargetElement.classList.toggle("d-block", false);
            },

            /** Function to call when mouse enters a menu row.
             * Entering a row does not mean the row has been
             * activated, as the user may be mousing over to a submenu. */
            enter: (targetRow) => {
                const toggleTarget = targetRow.dataset.menuAimTarget;
                if (typeof toggleTarget === "undefined") {
                    return;
                }

                // const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
                // console.log("enter", toggleTargetElement);
            },

            /** Function to call when mouse exits a menu row. */
            exit: (targetRow) => {
                const toggleTarget = targetRow.dataset.menuAimTarget;
                if (typeof toggleTarget === "undefined") {
                    return;
                }

                // const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
                // console.log("exit", toggleTargetElement);
            },

            /** Function to call when mouse exits the entire menu. If this
             *  returns true, the current row's deactivation event and
             *  callback function will be fired. Otherwise, if this isn't
             *  supplied or it returns false, the currently activated row
             *  will stay activated when the mouse leaves the menu entirely. */
            exitMenu: (menuAimInstance) => { 
                // console.log("exitMenu"); 
            },

            /** Function to call when a menu row is clicked */
            clickRow: (mouseEvent, targetRow) => { 
                // console.log("clickRow");
            },

            /** Selector for identifying which elements in the menu are rows
             *  that can trigger the above events. Defaults to "> li". */
            ////rowSelector: "> li",

            /** You may have some menu rows that aren't submenus and therefore
             *  shouldn't ever need to "activate." If so, filter submenu rows
             *  w/ this selector. Defaults to "*" (all elements). */
            ////submenuSelector: "*",

            /** Direction the submenu opens relative to the main menu.
             *  This controls which direction is "forgiving" as the user
             *  moves their cursor from the main menu into the submenu.
             *  Can be one of "right", "left", "above", or "below".
             *  Defaults to "right". */
            ////submenuDirection: "right",

            /** for how long should menu remain displayed after the mouse has left it */
            ////exitDelay: null,

            /** bigger = more forgiving when entering submenu */
            tolerance: 75,

            /** set it to TRUE if the menu is not nested within another menu */
            ////isRoot: false
        };

        const menuList = document.querySelectorAll(".js-menu-aim");
        const menuArray = Array.from(menuList);

        menuArray.forEach((menuItem) => {
            let menuElement = menuItem as HTMLElement;
            let menuAim = new MenuAim(menuItem as HTMLElement, menuAimConfig);

            let menuLinkList = menuElement.querySelectorAll("li");
            let menuLinkArray = Array.from(menuLinkList);
            menuLinkArray.forEach(menuLinkItem => {
                if (menuLinkItem.hasAttribute("data-menu-aim-target")) {
                    menuAim.hookUp(menuLinkItem);
                }
            })
        })
    }

    /** Uses JQuery library to initialize the menu-aim component.
     *  Add <script src="/js/libs/jquery.menu-aim.js"></script> to the page when using it.
     */
    public static initJQuery() {
        const activate = targetRow => {
            CustomMenuAim.closeAll();

            const toggleTarget = targetRow.dataset.menuAimTarget;
            if (typeof toggleTarget === "undefined") {
                return;
            }

            const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
            // console.log("activate", toggleTargetElement);

            if (!toggleTargetElement) {
                return;
            }

            toggleTargetElement.classList.toggle("d-none", false);
            toggleTargetElement.classList.toggle("d-block", true);
            
            targetRow.querySelector("a").classList.toggle("hover", true);

            CustomMenuAim.reactivateParent(toggleTargetElement);
        };

        const deactivate = targetRow => {
            const toggleTarget = targetRow.dataset.menuAimTarget;
            if (typeof toggleTarget === "undefined") {
                return;
            }

            const toggleTargetElement = document.getElementById(toggleTarget) as HTMLElement;
            // console.log("deactivate", toggleTargetElement);

            if (!toggleTargetElement) {
                return;
            }

            toggleTargetElement.classList.toggle("d-none", true);
            toggleTargetElement.classList.toggle("d-block", false);
            
            targetRow.querySelector("a").classList.toggle("hover", false);
        };
        
        const enter = targetRow => {
            // console.log("enter", targetRow);
        }
        
        const exit = targetRow => {
            // console.log("exit", targetRow);
        }
        
        const $menuList = $("body").find(".js-menu-aim");
        
        // @ts-ignore
        $menuList.menuAim({
            activate: activate,
            deactivate: deactivate,
            enter: enter,
            exit: exit,
            rowSelector: "li[data-menu-aim-target]",
        })
    }
    
    private static reactivateParent(toggleTargetElement: any) {
        const toggleParent = toggleTargetElement.dataset.menuAimParent;
        if (typeof toggleParent === "undefined") {
            return;
        }

        const toggleParentElement = document.getElementById(toggleParent) as HTMLElement;
        // console.log("reactivate parent", toggleParentElement);

        if (!toggleParentElement) {
            return;
        }

        toggleParentElement.classList.toggle("d-none", false);
        toggleParentElement.classList.toggle("d-block", true);
        
        CustomMenuAim.reactivateParent(toggleParentElement);
    }
    
    private static closeAll() {
        const subMenuList = document.querySelectorAll(".js-menu-aim-sub");
        const subMenuArray = Array.from(subMenuList);

        subMenuArray.forEach((subMenuItem) => {
            subMenuItem.classList.toggle("d-none", true);
            subMenuItem.classList.toggle("d-block", false);
        })
    }
}