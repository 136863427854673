<template>
    <div class="nt-responsive-table align-middle" data-list-id="{{ dto.id }}">
        <div class="nt-responsive-table__header">
            <div class="nt-responsive-table__header-row">
                <div class="nt-responsive-table__header-row-item list-product__col-select-all text-start">
                    <input type="checkbox" v-model="dto.isAllSelected" @click="selectAll(dto)" class="form-check-input" id="select-all" />
                    <label class="form-check-label text-16-lh-sm ms-2 d-inline-block d-md-none" for="select-all">{{ labels.selectAll }}</label>
                </div>
                <div class="nt-responsive-table__header-row-item list-product__col-image d-none d-md-block">&nbsp;</div>
                <div class="nt-responsive-table__header-row-item list-product__col-favorite d-none d-md-block">&nbsp;</div>
                <div class="nt-responsive-table__header-row-item list-product__col-name d-none d-md-block">{{ labels.productName }}</div>
                <div class="nt-responsive-table__header-row-item list-product__col-stock d-none d-md-block">&nbsp;</div>
                <div class="nt-responsive-table__header-row-item list-product__col-price text-end d-none d-md-block">{{ labels.productPrice }}</div>
                <div class="nt-responsive-table__header-row-item list-product__col-order-quantity d-none d-md-block">{{ labels.productOrderQuantity }} </div>
                <div class="nt-responsive-table__header-row-item list-product__col-actions text-end d-none d-md-block">&nbsp;</div>
            </div>
        </div>

        <div class="nt-responsive-table__body">
            <template v-for="line in dto.items" :key="line.lineId">
                <div class="nt-responsive-table__body-row">
                    <div class="nt-responsive-table__body-row-item list-product__col-select text-start">
                        <input type="checkbox" class="form-check-input" v-model="line.selected" @change="selectLine(dto, line)" />
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-image">
                        <a v-bind:href="line.detailUrl" class="text-decoration-none" v-html="line.imageHtmlFragment"></a>
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-favorite text-center">
                        <i class="fas fa-star text-neutral-300" @click="toggleFavorite(line)" v-show="line.isFavorite"></i>
                        <i class="far fa-star text-neutral-300" @click="toggleFavorite(line)" v-show="!line.isFavorite"></i>
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-name" v-bind:class="'nt-listitem-selected-' + line.selected">
                        <a :href="line.detailUrl" class="text-decoration-none product-detail-source--search-result">{{ line.displayName }}</a>

                        <div class="d-flex flex-column flex-md-row align-items-center mt-2">
                            <div class="col-auto">{{ line.productNumber }}&nbsp;/&nbsp;</div>
                          
                            <div class="col-12 col-md-6" v-show="!context.allowEditCustomerProductCodes">
                                {{ line.customerProductCode === null || line.customerProductCode.length === 0 ? labels.productYourCode : line.customerProductCode }}
                            </div>

                            <div class="col-12 col-md-6" v-show="context.allowEditCustomerProductCodes">
                                <div class="input-group">
                                    <input type="text" maxlength="32" v-model="line.newCustomerProductCode" :ref="'cpcInput' + line.lineId" class="form-control" />
                                    <button type="button" class="btn btn-outline-secondary" v-show="isValidCustomerProductCode(line)" @click="saveCustomerProductCode(line)">
                                        <i class="fa fa-save"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-stock" v-html="line.stockHtmlFragment"></div>

                    <div class="nt-responsive-table__body-row-item list-product__col-price text-end">
                        <span v-show="line.priceOnRequest">{{ labels.productPriceOnRequest }}</span>

                        <div v-show="!line.priceOnRequest && line.isPromotionOrLiquidation && line.unitPriceNet !== null && line.unitPriceNet !== 0" class="text-16-lh-sm-bold">
                            <span>{{ labels.productYourPromoPrice }}</span>&nbsp;
                            <span>{{ displayNetPrice(line) }}</span>

                            <div v-show="line.unitPriceGross !== null && line.unitPriceGross !== 0" class="text-14-lh-sm text-neutral-400">
                                <span>{{ displayGrossPrice(line) }}</span>
                            </div>
                        </div>

                        <div v-show="!line.priceOnRequest && !line.isPromotionOrLiquidation && line.unitPriceNet !== null && line.unitPriceNet !== 0" class="text-16-lh-sm-bold">
                            <span v-if="line.isBlanketOrderPrice">{{ labels.productBlanketOrderPrice }}</span>
                            <span v-if="!line.isBlanketOrderPrice">{{ labels.productYourPrice }}</span>&nbsp;
                            <span>{{ displayNetPrice(line) }}</span>
                        </div>

                        <div v-show="!line.priceOnRequest && !line.isPromotionOrLiquidation && line.unitPriceGross !== null && line.unitPriceGross !== 0" class="text-14-lh-sm text-neutral-400">
                            <span>{{ labels.productPriceGross }}</span>&nbsp;
                            <span>{{ displayGrossPrice(line) }}</span>
                        </div>
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-order-quantity">
                        <input type="number" v-model="line.orderQuantity" @keyup.enter="saveItemQuantity(line)" @change="saveItemQuantity(line)" @keydown.tab.prevent="toggleNextLineEdit(dto, line)" :ref="'orderQuantityInput' + line.lineId" class="form-control" min="0" max="9999" maxlength="6" />
                    </div>

                    <div class="nt-responsive-table__body-row-item list-product__col-actions text-end">
                        <button type="button" class="btn btn-link" v-show="!dto.fixed" @click="deleteLine(dto, line)">
                            <i class="fa fa-trash-can"></i>
                        </button>
                    </div>
                </div>

                <hr class="d-block d-md-none my-3" />
            </template>
        </div>
    </div>
</template>

<script lang="ts">
    import { defineComponent, type PropType } from "vue";
    import $ from "jquery";
    import axios, { AxiosError } from "axios";
    import Toaster from "../scripts/components/toaster";
    import Multilang from "../scripts/utils/multilang";
    import ActionUrls from "../scripts/utils/action-urls";
    import { ListDto, ListItemDto, ParentContextType } from "../scripts/models/lists";
    import CustomHelper from "../scripts/utils/custom-helper";

    interface Labels {
        confirmDeleteItemPrompt: string;
        confirmDeleteItemButtonCancel: string;
        confirmDeleteItemButtonConfirm: string;
        productAdded: string;
        productBlanketOrderPrice: string;
        productName: string;
        productOrderQuantity: string;
        productPrice: string;
        productPriceGross: string;
        productPriceOnRequest: string;
        productQuantity: string;
        productYourCode: string;
        productYourPrice: string;
        productYourPromoPrice: string;
        selectAll: string;
    };

    interface ChangeCustomerProductCodeResponseData {
        success: boolean;
        customerProductCode: string;
    }

    interface DeleteLineResponseData {
        success: boolean;
    }

    interface ListActionUrls {
        updateCustomerProductCode: string;
        deleteLine: string;
        toggleFavorite: string;
    }

    interface ComponentData {
        labels: Labels;
        listActionUrls: ListActionUrls;
    };

    export default defineComponent({
        name: "ListProducts",
        data() {
            return {
                labels: {
                    confirmDeleteItemPrompt: Multilang.getTranslation("lists.delete.item.confirm"),
                    confirmDeleteItemButtonCancel: Multilang.getTranslation("lists.delete.item.confirm.button.cancel"),
                    confirmDeleteItemButtonConfirm: Multilang.getTranslation("lists.delete.item.confirm.button.confirm"),

                    productAdded: Multilang.getTranslation("list.line.added"),
                    productBlanketOrderPrice: Multilang.getTranslation("list.line.yourblanketorderprice"),
                    productName: Multilang.getTranslation("list.line.productnameno"),
                    productOrderQuantity: Multilang.getTranslation("list.line.order.quantity"),
                    productPrice: Multilang.getTranslation("list.line.price"),
                    productPriceGross: Multilang.getTranslation("list.line.grossprice"),
                    productPriceOnRequest: Multilang.getTranslation("list.line.priceonrequest"),
                    productQuantity: Multilang.getTranslation("list.line.quantity"),
                    productYourCode: Multilang.getTranslation("list.line.yourcode"),
                    productYourPrice: Multilang.getTranslation("list.line.yourprice"),
                    productYourPromoPrice: Multilang.getTranslation("list.line.yourpromoprice"),
                    selectAll: Multilang.getTranslation("list.action.select-all"),
                },
                listActionUrls: {
                    updateCustomerProductCode: ActionUrls.getUrl("update-customer-product-code"),
                    deleteLine: ActionUrls.getUrl("delete-line"),
                    toggleFavorite: ActionUrls.getUrl("update-favorite")
                }
            } as ComponentData;
        },
        props: {
            dto: Object as PropType<ListDto>,
            context: Object as PropType<ParentContextType>,
        },
        methods: {
            deleteLine(list: ListDto, item: ListItemDto) {
                this.context.cancelAllEdits();

                const ix = list.items.indexOf(item);
                if (ix === -1) {
                    return;
                }

                CustomHelper.confirmWithCustomButtonLabels(this.labels.confirmDeleteItemPrompt,
                    this.labels.confirmDeleteItemButtonCancel,
                    this.labels.confirmDeleteItemButtonConfirm,
                    () => {
                        const postData = { listId: list.id, lineId: item.lineId };

                        axios
                            .post<DeleteLineResponseData>(this.listActionUrls.deleteLine, postData)
                            .then((res) => res.data)
                            .then((data: DeleteLineResponseData) => {
                                list.items.splice(ix, 1);
                            })
                            .catch((err: AxiosError) => {
                                item.customerProductCode = "";
                                Toaster.showAxiosMessage(err, "DeleteLine fail");
                            });
                    });
            },
            displayGrossPrice(item: ListItemDto) {
                if (item.unitPriceGross === null) {
                    return null;
                }

                return item.unitPriceGross.toLocaleString("nl-be", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: 2
                });
            },
            displayNetPrice(item: ListItemDto) {
                if (item.unitPriceNet === null) {
                    return null;
                }

                return item.unitPriceNet.toLocaleString("nl-be", {
                    style: "currency",
                    currency: "EUR",
                    maximumFractionDigits: item.unitPriceNet > 1.0 ? 2 : 4
                });
            },
            isAllSelected(list: ListDto) {
                list.isAllSelected = true;

                for (let item of list.items) {
                    if (!item.selected) {
                        list.isAllSelected = false;
                        return;
                    }
                }

                return list.isAllSelected;
            },
            isValidCustomerProductCode(item: ListItemDto) {
                if (item.newCustomerProductCode === null) {
                    item.newCustomerProductCode = "";
                }

                return (item.newCustomerProductCode.trim().length >= 3 && item.newCustomerProductCode.trim().length <= 30) || item.newCustomerProductCode.trim().length === 0;
            },
            itemQuantityChanged(item: ListItemDto) {
                item.orderQuantity = item.newQuantity;
            },
            onCreateClick() { },
            onSelect() { },
            recalcSelection(list: ListDto) {
                this.isAllSelected(list);
            },
            saveCustomerProductCode(item: ListItemDto) {
                if (item.newCustomerProductCode === null) {
                    item.newCustomerProductCode = "";
                }

                item.newCustomerProductCode = item.newCustomerProductCode.trim();

                const postData = { productId: item.productId, code: item.newCustomerProductCode, originalCode: item.customerProductCode };

                axios
                    .post(this.listActionUrls.updateCustomerProductCode, postData)
                    .then((res) => res.data)
                    .then(() => {
                        item.customerProductCode = item.newCustomerProductCode;
                    })
                    .catch((err: AxiosError) => {
                        item.customerProductCode = "";
                        Toaster.showAxiosMessage(err, "Save CustomerProductCode fail");
                    });
            },
            saveItemQuantity(item: ListItemDto) {
                this.context.addToSaveQueue(item);
            },
            selectAll(list: ListDto) {
                if (this.isAllSelected(list)) {
                    for (let item of list.items) {
                        item.selected = false;

                        if (item.orderQuantity !== 0) {
                            item.orderQuantity = 0;
                            this.context.addToSaveQueue(item);
                        }
                    }

                    list.isAllSelected = false;
                } else {
                    for (let item of list.items) {
                        item.selected = true;
                        if (item.orderQuantity !== item.quantity) {
                            item.orderQuantity = item.quantity;
                            this.context.addToSaveQueue(item);
                        }
                    }

                    list.isAllSelected = true;
                }

                this.context.saveItemFromQueue();
            },
            selectLine(list: ListDto, item: ListItemDto) {
                if (item.selected) {
                    item.orderQuantity = item.quantity;
                    this.context.addToSaveQueue(item);
                } else {
                    item.orderQuantity = 0;
                    this.context.addToSaveQueue(item);
                }

                this.context.saveItemFromQueue();
                this.recalcSelection(list);
            },
            toggleFavorite(item: ListItemDto) {
                const postData = { productId: item.productId, isFavorite: !item.isFavorite };

                axios
                    .post(this.listActionUrls.toggleFavorite, postData)
                    .then((res) => res.data)
                    .then(() => {
                        item.isFavorite = !item.isFavorite;
                    })
                    .catch((err: AxiosError) => {
                        Toaster.showAxiosMessage(err, "ToggleFavorite fail");
                    });
            },
            toggleNextLineEdit(list: ListDto, item: ListItemDto) {
                this.context.saveItemQuantity(item);
            }
        },
        computed: {
        },
        created() {
        },
        mounted() {
        },
        setup: (props) => {
            let dto: ListDto = props.dto as ListDto;
            let context: ParentContextType = props.context as ParentContextType;
            return { dto, context }
        },
    });
</script>

<style lang="scss" scoped></style>
