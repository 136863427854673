<template>
    <!-- BarCodes Modal -------->
    <div class="modal fade" tabindex="-1" role="dialog" id="js-barcodes-modal" data-bs-backdrop="static" data-bs-keyboard="false">
        <div class="modal-dialog modal-fullscreen-sm-down" role="document">
            <div class="modal-content">
                <form action="" method="post" autocomplete="off">
                    <div class="modal-header">
                        <h4 class="modal-title">{{ modalTitle }}</h4>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" tabindex="-1"></button>
                    </div>

                    <div class="modal-body">
                        <div class="mb-3">
                            <h6 class="mb-1">{{ codesLabel }}</h6>

                            <div class="form-check form-check-inline">
                                <input type="radio" v-model="codes" value="1" name="codesRadios" class="form-check-input" id="article-code-1" checked>
                                <label for="article-code-1" class="form-check-label">{{ articleCodesLabel }}</label>
                            </div>

                            <div class="form-check form-check-inline">
                                <input type="radio" v-model="codes" value="2" name="codesRadios" class="form-check-input" id="article-code-2">
                                <label for="article-code-2" class="form-check-label">{{ customerArticleCodesLabel }}</label>
                            </div>
                        </div>

                        <div class="mb-3">
                            <h6 class="mb-1">{{ orderByLabel }}</h6>

                            <div class="form-check form-check-inline">
                                <input type="radio" v-model="orderBy" value="1" name="orderRadios" class="form-check-input" id="order-by-1" checked>
                                <label for="order-by-1" class="form-check-label">{{ orderByArticleCodesLabel }}</label>
                            </div>

                            <div class="form-check form-check-inline">
                                <input type="radio" v-model="orderBy" value="2" name="orderRadios" class="form-check-input" id="order-by-2">
                                <label for="order-by-2" class="form-check-label">{{ orderByCustomerArticleCodesLabel }}</label>
                            </div>
                        </div>

                        <div class="mb-3">
                            <label for="paper-type" class="form-label mb-1">{{ paperTypeLabel }}</label>
                            <select v-model="paperSizeId" class="form-select" id="paper-type">
                                <option value="0" disabled>{{ chooseLabel }}</option>
                                <option value="1">{{ paperType1 }}</option>
                                <option value="2">{{ paperType2 }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="modal-footer">
                        <button type="button" class="btn btn-secondary" data-bs-dismiss="modal" tabindex="-1">{{ cancelButton }}</button>
                        <button type="button" class="btn btn-primary" @click="downloadBarcodes" :disabled="isBusy" id="js-save" tabindex="-1">{{ printButton }}</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
    <!-- /BarCodes Modal ------->
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import { Modal } from "bootstrap";
    import Multilang from "../scripts/utils/multilang";
    import DatalayerHelper from "../scripts/utils/datalayer-helper";
    import ActionUrls from "../scripts/utils/action-urls";

    interface ComponentData {
        modalTitle: string;
        codesLabel: string;
        articleCodesLabel: string;
        customerArticleCodesLabel: string;
        orderByLabel: string;
        orderByArticleCodesLabel: string;
        orderByCustomerArticleCodesLabel: string;
        paperTypeLabel: string;
        chooseLabel: string;
        paperType1: string;
        paperType2: string;
        cancelButton: string;
        printButton: string;

        listId: number;
        paperSizeId: number;
        orderBy: number;
        codes: number;

        isBusy: boolean;

        downloadBarcodesUrl: string;
    };

    export default defineComponent({
        name: "PrintForm",
        data() {
            return {
                modalTitle: Multilang.getTranslation("lists.barcodes.popup.title"),
                codesLabel: Multilang.getTranslation("lists.barcodes.popup.codes"),
                articleCodesLabel: Multilang.getTranslation("lists.barcodes.popup.codes.1"),
                customerArticleCodesLabel: Multilang.getTranslation("lists.barcodes.popup.codes.2"),
                orderByLabel: Multilang.getTranslation("lists.barcodes.popup.orderby"),
                orderByArticleCodesLabel: Multilang.getTranslation("lists.barcodes.popup.orderby.1"),
                orderByCustomerArticleCodesLabel: Multilang.getTranslation("lists.barcodes.popup.orderby.2"),
                paperTypeLabel: Multilang.getTranslation("lists.barcodes.popup.papersize"),
                chooseLabel: Multilang.getTranslation("lists.barcodes.popup.select"),
                paperType1: Multilang.getTranslation("lists.barcodes.popup.papertype.1"),
                paperType2: Multilang.getTranslation("lists.barcodes.popup.papertype.2"),
                cancelButton: Multilang.getTranslation("lists.barcodes.popup.cancel"),
                printButton: Multilang.getTranslation("lists.barcodes.popup.print"),
                listId: 0,
                paperSizeId: 1,
                orderBy: 1,
                codes: 1,
                isBusy: false,
                downloadBarcodesUrl: ActionUrls.getUrl("download-barcodes"),
            } as ComponentData;
        },
        setup: () => {
            let modalInstance: any;
            return { modalInstance };
        },
        methods: {
            downloadBarcodes() {
                this.isBusy = true;

                let url = this.downloadBarcodesUrl;
                url += "?";
                url += "paperType=" + this.paperSizeId;
                url += "&";
                url += "listId=" + this.listId;
                url += "&";
                url += "codes=" + this.codes;
                url += "&";
                url += "sortOrder=" + this.orderBy;
                let opened = false;
                DatalayerHelper.trackEvent("bm_lists_export_pdf", this.listId.toString(), () => {
                    if (!opened) {
                        window.open(url, "_blank");
                        opened = true;
                    }
                });

                setTimeout(() => {
                    this.closeForm();
                    this.isBusy = false;
                }, 5000);

                // document.location.href = url;
            },
            openForm(listId: number) {
                this.listId = listId;
                this.modalInstance.show();
            },
            closeForm() {
                this.modalInstance.hide();
            }
        },
        created() {
        },
        mounted() {
            this.modalInstance = new Modal("#js-barcodes-modal");
        }
    });
</script>

<style lang="scss" scoped></style>
