﻿import $ from "jquery";
import { Modal } from "bootstrap";

export default class ListSelector {
    static modalInstance: Modal;

    public static init() {
        $(document).on("click", ".js-list-selector--activator", {}, ListSelector.activateListSelector);
        $(document).on("click", "#list-selector-modal [data-bs-dismiss='modal']", {}, ListSelector.closeListSelector);
        ListSelector.modalInstance = new Modal("#list-selector-modal", { keyboard: false });
    }

    private static closeListSelector(event) {
        document.getElementById("content")?.dispatchEvent(new CustomEvent("closeListSelector", { detail: null }));
    }

    private static activateListSelector(event) {
        event.preventDefault();
        event.stopPropagation();

        const $activator = $(this);
        
        const productId = $activator.data("nt-product-id");
        const childSelector = $activator.data("nt-list-selector-children");

        let q = $activator.data("q");
        if (typeof q === "undefined") {
            q = null;
        }

        let qref = $activator.data("qref");
        if (typeof qref === "undefined") {
            qref = null;
        }

        // console.log({
        //     pid: productId,
        //     q: q,
        //     qref: qref,
        //     childSelector: childSelector,
        //     activator: $activator
        // });

        document.getElementById("content")?.dispatchEvent(
            new CustomEvent("setListSelectorProductId", {
                detail: {
                    pid: productId,
                    qref: qref,
                    q: q,
                    childSelector: childSelector,
                    activator: $activator
                }
            })
        );

        ListSelector.modalInstance.show();
    }
}
