﻿import * as FilePond from "filepond";
import FilePondPluginFileEncode from 'filepond-plugin-file-encode';
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size';
import Multilang from "../utils/multilang";

export default class ListsUpload {
    static filePondInstanceTop;
    static filePondInstanceBottom;
    
    static init(): void {
        ListsUpload.initTopComponent(".js-lists-upload--default-top", ".js-lists-upload--filepond-top", ListsUpload.filePondInstanceTop);
        ListsUpload.initTopComponent(".js-lists-upload--default-bottom", ".js-lists-upload--filepond-bottom", ListsUpload.filePondInstanceBottom);
    }
    
    static initTopComponent(defaultTarget: string, filePondTarget: string, filePondInstance: any): void {
        const listsUploadFilePond = document.querySelector(filePondTarget) as HTMLElement;

        if (listsUploadFilePond === null || typeof FilePond.setOptions === "undefined") {
            ListsUpload.showDefaultUpload(defaultTarget);
            return;
        }

        listsUploadFilePond.classList.remove("d-none");

        FilePond.registerPlugin(
            FilePondPluginFileEncode, // encodes files as base64 (needed for regular form post)
            FilePondPluginFileValidateSize // validates file size
        );

        FilePond.setOptions({
            maxFiles: 1,
            allowBrowse: true,
            allowFileEncode: true,
            allowFileSizeValidation: true,
            maxFileSize: "5MB",
            maxTotalFileSize: "5MB",
            labelButtonProcessItem: Multilang.getTranslation("lists-upload.label.process-item", "Upload"),
            labelButtonRemoveItem: Multilang.getTranslation("lists-upload.label.remove-item", "Verwijder"),
            labelFileProcessing: Multilang.getTranslation("lists-upload.label.file-processing", "Wachten..."),
            labelFileProcessingComplete: Multilang.getTranslation("lists-upload.label.file-processing-complete", "Upload klaar"),
            labelFileProcessingError: Multilang.getTranslation("lists-upload.label.file-processing-error", "Fout tijdens opladen (bestand te groot?)"),
            labelIdle: Multilang.getTranslation("lists-upload.label.idle", "Klik hier of sleep hier een bestand"),
            labelTapToUndo: Multilang.getTranslation("lists-upload.label.tap-to-undo", "Verwijder"),
            labelMaxFileSize: Multilang.getTranslation("lists-upload.message.warning.file-size.max", "De maximum bestandsgrootte is {filesize}."),
            labelMaxFileSizeExceeded: Multilang.getTranslation("lists-upload.message.warning.file-size.max-exceeded", "De bestandsgrootte werd overschreden."),
            labelMaxTotalFileSize: Multilang.getTranslation("lists-upload.message.warning.file-size.max-total", "De maximum totale bestandsgrootte is {filesize}."),
            labelMaxTotalFileSizeExceeded: Multilang.getTranslation("lists-upload.message.warning.file-size.max-total-exceeded", "De maximum totale bestandsgrootte werd overschreden."),
            onerror: ListsUpload.onError,
            credits: false
        });

        filePondInstance = FilePond.create(listsUploadFilePond);
    }
    
    static showDefaultUpload(defaultTarget: string): void {
        const listsUploadDefault = document.querySelector(defaultTarget) as HTMLElement;

        if (listsUploadDefault === null) {
            return;
        }

        listsUploadDefault.classList.remove("d-none");
    }

    static onError(error, file, status): void {
        console.error("Lists upload error.", error, file, status);
    }
}