import $ from "jquery";
import axios, { AxiosError } from "axios";
import Multilang from "../utils/multilang";
import CustomHelper from "../utils/custom-helper";
import Toaster from "../components/toaster";
import "../extensions/string-extensions";
import DatalayerHelper from "../utils/datalayer-helper";

export default class Products {
    static init() {
        Products.initChangeCustomerProductCode();
        Products.initLogDownload();
        Products.initSectionLinks();

        Products.doLogProductDetail();
    }

    private static initChangeCustomerProductCode() {
        $("body").on("keyup", ".js-product-detail--customer-product-code", e => {
            const $customerProductCodeInput = $(e.currentTarget);

            const $customerProductCodeAction = $customerProductCodeInput
                .closest(".input-group")
                .find(".js-product-detail--save-customer-product-code");

            const code = $customerProductCodeInput.val() as string;
            const originalCode = $customerProductCodeInput.data("nt-original-code") as string;

            // do not use prop for toggling disabled as that does not work on links
            $customerProductCodeAction.attr("disabled", (code === originalCode ? "disabled" : null));
        });

        $("body").on("click", ".js-product-detail--save-customer-product-code", e => {
            e.preventDefault();
            e.stopPropagation();

            const $customerProductCodeAction = $(e.currentTarget);

            const $customerProductCodeInput = $customerProductCodeAction
                .closest(".input-group")
                .find(".js-product-detail--customer-product-code");

            const postData = {
                productId: $customerProductCodeAction.data("nt-product-id") as number,
                code: $customerProductCodeInput.val() as string,
                originalCode: $customerProductCodeInput.data("nt-original-code") as string
            };

            if (postData.code === postData.originalCode) {
                return;
            }

            axios
                .post($customerProductCodeAction.prop("href"), postData)
                .then((res) => res.data)
                .then((data: any) => {
                    $customerProductCodeInput.data("nt-original-code", postData.code);

                    // do not use prop for toggling disabled as that does not work on links
                    $customerProductCodeAction.attr("disabled", "disabled");

                    const successMessage = Multilang.getTranslation("product.message.success.update-customer-product-code", "Customer product code was updated successfully.");
                    toastr.success(successMessage);

                    DatalayerHelper.trackEvent("bm_product_customer_product_code");
                    
                }).catch((err: AxiosError) => {
                    const defaultMessage = Multilang.getTranslation("product.message.error.update-customer-product-code", "Could not update customer product code.");
                    Toaster.showAxiosMessage(err, defaultMessage);
                }).finally(() => {
                    // do nothing
                });
        })
    }

    private static doLogProductDetail() {
        const $element = $(".js-log-product-detail");

        if ($element.length === 1) {

            const postData = {
                productId: $element.data("nt-product-id")
            };

            axios
                .post($element.data("nt-action-url"), postData)
                .then((res) => res.data)
                .then((data: any) => {
                    console.log("Product #" + postData.productId + " has logged a detail view.");
                }).catch((err) => {
                    console.error(err);
                }).finally(() => {
                    // do nothing
                });
        }
    }

    private static initLogDownload() {
        $("body").on("click", ".js-download", e => {
            const $downloadLink = $(e.currentTarget);

            const postData = {
                productId: $downloadLink.data("nt-product-id"),
                url: $downloadLink.attr("href") ?? ""
            };

            DatalayerHelper.trackEvent("bm_product_download_pdf", postData.url);

            axios
                .post($downloadLink.data("nt-action-url"), postData)
                .then((res) => res.data)
                .then((data: any) => {
                    console.log("Product #" + postData.productId + " has logged a download.");
                }).catch((err) => {
                    console.error(err);
                }).finally(() => {
                    // do nothing
                });
        });
    }

    private static initSectionLinks() {
        $("[href*='#product-detail-']").each((ix: number, el: HTMLElement) => {
            const linkTarget = el.getAttribute("href") as string;
            const linkTargetId = linkTarget.replace("#", "");
            const linkSection = document.querySelector(`section[id='${linkTargetId}']`);

            if (linkSection !== null) {
                el.addEventListener("click", (e) => {
                    e.preventDefault();

                    const stickyHeaderHeight: number = CustomHelper.getStickyHeaderHeight(true);
                    CustomHelper.smoothScrollTo(linkTarget, stickyHeaderHeight);

                    window.location.hash = linkTargetId;
                })
            }
        });
    }
}
