﻿import $$ from "../utils/double-dollar";

export default class PriceLists {
    static init() {
        this.onChangeDivision();
        this.addOnChangeDivision();
        this.addOnChangeCategoryCheckbox();
        this.addOnClickCheckboxLabel();
    }

    private static addOnClickCheckboxLabel() {
        $(".js-category-node--checkbox-label").on('click', (event) => this.onClickCheckboxLabel(event));
    }
    private static onClickCheckboxLabel(event: JQuery.ClickEvent) {
        const checkboxLabel = event.target as HTMLElement;
        const checkbox = checkboxLabel.previousElementSibling as HTMLInputElement;
        checkbox.checked = !checkbox.checked;
        checkbox.dispatchEvent(new Event('change', { bubbles: true, cancelable: true }));
    }

    private static addOnChangeCategoryCheckbox() {
        $(".js-category-node--checkbox").on("change", (event) => this.onChangeCategoryCheckbox(event));
    }

    private static onChangeCategoryCheckbox(event: JQuery.ChangeEvent) {
        const $currentNode = event.target as HTMLInputElement
        const $childNodes = this.getChildNodes($currentNode);
        
        if ($currentNode.checked) {
            this.addCategoriesRecusively($currentNode, $childNodes);
            this.expandNode($currentNode);
        } else {
            this.removeCategoriesRecursively($currentNode, $childNodes);
        }

        this.updateChildNodesCheckboxValue($childNodes, $currentNode.checked);
        this.updateParentNodeCheckboxValue($currentNode);
    }
    private static expandNode($currentNode: HTMLInputElement) {
        $("#js-category-node--child-nodes-" + $currentNode.dataset.category).collapse("show");
    }

    private static removeCategoriesRecursively($currentNode: HTMLInputElement, $childNodes: HTMLInputElement[]) {
        const categoriesToRemove = this.getCategories($currentNode, $childNodes);

        let selectedCategories = this.getSelectedCategories();
        
        if (categoriesToRemove.length > 0) {
            selectedCategories = selectedCategories.filter(selectedCategory => !categoriesToRemove.includes(selectedCategory));
            selectedCategories = Array.from(new Set(selectedCategories));

            this.updateSelectedCategories(selectedCategories);
        }
    }

    private static updateParentNodeCheckboxValue($currentNode: HTMLInputElement) {
        const $siblingNodes = this.getSiblings($currentNode);
        const $parentNode = this.getParentNode($currentNode);

        if ($siblingNodes !== null && $parentNode !== null) {
            const numberOfCheckedSiblings = $siblingNodes.filter(($siblingNode) => $siblingNode.checked).length;
            const totalSiblings = $siblingNodes.length;

            if (numberOfCheckedSiblings == totalSiblings) {
                $parentNode.indeterminate = false;
                $parentNode.checked = true;
            } else if (numberOfCheckedSiblings == 0) {
                $parentNode.indeterminate = false;
                $parentNode.checked = false;
            } else {
                $parentNode.indeterminate = true;
            }
        }
    }

    private static updateChildNodesCheckboxValue($childNodes: HTMLInputElement[], checked: boolean) {
        $childNodes.forEach(($childNode) => $childNode.checked = checked);
    }

    private static getParentNode($currentNode: HTMLInputElement): HTMLInputElement | null {
        const parentCategory = $currentNode.dataset.parent;
        const $parentNode = $("#js-category-node--checkbox-" + parentCategory);
        
        if ($parentNode.length == 0) {
            return null
        }
        
        return $parentNode[0] as HTMLInputElement;
    }

    private static getSiblings($currentNode: HTMLInputElement): HTMLInputElement[] | null {
        const $parent = this.findParentWithClass($currentNode, "js-category-node--child-nodes");
        
        if ($parent != null) {
            const $childNodes = Array.prototype.slice.call($parent.querySelectorAll('.js-category-node--checkbox, .js-category-node--checkbox *'), 0) as HTMLInputElement[];
            return $childNodes.map(($childNode) => $childNode as HTMLInputElement);
        }

        return null;
    }

     private static findParentWithClass(element: HTMLElement, className: string): HTMLElement | null {
        let parent = element.parentElement;
        
        while (parent) {
            if (parent.classList.contains(className)) {
                return parent;
            }
            
            parent = parent.parentElement;
        }
        
        return null;
    }

    private static addCategoriesRecusively($currentNode: HTMLInputElement, $childNodes: HTMLInputElement[]) {
        const categoriesToAdd = this.getCategories($currentNode, $childNodes);
        let selectedCategories = this.getSelectedCategories();

        if (categoriesToAdd.length > 0) {
            selectedCategories.push(...categoriesToAdd);
            selectedCategories = Array.from(new Set(selectedCategories));
            selectedCategories = selectedCategories.filter((category) => category != "")
            this.updateSelectedCategories(selectedCategories);
        }
    }

    private static getCategories($currentNode: HTMLInputElement, $childNodes: HTMLInputElement[]) {
        let categories = [] as string[];
        
        const currentCategory = $currentNode.dataset.category ?? "";
        categories.push(currentCategory);

        const childCategories = $childNodes.map(($childNode) => $childNode.dataset.category ?? "");
        categories.push(...childCategories);
        categories = categories.filter((category) => category !== "");
        
        return categories;
    }

    private static getSelectedCategories(): string[] {
        const categoryString = $("#SelectedCategories").val()?.toString() ?? "";
        return categoryString.split(",");
    }

    private static updateSelectedCategories(categories: string[]) {
        $("#SelectedCategories").val(categories.join(","));
    }

    private static getChildNodes($checkbox: HTMLInputElement): HTMLInputElement[] {
        const startCategory = $checkbox.dataset.category;
        const $childrenContainer = $("#js-category-node--child-nodes-" + startCategory)[0] as HTMLElement;
        const $childNodes = Array.prototype.slice.call($childrenContainer.querySelectorAll('.js-category-node--checkbox, .js-category-node--checkbox *'), 0) as HTMLInputElement[];
        
        return $childNodes.map(($childNode) => $childNode as HTMLInputElement);
    }

    private static addOnChangeDivision() {
        const $divisionSelect = $<HTMLSelectElement>(".js-price-list--select-division");
        $divisionSelect.on("change", (event) => this.onChangeDivision());
    }

    private static onChangeDivision() {
        const selectedDivision = $<HTMLSelectElement>(".js-price-list--select-division").val() as string;
        const divisions = $$(".js-price-list--select-division option", (option) => option.getAttribute("value"));

        divisions.filter(division => division !== selectedDivision)
            .forEach(divisionToHide => $('#js-division-' + divisionToHide).collapse("hide"));

        $('#js-division-' + selectedDivision).collapse("show");
    }
}