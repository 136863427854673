﻿import $ from "jquery";
import ActionUrls from "../utils/action-urls";

export default class Skylux {

    private static hiveInstance;
    private static pluginToken;
    private static configurationId;
    private static cultureCode;

    static init() {
        // console.log("Skylux.init");
        ActionUrls.load();

        var domElement = $(".js-skylux-tokens");
        if (domElement.length == 0) {
            // console.log("Skylux.init - bailing, not a Skylux page");
            return;
        }

        $("#skylux-addtobasket-button").click(Skylux.addToBasketClicked);

        Skylux.loadScript("https://external-static.hivecpq.com/hive-1.0.2.js", function () {
            /*//console.log("Skylux.init DOM");*/

            Skylux.pluginToken = domElement.data("nt-skylux-plugin-token");
            Skylux.configurationId = domElement.data("nt-skylux-configuration-id");
            Skylux.cultureCode = domElement.data("nt-skylux-culture-code");

            Skylux.instantiate(Skylux.pluginToken);
            Skylux.openConfigurator(Skylux.configurationId);
        })
    }

    static loadScript(source, callBack) {
        var script = document.createElement("script");
        script.src = source;
        script.async = false;
        document.body.appendChild(script);
        
        script.addEventListener("load", () => {
            if (typeof callBack == "function") callBack();
        });
    }

    static instantiate(pluginToken) {
        let Hive = window["Hive"];

        //console.log("Skylux.instantiate", pluginToken);

        Skylux.pluginToken = pluginToken;

        console.log("Hive.instantiate");
        Skylux.hiveInstance = Hive.instantiate(
            "https://app.hivecpq.com",
            "https://api.hivecpq.com",
            "hive-launch-button",
            "hive-close-button",
            "100%",
            "1500px"
        );

        //console.log("Hive.instantiate Done");

        // Using undocumented stuff here.
        // Discovered using reverse engineering.
        window.addEventListener("message", event => {
            console.log("Skylux message event");
            try {
                if (event.data.integrationType === "webshop") {
                    // console.log(event.data);
                    if (event.data.type === "isConfigValid") {
                        if (event.data.message.data === "valid") {
                            $("#skylux-addtobasket-button").prop("disabled", false);
                        } else {
                            $("#skylux-addtobasket-button").prop("disabled", true);
                        }
                    } else if (event.data.type === "loading") {
                        if (event.data.message.parameters.loading === false) {
                            // console.log("Setting iframe class");
                            Skylux.hiveInstance.setIframeAttribute(
                                Skylux.hiveInstance.iframe,
                                "style",
                                "border:0;width:100%;height:1500px;"
                            );
                        }
                    }
                }
            } catch (e) {
                console.error(e);
            }
        });

        $("#skylux-addtobasket-button").prop("disabled", true);
        //console.log("Skylux.hiveInstance.init");
        Skylux.hiveInstance.init({ status: true, data: { access_token: Skylux.pluginToken } });

        /*Skylux.hiveInstance.isConfigValid().subscribe(valid => {
            $("#skylux-addtobasket-button").prop("disabled", !valid);
        });*/

        //// let bool1 = Skylux.hiveInstance.isConfigValid();
        //// let hiveId = hiveInstance.getHiveId();
    }

    static openConfigurator(projectSegmentItemId) {
        if (projectSegmentItemId != null && projectSegmentItemId != "") {
            // alert(projectSegmentItemId);
            // Skylux.hiveInstance.externalSegment.projectSegmentItemId = projectSegmentItemId;
            Skylux.configurationId = projectSegmentItemId;
        }
        //console.log("Trying to launch");
        $("." + Skylux.hiveInstance.launchButtonsClass).click();
    }

    static addToBasketClicked(e) {
        let id = Skylux.hiveInstance.externalSegment.projectSegmentItemId;
        let configId = Skylux.configurationId;
        // console.log(id);

        if (Skylux.hiveInstance.isConfigurationValid === false) {
            console.log("Not Valid");
            return;
        }

        const actionUrl = ActionUrls.getUrl("add-configuration-to-basket");
        const q = $.get(`${actionUrl}?projectSegmentItemId=${id}&configurationId=${configId}`);
        
        q.done(model => {
            if (model.success) {
                toastr.success(model.message);
                
                if (model.productsAdded != 0) {
                    document.location.href = `/${Skylux.cultureCode}/checkout`;
                }
            } else {
                toastr.error(model.message);
            }
        });
    }
}
