<template>
    <div class="modal fade" tabindex="-1" role="dialog" id="cms-configurator-modal">
        <div class="modal-dialog modal-md modal-fullscreen-sm-down" role="document" id="vue-cms-configurator-app">
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="modal-title">{{ title }}</h4>
                    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>

                <div class="modal-body nt-cms-configurator">

                    <template v-for="question in questions" :key="question.id">
                        <div class="mt-2">{{ question.question }}</div>
                        <div v-for="choice in question.choices" :key="choice.id" class="mx-2">
                            <input type="radio" :value="choice.id" :id="choice.elementId" v-model="question.selectedChoiceId" @change="choicemade" :name="'question'+question.id" />
                            <label :for="choice.elementId" class="mx-2">{{ choice.choice }}</label>
                        </div>
                    </template>

                </div>

                <div class="modal-footer">
                    <button type="button" :disabled="!choicesValid" class="btn btn-secondary" @click="submitClicked" data-bs-dismiss="modal">{{ okButton }}</button>
                </div>
            </div><!-- /.modal-content -->
        </div><!-- /.modal-dialog -->
    </div><!-- /.modal -->
</template>

<script lang="ts">
    import { defineComponent } from "vue";
    import Multilang from "../scripts/utils/multilang";
    import CmsConfiguratorLauncher from "../scripts/components/cms-configurator-launcher";
    import { Question, Choice } from "../scripts/models/configurator-question";

    interface ComponentData {
        pid: number;
        questions: Question[];
        activator: any;
        title: string;
        okButton: string;
        choicesValid: boolean;
        quantity: number;
    }

    export default defineComponent({
        name: "CmsConfigurator",
        data() {
            return {
                pid: 0,
                choicesValid: false,
                questions: [],
                activator: null,
                quantity: 0,
                title: Multilang.getTranslation("cms.configurator.title", "Configureer"),
                okButton: Multilang.getTranslation("common.ok", "OK"),
            } as ComponentData;
        },
        props: {
        },
        methods: {
            onCreateClick() { },
            onSelect() { },
            choicemade() {
                this.choicesValid = !(this.questions.some((q) => q.selectedChoiceId == -1));
            },
            submitClicked() {
                for (let question of this.questions) {
                    question.answer = question.choices[question.selectedChoiceId].choice;
                }

                CmsConfiguratorLauncher.resolve(this.questions);
            },
            loadData() {
                console.log("Configurator for product #" + this.pid);

                // just add ids to questions and answers
                for (let [qix, question] of this.questions.entries()) {
                    question.id = qix;
                    question.selectedChoiceId = -1;
                    for (let [cix, choice] of question.choices.entries()) {
                        choice.id = cix;
                        choice.elementId = `q${qix}a${cix}`;
                    }
                }
            },
            toggleActivator() {
                const self = this;

                if (self.activator === null) {
                    return;
                }
            },
        },
        computed: {
            countSquared(): Number {
                return this.pid * this.pid;
            },
        },
        created() {
            document.getElementById("content")?.addEventListener("setCmsConfiguratorData", ((e: CustomEvent) => {

                this.pid = e.detail.pid;
                this.questions = e.detail.data.questions;
                this.activator = e.detail.activator;
                this.quantity = e.detail.quantity;

                this.loadData();
            }) as EventListener);

            document.getElementById("content")?.addEventListener("closeCmsConfigurator", (e: Event) => {
            });
        },
        mounted() {
        },
        setup: (props) => {
            Multilang.load();
            return { }
        },
    });
</script>

<style lang="scss" scoped>
</style>
