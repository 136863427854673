type MultilangLabel = {
    name: string;
    value: string;
};

export default class Multilang {
    private static LanguageCode: string;
    private static LongLanguageCode: string;
    private static Labels: MultilangLabel[] = [];

    static load() {
        const multilangJson = (document.querySelector(".js-multilang") as HTMLInputElement).value;
        const multilangObject = JSON.parse(multilangJson);

        this.LanguageCode = multilangObject.languageCode;
        this.LongLanguageCode = multilangObject.longLanguageCode;
        this.Labels = multilangObject.labels;
    }

    static getLanguageCode() {
        return this.LanguageCode;
    }

    static getLongLanguageCode() {
        return this.LongLanguageCode;
    }

    static getTranslation(labelName: string, defaultValue: string = ""): string {
        const filteredLabels = this.Labels.filter((l) => l.name === labelName);
        
        if (filteredLabels.length === 0) {
            console.warn(`Could not find multilang label '${labelName}', showing the default translation instead.`);
            return defaultValue;
        }

        return filteredLabels[0].value;
    }
}
