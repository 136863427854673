﻿import $ from "jquery";
import $$ from "../utils/double-dollar";
import axios, { AxiosError } from "axios";
import { Modal, Popover } from "bootstrap";
import ActionUrls from "../utils/action-urls";
import CustomHelper from "../utils/custom-helper";
import AsyncPrices from "./async-prices";

export default class ProductSuggestions {
    static trySuggest(productId: number, quantity: number, productSetPrice: string, suggestionList: Array<number>, suggestionRatioList: Array<number>) {
        let url = ActionUrls.getUrl("get-product-suggestions") as string;
        url = `${url}?productid=${productId}&suggestions=${suggestionList.join()}`

        axios
            .get<string>(url)
            .then((res) => res.data)
            .then((data: string) => {
                const productSuggestionsElement = document.getElementById("product-suggestions-modal") as HTMLElement;
                const productSuggestionsModal = new Modal(productSuggestionsElement);
                productSuggestionsModal.show();

                const $modalBody = $("#product-suggestions-modal").find(".modal-body");
                $modalBody.html(data);

                $(".js-product-suggestions--set-price").html(productSetPrice);

                if (suggestionList.length > 0 && suggestionList.length === suggestionRatioList.length) {
                    for (let i = 0; i < suggestionList.length; ++i) {
                        const productId = suggestionList[i];
                        const ratio = suggestionRatioList[i];
                        const selector = ".modal-body #product-card-" + productId + " ";

                        if (ratio >= 1) {
                            $(selector + ".js-product-order-box--order-quantity").val(quantity * ratio);
                            $(selector + ".js-product-order-box--total-order-quantity").val(quantity * ratio);
                        }

                    }
                }

                AsyncPrices.startLoadPricesForProductSuggestions();

                $$(`[data-bs-toggle="popover"]`, el => new Popover(el));
                $$(`[data-bs-toggle="popover-dom"]`, el => new Popover(el, {content: CustomHelper.getPopoverContent(el)}));
            })
            .catch((err: AxiosError) => console.error(err));
    }
}
