interface String {
    toArray: any;
    toToastrMessageType(): string;
}

String.prototype.toArray = function() {
    return (this as string).replace(/\[|\]/g,'').split(',') as Array<string>;
}

String.prototype.toToastrMessageType = function() {
    const messageTypeList = {
        "Success": "success",
        "Error": "error",
        "Information": "info",
        "Warning": "warn"
    };

    return messageTypeList[this as string];
}