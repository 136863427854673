﻿import $ from "jquery";
import "jquery-validation";
import "jquery-validation-unobtrusive";

export default class Validators {
    static init() {
        Validators.initVatValidator();
    }
    
    static initVatValidator() {
        $.validator.addMethod("vat", function (value, element, params) {
            // @ts-ignore
            if (!this.optional(element)) {
                const otherProperty = $("#" + params.otherProperty);
                if (otherProperty.val() == params.otherValue) {
                    return Validators.isBelgianVat(value) || Validators.isFrenchVat(value);
                }
            }

            return true;
        });

        $.validator.unobtrusive.adapters.add("vat", ["otherproperty", "othervalue"],
            function(options) {
                options.rules["vat"] = {
                    otherProperty: options.params.otherproperty,
                    otherValue: options.params.othervalue
                };

                options.messages["vat"] = options.message;
            }
        );
    }

    static isBelgianVat(input: string) {
        // remove spaces and dots
        let cleanVat = input.replace(/ /g, "");
        cleanVat = cleanVat.replace(/\./g, "");
        
        let result = false;
        const pattern = /^BE[01]?[0-9]{9}$/i;
        const regex = new RegExp(pattern);

        if (regex.test(cleanVat)) {
            cleanVat = cleanVat.replace(/BE/gi, "");
            
            const vatNumber = parseInt(cleanVat, 10);
            result = (97 - (Math.floor(vatNumber / 100) % 97) == vatNumber % 100);
        }

        return result;
    }

    static isFrenchVat(input) {
        // remove spaces and dots
        let cleanVat = input.replace(/ /g, "");
        cleanVat = cleanVat.replace(/\./g, "");
        
        let result = false;
        const pattern = /^FR[0-9]{11}$/i;
        const regex = new RegExp(pattern);

        if (regex.test(cleanVat)) {
            result = true;
        }

        return result;
    }
}