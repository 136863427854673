﻿export default class BlanketOrder {
    static init() {
        const $blanketOrderList = $(".js-blanket-order--list");

        if ($blanketOrderList.length === 0) {
            return;
        }

        BlanketOrder.initList($blanketOrderList);
    }
    
    static initList($blanketOrderList: JQuery<HTMLElement>): void {
        $blanketOrderList.find(".js-blanket-order--list-details").each((ix: number, el: HTMLElement) => {
            const $toggle: JQuery<HTMLElement> = $blanketOrderList.find(`[href="#${el.id}"]`);

            el.addEventListener('shown.bs.collapse', () => {
                BlanketOrder.toggleDetailText($toggle);
            });

            el.addEventListener('hidden.bs.collapse', () => {
                BlanketOrder.toggleDetailText($toggle);
            });
        });    
    }
    
    static toggleDetailText($toggle: JQuery<HTMLElement>) {
        const $hiddenText: JQuery<HTMLElement> = $toggle.find("span.d-none");
        const $visibleText: JQuery<HTMLElement> = $toggle.find("span.d-block")

        $hiddenText.toggleClass("d-none", false);
        $hiddenText.toggleClass("d-block", true);

        $visibleText.toggleClass("d-none", true);
        $visibleText.toggleClass("d-block", false);
    }
}