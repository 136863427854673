﻿import $ from "jquery";
import $$ from "../utils/double-dollar";
import { Popover } from "bootstrap";
import CustomHelper from "../utils/custom-helper";
import axios from "axios";
import { TransferDateData } from "../models/basket";
import { DatePickerData } from "../models/date-picker";
import CustomDatePicker from "./custom-date-picker";
import AsyncPrices from "./async-prices";

// https://github.com/devbridge/jQuery-Autocomplete/blob/master/dist/jquery.autocomplete.js

type Options = {
    serviceUrl: string;
    minimumInputLength: number;
    lookupDelay: number;
    onSelectProduct: Function;
    onSelectBrand: Function;
    onSelectCategory: Function;
    onSelectAll: Function;
};

export default class Autocomplete2 {
    static options: Options;
    static $container: JQuery<any>;
    static $el: JQuery<any>;
    static container: HTMLDivElement;
    static delayTimer: number | null;
    static blurTimer: number;
    static busy: boolean = false;
    static visible: boolean = false;
    static hovering: boolean = false;
    static previousLookup: string | null = null;
    static cachedResponses = {};
    static mainClass: string = "autocomplete2-dropdown";
    static eventNamespace = ".autocomplete2";
    static hasLeft: boolean;
    static hasRight: boolean;

    constructor() { }

    static defaults: Options = {
        serviceUrl: "",
        minimumInputLength: 3,
        lookupDelay: 200,
        onSelectProduct: function () { },
        onSelectBrand: function () { },
        onSelectCategory: function () { },
        onSelectAll: function () { }
    };

    static keys = {
        ESC: 27,
        TAB: 9,
        RETURN: 13,
        LEFT: 37,
        UP: 38,
        RIGHT: 39,
        DOWN: 40
    };

    static isArray = data => {
        return Object.prototype.toString.call(data) === "[object Array]";
    };

    static init(options: object) {
        Autocomplete2.options = $.extend({}, Autocomplete2.defaults, options);
        // console.log("Autocomplete2.init", Autocomplete2.options.serviceUrl);

        const div = document.createElement("div");
        div.className = Autocomplete2.mainClass;
        div.style.position = "absolute";
        div.style.display = "none";
        
        Autocomplete2.container = div;
        Autocomplete2.$container = $(div);

        if (Autocomplete2.container === null) {
            return;
        }

        if (Autocomplete2.$container === null) {
            return;
        }
        
        $(window).on("resize" + Autocomplete2.eventNamespace, Autocomplete2.fixPosition);

        $(document).on("keyup", '[data-nt-provide="autocomplete2"]', function (e) {
            const $this = $(this);
            
            if (Autocomplete2.$el === null || (typeof Autocomplete2.$el === "undefined")) {
                Autocomplete2.$el = $this;
                Autocomplete2.$container.insertAfter(Autocomplete2.$el);
                
                $this.on("keydown" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onKeyPress(e);
                });
                
                $this.on("keyup" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onKeyUp(e);
                });
                
                $this.on("blur" + Autocomplete2.eventNamespace, function () {
                    Autocomplete2.onBlur();
                });
                
                $this.on("focus" + Autocomplete2.eventNamespace, function () {
                    Autocomplete2.onFocus();
                });
                
                $this.on("change" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onKeyUp(e);
                });
                
                $this.on("input" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onKeyUp(e);
                });
                
                Autocomplete2.$container.on("mouseover" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onMouseover(e);
                });
                
                Autocomplete2.$container.on("mouseout" + Autocomplete2.eventNamespace, function (e) {
                    Autocomplete2.onMouseout(e);
                });
                
                $("body").on("click" + Autocomplete2.eventNamespace, function () {
                    Autocomplete2.onBlur();
                });
            }

            if (e.which == Autocomplete2.keys.UP) {
                Autocomplete2.hide();
            } else if (e.which == Autocomplete2.keys.RIGHT) {
                Autocomplete2.hide();
            } else if (e.which == Autocomplete2.keys.LEFT) {
                Autocomplete2.hide();
            } else if (e.which == Autocomplete2.keys.ESC) {
                Autocomplete2.hide();
            } else {
                if (Autocomplete2.isValidInput(Autocomplete2.getValue())) {
                    Autocomplete2.lookup();
                } else {
                    Autocomplete2.hide();
                }
            }
        });
    }

    static hide() {
        Autocomplete2.$container.hide();
        Autocomplete2.visible = false;

        Autocomplete2.$container.off("mouseover" + Autocomplete2.eventNamespace);
        Autocomplete2.$container.off("mouseout" + Autocomplete2.eventNamespace);
    }

    static show() {
        Autocomplete2.$container.show();
        Autocomplete2.visible = true;
        Autocomplete2.hovering = true;

        Autocomplete2.$container.on("mouseover" + Autocomplete2.eventNamespace, function (e) {
            Autocomplete2.onMouseover(e);
        });
        
        Autocomplete2.$container.on("mouseout" + Autocomplete2.eventNamespace, function (e) {
            Autocomplete2.onMouseout(e);
        });
    }

    static isValidInput(value: string): boolean {
        return value.length >= Autocomplete2.options.minimumInputLength;
    }

    static getValue(): string {
        let value = Autocomplete2.$el.val() || "";
        
        if (typeof value === "number") {
            return "" + value;
        } else if (Autocomplete2.isArray(value) && value.length > 0) {
            return value[0];
        }
        
        return value as string;
    }

    static lookup() {
        if (Autocomplete2.delayTimer !== null) {
            clearTimeout(Autocomplete2.delayTimer);
            Autocomplete2.delayTimer = null;
        }

        Autocomplete2.delayTimer = setTimeout(Autocomplete2.actualLookup, Autocomplete2.options.lookupDelay);
    }

    static actualLookup() {
        let value = Autocomplete2.getValue();
        if (value === Autocomplete2.previousLookup) {
            Autocomplete2.show();
            return;
        }

        if (Autocomplete2.isValidInput(value)) {
            const cacheKey = value;
            
            const fromCache = Autocomplete2.cachedResponses[cacheKey];
            if (fromCache) {
                Autocomplete2.previousLookup = value;
                Autocomplete2.render(fromCache.html);
            } else {
                Autocomplete2.loadSuggestions(value);
            }
        } else {
            Autocomplete2.hide();
        }
    }

    static loadSuggestions(value: string) {
        if (Autocomplete2.busy) {
            // TODO: cancel current request (use abort controller)?
            return;
        }

        Autocomplete2.busy = true;

        axios
            .get<TransferDateData>(`${Autocomplete2.options.serviceUrl}?query=${value}`)
            .then((res) => res.data)
            .then((data: any) => {
                if (data.success) {
                    Autocomplete2.previousLookup = value;
                    Autocomplete2.render(data.html);

                    const cacheKey = value;
                    Autocomplete2.cachedResponses[cacheKey] = data;
                } else {
                    Autocomplete2.hide();
                }
                
                Autocomplete2.busy = false;
            }).catch((err) => {
                Autocomplete2.hide();
                Autocomplete2.busy = false;
            }).finally(() => {
                // do nothing
            });
    }

    static hookEvents() {
        $$(`[data-bs-toggle="popover"]`, el => new Popover(el));
        $$(`[data-bs-toggle="popover-dom"]`, el => new Popover(el, { content: CustomHelper.getPopoverContent(el) }));

        // Autocomplete2.$container.off(Autocomplete2.eventNamespace); // TODO?
    }

    static unHookEvents() {
        Autocomplete2.$container.off(Autocomplete2.eventNamespace);
    }

    static render(html: string) {
        if (Autocomplete2.container.children.length > 0) {
            Autocomplete2.unHookEvents();
            Autocomplete2.container.removeChild(Autocomplete2.container.children[0]);
        }

        const content = document.createElement("div");
        content.innerHTML = html;
        
        Autocomplete2.hasLeft = html.indexOf("autocomplete2-left-side") > 0;
        Autocomplete2.hasRight = html.indexOf("autocomplete2-right-side") > 0;
        Autocomplete2.container.appendChild(content);
        Autocomplete2.show();
        Autocomplete2.fixPosition();
        Autocomplete2.hookEvents();

        AsyncPrices.startLoadPricesForAutoComplete();
    }

    static destroy() { }

    static onMouseover(e: JQuery.TriggeredEvent<any, undefined, any, any>) {
        Autocomplete2.hovering = true;
    }

    static onMouseout(e: JQuery.TriggeredEvent<any, undefined, any, any>) {
        Autocomplete2.hovering = false;
    }

    static onKeyPress(e) { }

    static onKeyUp(e) { }

    static onFocus() {
        Autocomplete2.fixPosition();

        if (Autocomplete2.getValue().length >= Autocomplete2.options.minimumInputLength) {
            Autocomplete2.lookup();
        }
    }

    static onBlur() {
        if (Autocomplete2.hovering === false) {
            Autocomplete2.blurTimer = setTimeout(function () {
                Autocomplete2.hide();
            }, 200);
        }
    }
    
    static fixPosition() {
        if (Autocomplete2.visible === false) {
            return;
        }

        const containerParent = Autocomplete2.$container.parent().get(0);
        const elementParent = Autocomplete2.$el.parent();
        const elementParentParent = elementParent.parents(".row");

        if (typeof elementParentParent === "undefined") {
            console.debug("elementParentParent is undefined");
            return;
        }

        const height = elementParent.outerHeight(),
            offset = elementParent.offset(),
            styles = { top: offset?.top ?? 0, left: offset?.left ?? 0, width: "auto" };

        const parentOffsetDiff = Autocomplete2.$container.offsetParent().offset();

        styles.top -= parentOffsetDiff?.top ?? 0;
        styles.top += containerParent.scrollTop;
        styles.top += height ?? 0;
        styles.left -= parentOffsetDiff?.left ?? 0;

        Autocomplete2.$container.css(styles);
    }
}