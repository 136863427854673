import $ from "jquery";
import { Modal } from "bootstrap";
import { Question, Choice } from "../models/configurator-question";

type resolverCallbackType = (questions: Question[], activator: any) => void;
type rejectorCallbackType = (reason?: any) => void;

export default class CmsConfiguratorLauncher {
    private static modalInstance: Modal;
    private static rejector: rejectorCallbackType | null = null;
    private static resolver: resolverCallbackType | null = null;
    private static activator: any | null = null;

    public static init() {
        //$(document).on("submit", ".js-product-order-box", {}, CmsConfigurator.activateCmsConfigurator);
        $(document).on("click", "#cms-configurator-modal [data-bs-dismiss='modal']", {}, CmsConfiguratorLauncher.closeCmsConfigurator);
        CmsConfiguratorLauncher.modalInstance = new Modal("#cms-configurator-modal", { keyboard: false });
    }

    public static needsConfiguration($form: any): boolean {
        const data = $form.data("nt-configurator-json");
        if (data == null || data === "") {
            return false;
        }

        return true;
    }

    public static resolve(configuration: Question[]) {
        if (CmsConfiguratorLauncher.resolver != null) {
            CmsConfiguratorLauncher.resolver(configuration, CmsConfiguratorLauncher.activator);
        }
    }

    public static reject(reason?: any) {
        if (CmsConfiguratorLauncher.rejector != null) {
            CmsConfiguratorLauncher.rejector(reason);
        }
    }

    public static askConfiguration($form: any, quantity: number): Promise<Question[]> {
        return new Promise<Question[]>((resolve, reject) => {
            CmsConfiguratorLauncher.resolver = resolve;
            CmsConfiguratorLauncher.rejector = reject;
            CmsConfiguratorLauncher.activator = $form;

            const productId = $form.data("nt-product-id");
            const data = $form.data("nt-configurator-json");
            if (data == null || data === "") {
                CmsConfiguratorLauncher.rejector("Configuration n/a");
                return;
            }

            document.getElementById("content")?.dispatchEvent(
                new CustomEvent("setCmsConfiguratorData", {
                    detail: {
                        pid: productId,
                        data: data,
                        quantity: quantity,
                        activator: $form,
                        launcher: CmsConfiguratorLauncher
                    }
                })
            );

            CmsConfiguratorLauncher.modalInstance.show();
        });
    }

    public static askConfigurationEx(productId: number, quantity: number, configuration: any, $activator: any, isFromQuickOrder: boolean): Promise<Question[]> {
        return new Promise<Question[]>((resolve, reject) => {
            CmsConfiguratorLauncher.resolver = resolve;
            CmsConfiguratorLauncher.rejector = reject;
            CmsConfiguratorLauncher.activator = $activator;

            if (configuration == null) {
                CmsConfiguratorLauncher.rejector("Configuration n/a");
                return;
            }

            document.getElementById("content")?.dispatchEvent(
                new CustomEvent("setCmsConfiguratorData", {
                    detail: {
                        pid: productId,
                        data: configuration,
                        quantity: quantity,
                        activator: $activator,
                        launcher: CmsConfiguratorLauncher
                    }
                })
            );

            CmsConfiguratorLauncher.modalInstance.show();
        });
    }

    private static closeCmsConfigurator(event) {
        document.getElementById("content")?.dispatchEvent(new CustomEvent("closeCmsConfigurator", { detail: null }));
        if (CmsConfiguratorLauncher.rejector != null) {
            CmsConfiguratorLauncher.rejector("User closed the modal");
        }
    }
}
