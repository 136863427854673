﻿import $ from "jquery";
import { Popover } from "bootstrap";

export default class StockInWarehouse {
    static init() {
        const $body = $("body");

        let currentPopoverInstance: Popover;

        // show popover when clicking on the trigger
        $body.on("click", ".js-stock-in-warehouse-trigger", e => {
            const $trigger = $(e.currentTarget);

            // hide previous one
            if (currentPopoverInstance) {
                currentPopoverInstance.hide();
            }

            // show new one
            currentPopoverInstance = StockInWarehouse.getPopoverInstance($trigger) as Popover;

            if (currentPopoverInstance) {
                currentPopoverInstance.toggle();
            }
        });

        // hide popover when clicking elsewhere (not on the trigger)
        document.addEventListener("click", e => {
            if (e.target === null || e.target instanceof HTMLElement === false){
                return;
            }

            const isPopoverTrigger = (e.target as HTMLElement).classList.contains("js-stock-in-warehouse-trigger");

            if (isPopoverTrigger === false && currentPopoverInstance) {
                currentPopoverInstance.hide();
            }
        });
    }

    private static getPopoverInstance($trigger: any) : Popover | null {
        const $container = $trigger.closest(".js-stock-in-warehouse-container");
        const $popover = $container.find(".js-stock-in-warehouse-popover");

        if ($popover.length == 0) {
            return null;
        }

        const popoverElement = $popover[0];
        return Popover.getInstance(popoverElement);
    }
}