﻿import Multilang from "../utils/multilang";

export default class PwaHelper {
    static init() {
        const userAgent = window.navigator.userAgent.toLowerCase();

        const isIos = /iphone|ipad|ipod/i.test(userAgent) || /CriOS/i.test(userAgent); // CriOS = Chrome on iOS
        const isSamsung = /samsungbrowser/i.test(userAgent);
        const isFirefox = /firefox/i.test(userAgent) && (/mobile/i.test(userAgent) || /tablet/i.test(userAgent));
        const isPwaHelperDismissalFound = document.cookie.indexOf("pwah=1") !== -1;
        const isInStandaloneModeIos = "standalone" in window.navigator && window.navigator.standalone;
        const isInStandaloneModeChrome = window.matchMedia("(display-mode: standalone)").matches;

        if (isPwaHelperDismissalFound) {
            return;
        }

        if ((!isIos || isInStandaloneModeIos) && (!isSamsung || isInStandaloneModeChrome) && !isFirefox) {
            return;
        }

        const helperElement = document.querySelector(".pwa-helper") as HTMLElement;

        if (helperElement !== null) {
            const helperContentElement = helperElement.querySelector(".js-pwa-helper-content") as HTMLElement;

            const iconElement = document.createElement("img");
            iconElement.style.width = "18px";
            iconElement.style.height = "18px";

            if (helperContentElement !== null) {
                if (isIos) {
                    iconElement.src = Multilang.getTranslation("pwa-helper.browser.safari.icon", "/img/ios-share-icon-46x46.png");

                    helperContentElement.innerHTML = Multilang
                        .getTranslation("pwa-helper.browser.safari.message", "Om de webshop als app te installeren kan u de optie 'Add to home screen' (__icon__) gebruiken.")
                        .replace("__icon__", iconElement.outerHTML);
                }

                if (isSamsung) {
                    iconElement.src = Multilang.getTranslation("pwa-helper.browser.samsung.icon", "");

                    helperContentElement.innerHTML = Multilang
                        .getTranslation("pwa-helper.browser.samsung.message", "Om de webshop als app te installeren kan u de optie 'Add to home screen' gebruiken.")
                        .replace("__icon__", iconElement.outerHTML);
                }

                if (isFirefox) {
                    iconElement.src = Multilang.getTranslation("pwa-helper.browser.firefox.icon", "");

                    helperContentElement.innerHTML = Multilang
                        .getTranslation("pwa-helper.browser.firefox.message", "Om de webshop als app te installeren kan u de optie 'Add to home screen' gebruiken.")
                        .replace("__icon__", iconElement.outerHTML);
                }

                helperElement.style.display = "block";
            }

            const closeElement = helperElement.querySelector(".js-pwa-helper-close");

            if (closeElement !== null) {
                closeElement.addEventListener("click", function () {
                    helperElement.style.display = "none";
                    document.cookie = "pwah=1; expires=Sun, 1 Jan 2040 03:14:15 UTC; path=/";
                });
            }
        }
    }
}
