﻿import $ from "jquery";
import CustomHelper from "../utils/custom-helper";

export default class Approvals {
    static init() {
        $("body").on("click", ".js-basket-approval--select-purchaser", (e) => {
            const $button = $(e.currentTarget);
            const purchaserId = $button.data("nt-purchaser-id") as number;
            $(".js-basket-approval--purchaser-id").val(purchaserId);
            
            const $form = $button.closest("form");
            CustomHelper.submitForm($form);
        });
        
        $("body").on("click", ".js-basket-purchaser--activate-basket", (e) => {
            const $button = $(e.currentTarget);
            const basketId = $($button).data("nt-basket-id") as number;
            $(".js-basket-purchaser--basket-id").val(basketId);

            const $form = $button.closest("form");
            CustomHelper.submitForm($form);
        });
    }
}